import React from "react";
// import { Container, ContainerContent, ContainerWrapper, ContainerGroup } from 'components/layout/containers/Containers';





// 0
export class ContainerGroup extends React.Component {
  render() {
  const className = this.props.className ? this.props.className : '';
      return <div className={`container-group ${className}`} style={this.props.style}>{this.props.children}</div>
  }
}

// 1
export class ContainerWrapper extends React.Component {
  render() {
  const className = this.props.className ? this.props.className : '';
      return <div className={`container-wrapper ${className}`} style={this.props.style}>{this.props.children}</div>
  }
}

// 2
//   Set 'background' to false to remove the background
export class Container extends React.Component {
  render() {
  const className = this.props.className ? this.props.className : '';
      return <div className={`container ${(this.props.background == undefined || this.props.background == true) ? 'container-background shadow-thin' : ''} ${className}`} style={{...this.props.style, backgroundImage: `${window.publicURL}/image/placeholder/placeholder.jpg`}}>{this.props.children}</div>
  }
}

// 3
export class ContainerContent extends React.Component {
  constructor(props) {
    super(props);


  }  

  render() {
    const props = this.props;
    const _noPadding = (props.noPadding != undefined && props.noPadding != false) ? {padding: '0'} : {};

  const className = this.props.className ? this.props.className : '';
      return <div className={`container-content ${className}`} style={{...this.props.style, ..._noPadding}}>{this.props.children}</div>
  }
}

{/* <ContainerGroup>
  <ContainerWrapper>
      <h1 className="container-title">Neem contact met ons op</h1>
      <Container background={false}>
          <ContainerContent>
          
          
          
          </ContainerContent>
      </Container>
  </ContainerWrapper>
  <ContainerWrapper >
      <Container background={false}>
          <ContainerContent>


            
          </ContainerContent>
      </Container>
  </ContainerWrapper>
</ContainerGroup> */}