import React from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

// to={props.path}
function CardItemHorizontal(props) {

  var s = props.style;

  var _type = props.type ? props.type : '';

  var _logo = window.publicURL + props.logo;

  var _logoInImage = props.logoInImage;// Boolean


  var _logo = 
  		(<img key={'3'}
			className={`cards_item_logo-text horizontal ${_type}`}
			alt={`Project owners logo`}
			src={_logo}
		/>
  	)

  var _logo_image = 
  		(<img key={'4'}
			className={`cards_item_logo-image horizontal ${_type}`}
			alt={`Project owners logo`}
			src={_logo}
		/>
  	)

	// Info
  var _text = 
	(<div key={'1'} className={`cards_item_half horizontal ${_type}`}>
		<div className={`cards_item_info horizontal ${_type}`}>
			<h2 className={`cards_item_title horizontal ${_type}`}>{props.title}</h2>
			<p className={`cards_item_text horizontal ${_type}`}>{props.text ? parse(props.text) : ""}</p>
			
			{!_logoInImage ? _logo : ''}
		</div>
	</div>
	);

  var _image = 
  	(<div key={'2'} className={`cards_item_half horizontal ${_type}`}>
	  <figure className={`cards_item_pic_wrap horizontal ${_type}`} data-category={props.label}>
		<div className={`cards_item_label_wrap horizontal ${_type}`}>
			<h2 className={`cards_item_label horizontal ${_type}`}>{props.label}</h2>
		</div>

		{/* Product Image */}
		<img key={'5'}
			className={`cards_item_img horizontal ${_type}`}
			alt={`Produc image`}
			src={window.publicURL + props.src}
		/>
			{_logoInImage ? _logo_image : ''}
		
	</figure>
	</div>
	);
  

  // if (props.zoom != undefined)
  // {
  //   var zoom = -(props.zoom / 6);
  //     //props.zoom = props.zoom / 100;
  //     s = {width: props.zoom + "%"};//{transform: "scale("+ (props.zoom / 100) + ")"}; , left: zoom + "%"
  //   }

  return (
      <div key={props._key} className={`cards_item horizontal ${_type} ${props.className}`} title="Project" style={props.style}> 
        <div className={`cards_item_link horizontal ${_type}`}>

			{props.mirror ? _image : _text}
			{props.mirror ? _text : _image}

        </div>
      </div>
  );
}

export default CardItemHorizontal;
