import { ImageObject } from "components/layout/media/Gallery";
import $ from 'jquery';
import HTMLReactParser from "../node_modules/html-react-parser/index";
import { Slide, Fade  } from 'react-slideshow-image';


// Home 
// let homeHeader = new ImageObject("Test");
// console.log(homeHeader);

export function importAll(require, addPath="") {
	let _return = undefined;

	_return = require.keys().reduce((acc, next) => {

		acc[next.replace("./", "")] = String(require(next).default);

		// acc[next] = require(next).default;
		
		return acc;
	}, {});

	return _return;
}

/*
*const images = importAllArray(require.context('../public/images/portfolio/layout/pages/home', true, /\.(JPG|png|jpe?g|svg)$/));
*/
export function importAllArray(require) {
	// Exempt all in folder 'not-used': /^(?!.*\/not-used\/).*\/.*.\.(JPG|png|jpe?g|svg)$/
	// https://stackoverflow.com/questions/51129682/generating-regex-to-exclude-a-string-in-path
	let _return = undefined;

	_return = importAll(require);
	
	// Returns an array of values of the enumerable properties of an object
	_return = Object.values(_return);
	_return.sort();

	return _return;
}



// console.log(images);

export function importAll2(require) {
	let _return = undefined;

	_return = require.keys().reduce((acc, next) => {
		
		acc[next.replace("./", "")] = require(next);
		
		return acc;
	}, {});

	return _return;
}

export const Slideshow = (props) => {
	const _images = props.images;
	//style={{ height: props.mediaHeight}}
	return (
	  <div className={`slide-container ${props.className}`}>
		<Fade duration={6000} arrows={false} infinite={true}>
		  {_images.map((_image, _index) => (
			<div key={_index} className='header-slideshow-wrapper'>
			  <img className='header-slideshow' style={{ width: '60%'}} src={_image} />
			  {/* <h2>{fadeImage.caption}</h2> */}
			</div>
		  ))}
		</Fade>
	  </div>
	)
  }