import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from '../../interacion/button/Button';

function FooterCTA(props) {
  return (
    /* Subscription footer */
    <section className='footer-subscription'>
      <p className='footer-subscription-heading'>
        Title - Call To Action
      </p>
      <p className='footer-subscription-text'>
        You can unsubscribe at any time.
      </p>
      <div className='input-areas'>
        <form>
          <input
            className='footer-input'
            name='email'
            type='email'
            placeholder='Your Email'
          />
          <Button buttonStyle='btn--outline'>Subscribe</Button>
        </form>
      </div>
    </section>
  );
}

export default FooterCTA;