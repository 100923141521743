import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import './Footer.css';
import '../interacion/socialmedia/Socialmedia.css';
import { AppointmentButton, Button } from '../interacion/button/Button';


import FooterLinks from './components/FooterLinks';
import FooterCTA from './components/FooterCTA';
import FooterSocialMedia from './components/FooterSocialMedia';
import { Container, ContainerContent, ContainerGroup, ContainerWrapper } from '../containers/Containers';
import { Slideshow, importAllArray } from 'images';
import { List, WebsiteLink } from '../text/TextComponents';

function Footer() {
  const [showButton, setShowButton] = useState(true);

  const _imageArray = importAllArray(
		require.context('../../../../public/images/layout/home', true, /^(?!.*\/not-used\/).*\/.*.\.(JPG|png|jpe?g|svg)$/)
	)

  var _style = {backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover'}

  //
    return (<>
{/* backgroundImage: `url(${window.publicURL}/images/layout/footer/butterfly-blue.jpeg)` */}
      <footer style={{..._style}} className='footer-container'>
        {/* <div className='footer-background-image-container'>
          <img className='footer-background-image' src={`${window.publicURL}/images/layout/footer/butterfly-blue.jpeg`} />
        </div> */}

        {/* <div className='footer-background-slideshow-container'><Slideshow className={`footer-background-slideshow`} images={_imageArray} mediaHeight={'300px'}/></div> */}

        {/* <FooterCTA/> */}

        {/* <FooterLinks/> */}

        {/* Social media links */}
        
        <section className="social-media blur round-edge">
            <div className="social-media-wrap ">
                {/* <a href="/" title='Home'><img className="logo footer-logo" src={window.publicURL + global.config.companyMedia.logoWhite} width="100px"></img></a> */}
                {/* <div className="footer-logo">
                    <Link to='/' className="social-logo">
                        TRVL <i className="fab fa-typeo3"></i>
                    </Link>
                </div> */}

                

                <ContainerGroup style={{width: '100%', marginLeft: 'auto', marginRight: 'auto', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'stretch'}}>
                        <ContainerWrapper >
                            <Container style={{overflow: 'visible', alignItems: 'stretch'}} background={false}>
                                <ContainerContent noPadding={true}>

                                <a href="/" title='Home'><img className="logo footer-logo" src={window.publicURL + global.config.companyMedia.logoWhite} width="100px"></img></a>
                                
                                {/* - Klachten regeling
                                - Disclaimer
                                - Privacyverklaring */}
                                
                                </ContainerContent>
                            </Container>
                        </ContainerWrapper>
                        <ContainerWrapper >
                            <Container style={{overflow: 'visible', height: '100%'}} background={false}>
                                <ContainerContent noPadding={true}>
                                <div className='text-center height100 width100'>
                                  <div className='center-element width100 flex-font-size'>
                                    <small className="website-rights text-gray">{global.config.companyInfo.name} <wbr/>© {global.config.websiteInfo.creationDate}</small>
                                    <br/>
                                    <a href='http://peter-schreuder.com/portfolio/' target='blank'><small className="website-rights text-gray">Front- & backend door Peter Schreuder</small></a>
                                    <br/>
                                  </div>
                                </div>
                                  
                                </ContainerContent>
                            </Container>
                        </ContainerWrapper>
                        <ContainerWrapper >
                            <Container style={{overflow: 'visible', height: '100%', width: '150px'}} className={'margin-center-vertically'} background={false}>
                                <ContainerContent noPadding={true}>
                                  {/* style={{width: 'max-content'}} */}
                                <div className='height100'>
                                  <List 
                                  // className={'vertical-center'}
                                  bulletPoints={false}
                                  lineHeight={'default'}
                                  items={[
                                    <br/>,
                                      <><WebsiteLink underline={false} className={'flex-font-size'} href={'/media/documents/klassiekehomeopathie-groningen-scag.pdf'}>- Klachten regeling</WebsiteLink></>
                                     ,<><WebsiteLink underline={false} className={'flex-font-size'} href={'/media/documents/klassiekehomeopathie-groningen-disclaimer.pdf'}>- Disclaimer</WebsiteLink></>
                                     ,<><WebsiteLink underline={false} className={'flex-font-size'} href={'/media/documents/klassiekehomeopathie-groningen-privacy.pdf'}>- Privacyverklaring</WebsiteLink></>
                                     
                                  ]}
                                  />
                                </div>
                                  
                                </ContainerContent>
                            </Container>
                        </ContainerWrapper>
                    </ContainerGroup>
            </div>
        </section>
      </footer>
    </>);
  }

export default Footer


var __a = <ContainerWrapper>
{/* <h1 className="container-title">Waar u ons kunt bezoeken</h1> */}
  <Container className='container-content-list' background={false}>
    <ContainerContent>
      {/* <img style={{width: '200px'}} src='/projecten/testwebsites/homeopathie/images/recognition/NVKH-logo-wit-groot.png'/> */}
      <img style={{width: '150px'}} src={`${window.publicURL}/images/recognition/NVKH-logo-wit-groot.png`}/>
      <img style={{width: '100px'}} src={`${window.publicURL}/images/recognition/RBCZ-logo-groot.png`}/>
      
      {/* <FooterSocialMedia /> */}
    </ContainerContent>
  </Container>
</ContainerWrapper>;