import React from 'react';
import HeaderSection, { HeaderSectionHome } from '../layout/header/HeaderSection';
import CompanyStory from '../layout/text/CompanyStory';
import { importAllArray } from 'images';


var companyStory = <>
<div>

    <h2 className='font-p italic glow-white'>
Deze pagina is op dit moment jammer genoeg nog niet beschikbaar. Er wordt hard aan gewerkt!
Kom alstublieft op een ander moment terug.
    </h2>

    <h2 className='text-white'>{window.innerWidth}</h2>

    </div>
</>;
    
    // companyStory = jsxToString(companyStory, {detectFunctions: true});
const title = `(501) Onder constructie`;

export default function NotFound () {
	let _video = `${process.env.PUBLIC_URL}/videos/video-1.mp4`;
	let _image = `${process.env.PUBLIC_URL}/images/layout/filler/image2.jpg`;

	const _imageArray = importAllArray(
		require.context('../../../public/images/layout/home', true, /^(?!.*\/not-used\/).*\/.*.\.(JPG|png|jpe?g|svg)$/)
	);

    return (
        <>
            <div className="page">
                {/* <HeaderSection video={'/videos/video-1.mp4'} showInfo={true} logo={true} /> */}
                {/* <HeaderSection video={_media} showInfo={true} logo={true} /> */}
                
                <HeaderSectionHome />

                {/* <HeaderSection title={`Even Voorstellen`} mediaHeight={'350px'} image={_image} showInfo={false} logo={false} /> */}
                {/* <HeaderSection title={`${title}`} mediaHeight={'450px'} imageArray={_imageArray} showInfo={false} logo={false} /> */}

                <div className="page-content">
                    <CompanyStory showAppointmentButtonBottom={false} title={`${title}`}>
                        {companyStory}
                    </CompanyStory>
                </div>
            </div>
        </>
    );
};