import React from "react";
import './ContactForm.css';
import { ShowEmail, ShowPhone } from "components/layout/pages/home/company_info/Info";




export default function ContactForm() {
    // var _mail = global.config.companyInfo.emailMain;
    var _mail = '';//'the.rijder@gmail.com';
    
    var _unavailable = false;
    var _unavailableElement = _unavailable ? <>
        <div className="width100 height100 round-edge unavailable" style={{position: 'absolute', WebkitTransform: 'translateZ(0)', left: 0, top: 0}}>
            <h3 className="blur glow-accent5 text-black text-center center-div-vertical"><br/>Voor een afspraak of meer informatie graag een mail sturen naar:<br/><ShowEmail className={'text-black'} email={global.config.companyInfo.emailMain}/><br/><br/>Of telefonisch te bereiken op:<br/><ShowPhone className={'text-black'} phone={global.config.companyInfo.phoneNumberMain}/><br/><br/></h3>
        </div>
    </> : '';

    return(<div style={{position: 'relative', overflow: 'hidden'}} id="mid">
        {_unavailableElement}
        <form className="font-p" style={{width: '100%'}} action={`mailto:${_mail}`} method="post" encType="text/plain">
            <div>
                <label className="text-accent" htmlFor="name">Naam:
                <input type="text" name="name" id="name" placeholder="Hier uw naam" />
                </label>
            </div>
            <div>
                <label htmlFor="email">Emailadres:
                <input type="text" name="email" id="email" placeholder="Hier uw e-mailadres" />
                </label>
            </div>
            <div>
                <label htmlFor="email">Telefoonnummer:
                <input type="text" name="phone" id="phone" placeholder="Hier uw telefoonnummer" />
                </label>
            </div>
            <div>
                <label>Bericht:</label>
                <br />
                <textarea style={{resize: 'vertical'}} name="comments" rows={6} placeholder="Hier uw bericht..."></textarea>
            </div>
            <div>
                <a className={`font-p text-accentDarker`} href={`/media/documents/klassiekehomeopathie-groningen-privacy.pdf`}>Privacyverklaring</a>
                <br />
                <br />
            </div>
            <div>
                <input type="submit" name="submit" value="Verzenden" />
                {/* <input type="reset" name="reset" value="Clear Form" /> */}
            </div>
            <div>
                <h4>(Dit opent uw mail programma)</h4>
            </div>
        </form>
    </div>)
}