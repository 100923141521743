import React from 'react'
import CardItemHorizontal from './CardItemHorizontal';
import './CardsHorizontal.css';

var textTitle = 'Projecten';//Portfolio

var before = '';
var after = '';

function TitleText(string) {
  return before + string + after
}

export default function CardsHorizontal(props) {
	let cards = props.cards;

	if (props.projects) {
		// console.log(props.projects[0].owner);
		cards = [];

    let _i = 0;
		props.projects.forEach(project => {
      // console.log(_i);
			cards.push(
				<CardItemHorizontal
                _key={String(_i)}
                title={TitleText(`${project.name}`)}
                src={`/images/portfolio/placeholder/placeholder-product-01.jpg`}
                label={`Product`}
                text={project.description}
                date={`30-01-2023`}
                path={`/factuur`}
				        logo={global.config.companyMedia.logo}
              />
			);
      _i++;
		});
	}

	if (!cards) {
		cards = <>
			<CardItemHorizontal
      key={'3451'}
                title={TitleText(`Product Voorbeeld 1 (Breed Logo)`)}
                src={`/images/portfolio/placeholder/placeholder-product-01.jpg`}
                label={`Product`}
                text={window.basicText + window.basicText + window.basicText + window.basicText}
                date={`30-01-2023`}
                path={`/factuur`}
				logo={global.config.companyMedia.logo}
              />
              <CardItemHorizontal
              key={'3452352'}
                title={TitleText(`Product Voorbeeld 2 (Vierkant Logo)`)}
                src={`/images/portfolio/placeholder/placeholder-product-02.jpg`}
                label={`Product`}
                text={window.basicText + window.basicText + window.basicText + window.basicText}
                date={`30-01-2023`}
                path={`/portfolio`}
				logo={`/logo/placeholder_logo2.png`}
				logoInImage='true'
				// mirror={`true`}
              />
		</>;
	}

    return (
      <div key={props._key} className={`cards horizontal`}>
        <h1>{textTitle}</h1>
        <div className={`cards_container horizontal`}>
          <div className={`cards_wrapper horizontal`}>
            <div className={`cards_items horizontal`}>
              {cards}
            </div>
          </div>
        </div>
      </div>
    );
  }
