import { Container, ContainerContent, ContainerWrapper, ContainerGroup } from 'components/layout/containers/Containers';
import { useState } from 'react';


// Funtions 

/*
<List 
title={"Title of the list"} 
items={[
     `asdfasdf`
    ,`asdfafa
    asdfasdfaf`
]}
/>
*/
export function List(props) {
    let _return = <></>;
    let title = props.title;
    let subTitle = props.subTitle;
    let items = props.items;
    let bulletPoints = props.bulletPoints == undefined || props.bulletPoints != false ? true : false;
    let _bulletPoints = bulletPoints ? {} : {listStyleType: 'none',
        margin: '0',
        padding: '0'};
    let numbers = props.numbers ? {listStyleType: 'number'} : {};


    let i = 0;
    
    items.forEach(item => {
        items[i] = <>
        <li style={{fontSize: 'large'}} className='font-p'>
            {items[i].children ? items[i].children : items[i]}
        </li>
        </>;

        i++;
    });

    return <div className={`${props.className ? props.className : ''}`}>
        {title ? <h3 className=''>{title}</h3> : ''}
        {subTitle ? <h3 className='bold'>{subTitle}</h3> : ''}
        <ul className='font-p padding-left' style={{..._bulletPoints, ...numbers, lineHeight: `${props.lineHeight ? props.lineHeight : '1.4'}`}}>
            {items}
        </ul>
    </div>
}

//rgb(192, 219, 169)
export function Quote(props) {
    let _style = {paddingLeft: '0'}
    return <>
    <Container className={'column-wrapper bgr-accentLight border-left border-accentDarker'}>
                <i className='quote-icon fas fa-quote-right text-white height100'></i>
            <ContainerContent style={{...props.contentStyle, ..._style}}>
                <p className='font-p text-white'>
                    {props.children}
                </p>
            </ContainerContent>
        </Container>
    </>;
}

export function PhotoAndText(props) {
    const [left, setLeft] = useState(props.imageLeft || (window.isPhoneSize));
    const _text = props.children;
    let _imageWide = (props.imageWide == undefined || props.imageWide != false) ? true : false;
    let _imageRound = (props.round == undefined || props.round != true) ? 'round-edge-big' : 'round';

    const _imageWideString = props.width ? '' : (_imageWide ? 'width100 pwidth100' : 'width50 pwidth100');

    const _background = props.background == undefined || props.background != true ? {} : {backgroundColor: 'white'};

    const _image = <>
        {props.beforeImage}
        <div className='text-center overflow-hidden'>
            <img style={{width: `${props.width}%`, ..._background}} className={`${_imageWideString} ${_imageRound}`} src={props.image}/>
        </div>
            <br/>
            <hr/>
            {props.afterImage}
    </>;
// ${props.width}
// ${_imageWide ? 'width100 pwidth100' : 'width50 pwidth100'}
    function checkSize() {
        if (window.isPhoneSize) {
            setLeft(true);
        } else if (left) {
            setLeft(props.imageLeft || false);
        }
    };

    window.addEventListener("resize", checkSize);


    return <>
    <ContainerGroup style={{justifyContent: 'space-evenly', alignItems: 'stretch'}}>
        <ContainerWrapper className={'container-wrapper-grow'}>
            <Container style={{overflow: 'visible', alignItems: 'stretch'}} background={false}>
                <ContainerContent noPadding={true}>
                {(left) ? _image : _text}
                </ContainerContent>
            </Container>
        </ContainerWrapper>
        <ContainerWrapper className={'container-wrapper-grow'}>
            <Container noPadding={true} style={{overflow: 'visible'}} background={false}>
                <ContainerContent>
                {(left) ? _text : _image}
                </ContainerContent>
            </Container>
        </ContainerWrapper>
    </ContainerGroup>
    </>;
}

export function WebsiteLink(props) {
    const target = props.target || 'blank';
    const url = props.href;
    const _style = props.style || {};
    const _styleA = props.underline == undefined || props.underline != false ? {} : {textDecoration: 'none'};
    const _className = props.className || '';
    const _text = props.children || url;

    return <><div className={_className} style={{..._style, width: 'fit-content', wordBreak: 'break-word', fontSize: 'clamp(10px, 10vmin, 20px)'}}><a target={target} href={url} style={{..._styleA}}>{_text}</a></div>{(props.break == undefined || props.break == true) ? <br/> : ''}</>;
}

export function VideoPage(props) {
    let _return = <>
    <div className={`${props.className ? props.className : "width100"}`}>
        <br/>
        <h2>{props.title}</h2>
        <br/>

        <div className={`${props.classNameVideo ? props.classNameVideo : "width100"}`}>
            <iframe width="100%" height="400" src={`https://www.youtube-nocookie.com/embed/${props.videoID}`} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
        </div>
    </div>
    </>;

    return _return;
}