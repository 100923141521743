
//<QuoteByPerson text={'Alleen dat wat iemand werkelijk is heeft helende kracht'} who={'Carl G. Jung'}/>
export function QuoteByPerson(props) {
    const _color = props.color ? props.color : 'text-accentDark';

    return <div className='center-div italic' style={props.style}>
        <div>
            <h2 style={{fontWeight: "normal"}} className={`${_color}`}>{props.text}</h2>
            {props.who ? <h3 className={`${props.whoClassName || 'text-accent5'} shadow `}>({props.who})</h3> : ''}
        </div>
    </div>;
}

export function P(props) {
    return <>
    <p className={`font-p ${props.className}`} style={{...props.style}}>
    {props.children}
    </p>
    {props.noBreak == true ? '' : <br/>}
    </>;
}

export function Title(props) {
    return <>
    <h3 className=''>
    {props.children}
    </h3>
    </>;
}