import React from 'react';
import HeaderSection, { HeaderSectionHome, HeaderSection_Page } from '../layout/header/HeaderSection';
import CompanyStory from '../layout/text/CompanyStory';
import Cards from '../layout/cards/Cards';
import CardsHorizontal from '../layout/cards/CardsHorizontal';
import { Slideshow, importAll, importAllArray } from 'images';
import jsxToString from 'jsx-to-string';
import '../../styling/pages/MorePage.css'

import {entities} from 'entities.js';
import Gallery from 'components/layout/media/Gallery';
import { Container, ContainerContent, ContainerWrapper, ContainerGroup } from 'components/layout/containers/Containers';
import { ArrayToHTMLList } from 'components/tools/arrays';
import { List, PhotoAndText, Quote, WebsiteLink } from 'components/layout/text/TextComponents';
import { P, QuoteByPerson, Title } from 'styling/TextTypes';
import { ButtonLink } from 'components/layout/interacion/button/Button';
import { ImagePage } from './Vaccinaties';

// "Klassieke Homeopathie volgens de Ziekte-classificatie RH"
//         ,"CEASE-therapie"
//         ,"Banerji protocollen"

//------------
const _quote2 = <>Als er een einde komt aan de oorzaak, houdt ook het gevolg op</>;

const _left = <>her-inner wonderkracht en licht</>;
const _right = <>heling begint met een ander zicht</>;

const _both = <div style={{width: '100%', paddingRight: '10vw'}}>{_left}<div style={{float: 'right', textAlign: 'right'}}>{_right}</div></div>;

const values = { 
    titleSubject: `Toelichting`
    ,quote: _both
    ,image: `${process.env.PUBLIC_URL}/images/layout/klassieke-homeopathie/dragonfly-big.jpg`
}


function HeaderSection_ThisPage(props) {
    let _return = <></>;

	_return = <HeaderSection_Page 
    title={props.title} 
    titleBackground={values.titleSubject} 
    quote={props.quote || values.quote} 
    image={values.image} 
    />

	return _return;
}


export function ToelichtingPage_Hahnemann () {
    const headerTitle = ``;
	let _video = `${process.env.PUBLIC_URL}/videos/video-1.mp4`;
	let _image = `${process.env.PUBLIC_URL}/images/layout/klassieke-homeopathie/dragonfly.jpeg`;

	const _imageArray = importAllArray(
		require.context('../../../public/images/layout/home', true, /^(?!.*\/not-used\/).*\/.*.\.(JPG|png|jpe?g|svg)$/)
	);
// hahnemann.jpeg
    const companyStory = <>
    <div>

    <h1 className='glow-white'>
    Samuel Hahnemann
    </h1>

    <PhotoAndText image={`/images/layout/more/hahnemann.jpeg`} imageLeft={false}>



    <P>
    <b>Christian Friedrich Samuel Hahnemann (Meissen, 10 april 1755 - Parijs, 2 juli 1843)</b> was
als Duitse arts, de grondlegger van de homeopathie.
    </P>

    <P>
    Hahnemann studeerde geneeskunde aan de Universiteit van Erlangen, waar hij in 1779
afstudeerde. Hij ontwikkelde zijn theorie in een tijd waarin de geneeskunde nog nauwelijks
op exacte wetenschap gestoeld was en geen remedie had tegen de meeste ziekten.
    </P>

    <P>
    De eerste bespiegeling van Hahnemann draaide op het feit dat twee ziekten op zeer
bijzondere wijze op elkaar kunnen inwerken in hetzelfde individu. Een voorbeeld is de
bekende afwisseling van eczeem en astma als chronische uitingen van een allergische
constitutie. Ook signaleerde hij b.v. een chronische huiduitslag wanneer iemand de mazelen
kreeg.<br/>
Hij vroeg zich af wat dit verschil tussen tijdelijke en permanente vervanging
veroorzaakte en raakte ervan overtuigd dat dit gebeurt wanneer de twee ziekten
vergelijkbare symptomen hebben.
    </P>



</PhotoAndText> 

<P>
    Zonder te weten hoe het werkte begon hij met zijn ontdekking te experimenteren. Hij
probeerde b.v. lage doses van Belladonna te gebruiken om patiënten te behandelen met
roodvonk en hetzelfde deed hij met lage doseringen van arseen met cholera.
    </P>

    <P>
    Hij veronderstelde dat het op deze wijze mogelijk zou zijn om specifieke behandelwijzen te
vinden voor een aantal ziekten en zocht daarom actief naar andere potentieel voordelige
middelen en testte bij gezonde vrijwilligers hun 'pathogenetische ' kracht.
    </P>


<br/>

    <P>
    Zijn volgende stap was om te proberen om deze bevinding op systematische en
therapeutische wijze toe te passen. Als deskundige in de chemie, was hij bekend met veel
van de symptomen, veroorzaakt door toxische middelen.
Hij realiseerde zich dat de symptomen van een aantal natuurlijk voorkomende ziekten sterk
lijken op die van intoxicaties: b.v. de intoxicatie veroorzaakt door Belladonna lijkt op
roodvonk; de intoxicatie door kinine lijkt op malaria en een intoxicatie door arseen lijkt op
cholera. Het duurde voor hem niet lang om het idee van de vervanging van soortgelijke
ziekten te combineren met die van de vervanging door 'kunstmatige' intoxicatie.
    </P>

    <P>
    Langzamerhand verfijnde Hahnemann zijn homeopathische ideeën. Zo meende hij ontdekt
te hebben dat ook andere ziekten dan cholera kunnen worden genezen door een kleine
dosis arsenicum mits zij andere gemeenschappelijke 'kenmerken van arseen' hadden. Niet
alle cholerapatiënten reageerden op arseen, maar hadden een ander middel nodig,
afhankelijk van hun individuele symptomen. Hij veranderde dus het huidige nosologische
schema van medisch denken door de invoering van de concepten van drug-specifieke
pathogenese en de ziekte specifieke, individuele-status.
    </P>

    <QuoteByPerson text={<>Alles is gif en niets is gif; het verschil schuilt in de dosering.</>} who={'Paracelsus'}/>
<br/>
<P>
Hahnemann ging bij het bereiden van zijn medicijnen behoedzaam te werk: de giftige stoffen
die hij als uitgangsmateriaal koos verdunde hij eerst verregaand alvorens ze op zijn
patiënten toe te passen. Door de verdunning, waarbij er eigenlijk niets meer van de

uitgangsstof overblijft, namen de gevaarlijke effecten van het uitgangsmateriaal natuurlijk
af. Het resultaat was dan wel veilig, maar ook onwerkzaam.<br/>
Hahnemann claimde dat het verdunnen van de middelen op een bepaalde manier
('potentiëring ‘, gepaard gaande met ritmische schudslagen op een veerkrachtige
ondergrond), niet alleen de toxische effecten verminderde of ontnam, maar paradoxaal
genoeg ook de genezende kracht ervan vergrootte. Hij ontwierp daarop een standaard
verdunnings- en schudschema. Dit is nog steeds één van de meest onbegrepen aspecten van
homeopathie. Echter als je kijkt door de ogen van de kwantum fysische wetten, is het een
volkomen logisch en werkzaam principe.
</P>

<QuoteByPerson text={<>Een stof die ziekteverschijnselen teweegbrengt bij gezonde mensen, <br/> kan soortgelijke ziekteverschijnselen bij zieken genezen.</>} who={undefined}/>
<br/>

<P>
De grondlegger van de geneeskunde, Hippocrates, schreef in de 5de eeuw voor Christus al dat de
eigen geneeskracht van de patiënt essentieel is bij het kiezen van het juiste geneesmiddel en
dat die moest worden gestimuleerd.<br/>
Het woord homeopathie komt uit het Grieks: “Homoios” betekent gelijksoortig en
“Pathos” betekent lijden.<br/>
Deze begrippen verwijzen niet naar de ziekte als zodanig, maar naar de therapievorm.
Deze therapievorm wordt ook wel het “similia-principe” genoemd en is gebaseerd op de
slogan Similia Similibus Curentur, wat betekent: het gelijkende wordt door het gelijkende
genezen. Veel stoffen (planten en dierlijke gifstoffen en mineralen) veroorzaken bij
gezonde mensen bepaalde ziektesymptomen. Een ziektesymptoom is (zolang de patiënt
nog in staat is te reageren) een eigen reactie op een ziekmakende stof of situatie. Deze
reactie is een poging om vanuit de ziekmakende situatie weer zoveel mogelijk terug in
balans te komen.
</P>

<P>
Het homeopathisch geneesmiddel middel is dus gelijksoortig aan het ziektebeeld, versterkt
kunstmatig de natuurlijke ziektesymptomen van de patiënt met als gevolg dat de
natuurlijke afweer van de patiënt wordt gestimuleerd om exact het tegenovergestelde te
realiseren. De actie van het homeopathisch geneesmiddel is ziekmakend, de reactie van
het immuunsysteem brengt genezing, de natuurlijke gezondheid teweeg.
</P>

<P>
Na een lange reeks experimenten op zichzelf, zijn familie en de medische studenten die zijn
ideeën volgden, beschreef hij in 1796 zijn ideeën in de <b>Materia Medica</b> en in de
verhandelingen genaamd <b>'Organon'</b>, <b>'Chronische Ziekten'</b> tot en met de 5de editie vanwege
de vele wijzigingen door zijn ervaringen met de talrijke patiënten die hij behandelde.
</P>

<P>
Toch bleef hij tegen dilemma’s aanlopen. Het belangrijkste wat hij onder de loep nam was
de vraag hoe het kon dat hij wel de acute symptomen succesvol kon genezen maar niet altijd
de onderliggende chronische aandoening die maar bleven opvlammen. Waar was de missing
link en door zijn onvermoeide zoekende geest heeft hij die gevonden. Hij kwam tot de
conclusie dat permanente genezing alleen kan worden bereikt door het op de juiste wijze
selecteren van het geneesmiddel op basis van ook nog andere criteria, waaronder
constitutionele en psychologische eigenschappen van de patiënt, evenals eerder
doorgemaakte ziekten. Deze werkwijze met zijn passende homeopathische middelen voor
de behandeling van chronische ziekten heeft hij opgeschreven in de 6<sup>de</sup> editie <b>‘Organon Chronische ziekten’</b>.
Alle homeopaten die opgeleid zijn met de ziekteclassificatie werken
vanuit deze editie.
</P>

<P>
Een ander zwaar bekritiseerd aspect is de theorie van de ‘psora’ en de ‘miasma’s’, waarmee
Hahnemann de ziekten van zijn tijd probeerde te beschrijven. Dit is echter een briljant stuk
denkwerk van de meester geweest en verklaart veel van het ontstaan van de huidige
erfelijke ziekten waarmee de moderne mens te kampen heeft.
</P>

    </div>
    </>;



    // companyStory = jsxToString(companyStory, {detectFunctions: true});
    const title = undefined;//`Wie is Anita Schuit`;

    return (
        <>
            <div className="page">
                {/* <HeaderSection video={'/videos/video-1.mp4'} showInfo={true} logo={true} /> */}
                {/* <HeaderSection video={_media} showInfo={true} logo={true} /> */}
                
                {/* <HeaderSectionHome title={'Even Voorstellen'} /> */}

                <HeaderSection_ThisPage title={headerTitle} />

                {/* <HeaderSection title={`Even Voorstellen`} mediaHeight={'350px'} image={_image} showInfo={false} logo={false} /> */}
                {/* <HeaderSection title={`${title}`} mediaHeight={'450px'} imageArray={_imageArray} showInfo={false} logo={false} /> */}

                <div className="page-content">
                    <CompanyStory title={title} showAppointmentButton={false}>
                        {companyStory}
                    </CompanyStory>
                </div>
            </div>
        </>
    );
};

export function ToelichtingPage_MiasmaleerEpigenetica () {
    const headerTitle = ``;
	let _video = `${process.env.PUBLIC_URL}/videos/video-1.mp4`;
	let _image = `${process.env.PUBLIC_URL}/images/layout/klassieke-homeopathie/dragonfly.jpeg`;

	const _imageArray = importAllArray(
		require.context('../../../public/images/layout/home', true, /^(?!.*\/not-used\/).*\/.*.\.(JPG|png|jpe?g|svg)$/)
	);

    const companyStory = <>
    <div>

    <h1 className='glow-white'>
    Miasmaleer
    </h1>

    <P className="italic">
    Steeds meer mensen lijden aan chronische kwalen of ziekten.
Medicijnen bieden geen echte uitkomst, de kwaal blijft terugkeren.
De grondlegger van de homeopathie, Samuel Hahnemann, zocht de ware oorzaak van
dergelijke ziekten in de mentaliteit van zijn patiënten.
Het beste medicijn : een andere overtuiging.
    </P>

    <Quote>
    Door het miasma vindt dus een constante negatieve energiestroom plaats van waaruit we
keuzes maken die niet wezenlijk bij ons passen en ons ziek kunnen maken.
    </Quote>

    <Title>De aantrekkingskracht van ziekten</Title>
    <P>
    Wie met een kwaal naar de dokter gaat, krijgt vaak te horen dat het een virus of bacterie is.
Meestal wordt dan een geneesmiddel voorgeschreven om de ziektekiemen uit te roeien. De
symptomen worden bestreden, maar niet zelden steken deze later de kop weer op.
Hahnemann zag zo vaak ziekten die verdwenen leken weer terugkeren dat hij een soort
onderstroom vermoedde: een latente aantrekkingskracht voor een bepaalde ziekte.
Hij noemde dit een <b>miasma</b>. Een energetische vervuiling in onze cellen die we van onze
voorouders hebben meegekregen.<br/>
Het zijn deze <b>energiepatronen</b> die in feite onze ziekte en gezondheid bepalen. Ze werken als
een <b>blokkade</b> op onze genezing.<br/>
Je kunt de verschijningsvorm van een ziekte wel veranderen , dat is precies wat medicijnen
doen, maar de onzichtbare miasmatische krachten oefenen een diepere, aanhoudende
energetische aantrekkingskracht uit. Nadat het medicijn is uitgewerkt, kan die onderstroom
de kwaal gemakkelijk weer doen laten terugkeren. Het gevolg is wat patiënt en arts dan gaan
beschouwen als een hardnekkige chronische ziekte.<br/>
Door inzicht in de aard van een miasma, hoe ze groeien en hoe ze zich manifesteren, valt
vaak de ware oorzaak van gezondheidsproblemen te achterhalen.<br/>
Blijkbaar wordt er tijdens de conceptie en zwangerschap op energieniveau iets doorgegeven,
een soort <b>blauwdruk</b>. Door deze overdracht komt het voor dat niet alleen ouders en
kinderen qua karakter op elkaar lijken maar ook vaak dezelfde ziekten ontwikkelen.
Een miasma kan gezien worden als een automatisme in het zenuwstelsel waarbij in de
zenuwgeleiding een soort voorkeursbaan ontstaat waarlangs de impulsen automatisch
verlopen dus zonder ingrijpen van het bewuste denken.<br/>
Mensen beweren vaak dat zij nooit dezelfde fouten zullen gaan maken als hun ouders, maar
dat dit ondanks hun goede voornemens toch gebeurt. Dit is, naast de invloed van de
opvoeding en mogelijk nog andere oorzaken, mede te wijten aan deze overgeërfde
energiepatronen. De tendens tot een dergelijke ontwikkeling zit er al vanaf de geboorte in.
Klassieke homeopathie kan helpen deze ketens van erfelijke belasting van ons af te werpen
zodat we ons zelf als individu verder vrij kunnen ontplooien.<br/>
Door het miasma vindt dus een constante negatieve energiestroom plaats van waaruit we
keuzes maken die niet wezenlijk bij ons passen en ons ziek kunnen maken.
    </P>

    <P>
    De <b>Actie = reactie natuurwet</b> is gebaseerd op de 3 natuurwetten van <b>Newton</b>. Het kenmerk
van een natuurwet is dat deze voor iedereen altijd en overal werkt. Het immuunsysteem

werkt op basis van deze natuurwet.
Er zijn ziekteverwekkende besmettingen die van buitenaf komen en de mens ziek maken
maar de dynamis stabiel houden. Dit zijn de <b>acute ziekten</b>
Er zijn ook ziekteverwekkende besmettingen die het immuunsysteem wel ziek maakt en zich
uit in een <b>chronische miasmatische</b> ziekte. Deze ziekten genezen dus nooit uit zichzelf daar
het <b>actie=reactie</b> principe ontregeld is geraakt.
    </P>

    <P>
    Er zijn 3 basismiasma’s die de meest fundamentele natuurwet actie=reactie in haar
werkzaamheden ontregelen.
    </P>

    <Title>Psora</Title>
    <P>
    Vervuiling als gevolg van <b>lepra</b> doorgemaakt in voorgaande generaties. Psora
werkt <b>reactiepatroon vertragend</b>. 
<br/>Fysieke en emotionele symptomen uiten zich in volgende
generaties als een <b>tekort</b>, de ontwikkeling verloopt traag.<br/>
Centraal in de psyché staat de levensangst, de hulpeloosheid en het gebrek aan
doorzettingsvermogen. Deze innerlijke strijd haalt alle energie weg die daardoor niet
beschikbaar is voor basale functies zoals spijsvertering en opname van voedingsstoffen.
Hierdoor dreigen er tekorten die zich uiten in chronische ziekten.
    </P>

    <Title>Sycotisch</Title>
    <P>
    Vervuiling als gevolg van de ziekte <b>Gonorroe</b> doorgemaakt in vorige generaties.
Sycosis werkt <b>reactiepatroon versnellend</b>. 
<br/>Fysieke en emotionele symptomen uiten zich in
volgende generaties heftig en met een overdaad.<br/>
Het basisgevoel is dat er een gefixeerde onherstelbare zwakte bestaat die niet meer te
verhelpen is, de hoop is opgegeven maar moet voor de buitenwereld verborgen blijven. Men
is gezwicht voor verlangens niet vanuit een innerlijke drang maar vanuit de impulsen van de
buitenwereld met een hang naar meer. Het leven vindt plaats met veel stress, overactiviteit,
het zoeken naar kicks en over grenzen gaan. Dit geeft een gevoel van schaamte wat
                voorkomt uit de fundamentele wetenschap dat we niet doen waarvoor we op de wereld zijn.
                <br/>
De <b>slijmvliezen</b> zijn vaak aangetast. Deze vormen de bekleding van de verbindingen tussen
het lichaam en de buitenwereld. Er is vaak overdreven uitscheiding op <b>KNO gebieden</b>,
<b>luchtwegen, ogen, urinebuis, rectum en geslachtsdelen</b>. Ook zien we veel <b>wratten</b>, <b>cystes</b>,
<b>tumoren, overbeharing, overgewicht etc</b>.
    </P>

    <Title>Syfilitisch</Title>
    <P>
    Vervuiling als gevolg van de ziekte <b>Syfilis</b> doorgemaakt in vorige generaties.
Syfilis veroorzaakt een <b>zelfvernietigend reactiepatroon</b>. 
<br/>Fysieke en emotionele symptomen
uiten zich in volgende generaties met een snelle en destructieve kracht.
Men handelt zonder te luisteren naar zijn authentieke innerlijke stem.
Het fundament van het lichaam wordt aangetast en wordt gekenmerkt door kwalen
als: <b>Zweren, tumoren, verlies van botmassa, degeneratieverschijnselen aan het
                    zenuwstelsel, kanker, AIDS etc.</b><br />
                
                Ook op mentaal en emotioneel gebied zien we destrucktief gedrag.
                <br />
                <br/>
<b>Door onderdrukking</b> gedurende de laatste generaties zijn er meerdere miasma’s ontstaan
die altijd combinaties van de bovenste 3 onderliggende basis miasma's zijn
    </P>

    <Title>Tuberculose</Title>
    <P>
    Tbc is een primaire ontsteking en uit zich in een <b>versneld reactiepatroon</b> en
een <b>zelfvernietigend reactiepatroon</b>.
                Tuberculose weerspiegelt in haar essentie het beeld van de <b>atrofie, atonie</b>.
                <br/>
Centraal in de psyché staat het ontheemd zijn met al haar varianten vanuit een gebrek aan
eigen identiteit, angsten door geblokkeerde zelfexpressie en vluchten voor de werkelijkheid.

Deze mensen zijn romantisch, erotisch, sociaal, extrovert, kosmopolieten. Ze willen snel
zaken veranderen, zoals plaats, werkkring, vrienden etc. Rusteloos op zoek naar een
nieuw thuis, hebben ze weinig interesse voor de materiële wereld en verliezen gewicht op het
fysieke niveau. De onderdrukte energie wordt gecompenseert met spirituele stimuli zoals
muziek, kunst, esoterische filosofieën etc.<br/>
Bij een verschuiving naar het syfilitisch miasma zien we meer wanhoop, teleurstelling over
de eerdere filosofieën wat zich kan uiten door destructief gedrag.
    </P>

    <Title>Carcinogeen</Title>
    <P>
    Dit miasma wordt gekenmerkt door een neiging tot het vormen van <b>kwaad- of goedaardige
tumoren en neoplasma</b> van allerlei aard.<br/>
Kanker is een eindstadium welke veroorzaakt kan zijn door de biografie, carcinogene
substanties, gemengde miasma’s of een combinatie van alle drie.<br/>
De aard van de tumor wordt bepaald door de actieve miasma’s, de prognose bij het psora
miasma is het meest gunstig.<br/>
Centraal in de psyché staat het zwaar onder controle staan van degene van wie men
afhankelijk is en/of in onderdrukkende of gewelddadige relaties en/of bovenmatig veel stress en verdriet. Er is het gevoel dat
overleving afhankelijk is van het uitvoeren van bovenmenselijke taken (in het belang van
anderen), een inspanning die de eigen capaciteit te boven gaat. Er is een tendens om zichzelf
met zijn eigen wensen te onderdrukken. Men is perfectionistisch, nauwgezet, overbezorgd
en offert zichzelf op.
    </P>

    <Title>AIDS</Title>
    <P>
    AIDS heeft associaties met ‘grens’ kwesties. Een gezonde situatie is wat binnen in het
organisme is moet daar blijven, wat ziekmakend van buiten komt, komt niet erin. Maar bij
AIDS vindt een schending en vernietiging van de eigen grenzen plaats. het immuunsysteem
bezwijkt en het gevoel ontstaat van machteloosheid en verraden te zijn. Juist die mensen die
veiligheid en geborgenheid zouden moeten bieden hebben alle grenzen geschonden.
In de huidige tijd waarin we nu leven zien we een enorme toename van de laatste 2
miasma’s.
    </P>

    <h3>Lees meer</h3>
<WebsiteLink href={'https://www.gezichtspunten.nl/boek-aids-en-het-ik-9.html'}>Gezichtspunten - Aids en het ik</WebsiteLink>

    </div>
    </>;

    const companyStory2 = <>
    <div>

    <h1 className='glow-white'>
    Epigenetica
    </h1>

    <Quote>
    Hoe kan het dat eeneiige tweelingen verschillende ziekten kunnen
ontwikkelen, terwijl zij over precies hetzelfde DNA beschikken?
    </Quote>

    <P>
    Epigenetica is het vakgebied binnen de genetica dat de invloed bestudeert van de
omkeerbare erfelijke veranderingen in de genexpressie die optreden zonder wijzigingen in
de sequentie (volgorde van de basenparen) van het DNA in de celkern.
    </P>

    <P>
    Hoe kan het dat eeneiige tweelingen verschillende ziekten kunnen ontwikkelen, terwijl zij
over precies hetzelfde DNA beschikken?Als we veronderstellen dat alle ziekten genetisch
zijn, dan zouden identieke tweelingen met dezelfde genexpressie ook dezelfde ziekten
moeten ontwikkelen. Dit is niet het geval en dat heeft alles te maken met epigentica.
    </P>

    <P>
    Dankzij de inzichten van de epigenetica, de wetenschap die sinds het einde van de vorige
eeuw in opkomst is, weten we dat omgevingsfactoren zoals voeding, stress, trauma’s, drugs
en geneesmiddelen een belangrijke invloed hebben op onze levenskwaliteit en op het
ontstaan van ziekten. Ieder mens heeft circa 25.000 genen, maar niet allen zijn actief.
Omgevingsfactoren kunnen genen met negatieve eigenschappen, die normaal gesproken
uitstaan, aanzetten.
    </P>

    <P>
    Het goede nieuws is dat klachten en aandoeningen die ontstaan als gevolg van belastende
omgevingsfactoren, grotendeels te herstellen zijn. Dit herstelproces is te zien als een ‘reset’
van de originele fabrieksinstellingen. Door onze genen een min of meer gezonde of
‘normale’ omgeving aan te bieden ontstaat geleidelijk een proces van remissie, waarbij de
klachten verminderen.
    </P>


{/* afbeelding */}

<div className='margin-center width50 pwidth100'>
<ImagePage style={{paddingBottom: '32px'}} src={`${process.env.PUBLIC_URL}/images/layout/toelichting/h3schemaepigenetica.jpg`}/>

</div>



    <P>
    Nu is een ‘gezonde’ of ‘normale’ omgeving een relatief begrip in onze westerse samenleving.
Op sommige factoren, zoals luchtvervuiling bijvoorbeeld, hebben we minder invloed. Maar,

ook al leven we in een vervuilde omgeving, we hebben wél controle over andere factoren
zoals voeding en de manier waarop we met stress omgaan.
    </P>

    <P>
    Men kan de gezondheidszorg op verschillende manieren benaderen. De klassieke
geneeskunde gaat uit van een fragmentarisch en symptomatisch model: men behandelt een
aandoening door het verminderen van de symptomen, zonder veel aandacht te schenken
aan oorzaken of omgevingsfactoren. Door aandoeningen te behandelen met
symptoomonderdrukkers hoeft de patiënt weinig tot niets te veranderen aan zijn levensstijl.
Deze vorm van ‘comfortgeneeskunde’ biedt snelle oplossingen, maar is niet vrijblijvend.
Omdat men de oorzaken niet aanpakt, verergeren de epi-genetische adaptaties. Op deze
manier bouwt de patiënt alsmaar meer klachten en aandoeningen op, die vervolgens op een
gelijkaardige manier worden behandeld.
    </P>

    <P>
    De integrale epi-genetica ziet de mens als een geheel en zoekt naar de oorzakelijke
verbanden van de klachten, waarbij zowel fysieke, psychische, sociale als erfelijke factoren
betrokken worden. De klachten/aandoeningen waarvoor men hulp inroept, zijn immers met
elkaar verbonden. Met andere woorden: het volstaat niet om de naast elkaar bestaande
klachten afzonderlijk te behandelen, men dient ook te zoeken naar de gemeenschappelijke
deler.
    </P>

    <P>
Klassieke homeopathie volgens de ziekteclassificatie is gericht op de epigenetica.
    </P>

    <br/>
    <WebsiteLink href={'https://www.voedingsgeneeskunde.nl/editie/vg-24-1'}>Voedingsgeneeskunde, jaargang 24 (2023), nummer 1</WebsiteLink>

    <WebsiteLink href={'https://exendo.be/epigenetica/'}></WebsiteLink>
    
    {/* TODO VRAAG OF DE TIJD 0 Moet zijn */}
    {/* https://www.youtube.com/watch?v=WuyPuH9ojCE&t=100s */}
    <WebsiteLink href={'https://www.youtube.com/watch?v=WuyPuH9ojCE'}></WebsiteLink>
    


    </div>
    </>;



    // companyStory = jsxToString(companyStory, {detectFunctions: true});
    const title = undefined;//`Wie is Anita Schuit`;

    return (
        <>
            <div className="page">
                {/* <HeaderSection video={'/videos/video-1.mp4'} showInfo={true} logo={true} /> */}
                {/* <HeaderSection video={_media} showInfo={true} logo={true} /> */}
                
                {/* <HeaderSectionHome title={'Even Voorstellen'} /> */}

                <HeaderSection_ThisPage title={headerTitle} />

                {/* <HeaderSection title={`Even Voorstellen`} mediaHeight={'350px'} image={_image} showInfo={false} logo={false} /> */}
                {/* <HeaderSection title={`${title}`} mediaHeight={'450px'} imageArray={_imageArray} showInfo={false} logo={false} /> */}

                <div className="page-content">
                    <CompanyStory title={title} showAppointmentButton={false} showAppointmentButtonBottom={false}>
                        {companyStory}
                    </CompanyStory>

                    <hr/>

                    <CompanyStory title={title} showAppointmentButton={false}>
                        {companyStory2}
                    </CompanyStory>
                </div>
            </div>
        </>
    );
};

export function ToelichtingPage_InfectieziektenReckeweg () {
    const headerTitle = ``;

	let _video = `${process.env.PUBLIC_URL}/videos/video-1.mp4`;
	let _image = `${process.env.PUBLIC_URL}/images/layout/klassieke-homeopathie/dragonfly.jpeg`;

	const _imageArray = importAllArray(
		require.context('../../../public/images/layout/home', true, /^(?!.*\/not-used\/).*\/.*.\.(JPG|png|jpe?g|svg)$/)
	);

const companyStory = <>
<div>
<h1 className='glow-white'>
Acute Infectieziekten
</h1>

    <P>
    Een <b>infectieziekte</b> wordt veroorzaakt door levende <b>ziektekiemen</b> die hetzij direct of indirect
overgedragen kunnen worden van het ene levende organisme op het andere.<br/>
Er moet een juiste voedingsbodem aanwezig zijn in het lichaam waar schimmels of bacteriën
op kunnen groeien. Zij circuleren massaal in het milieu en zijn nodig voor het leven.
Een disbalans wordt gecreëerd als er een verstoring heeft plaatsgevonden en er een
ophoping van afvalstoffen aanwezig is.<br/>
(Er bestaat een theorie over virussen die anders is dan ons is aangeleerd/verteld.)
    </P>

    <P>
    <b>Acute</b> ziekten zijn dus een poging van het lichaam om dit evenwicht weer te herstellen door
te veel aan afvalstoffen uit te scheiden.<br/>
Of dit lukt zonder hulp hangt af van de <b>levenskracht, de constitutie,</b> maar ook of een
eventuele behandeling onderdrukkend of versterkend is.
    </P>

    <P>
    Er kunnen ook <b>acute symptomen</b> optreden als <b>begeleidend</b> symptoom bij een 
    klassieke <b>kinderziekte</b>, men spreekt dan van <b>complicaties</b>.<br/>
Symptomen kunnen ook een acute opleving zijn van een <b>chronische aandoening</b>. Het
lichaam probeert alsnog via deze opleving het lichaam te zuiveren. Zo kan bv. een acute
ziekte bij een reumapatiënt de reuma tijdelijk verlichten.
    </P>

    <P>
    Tijdens een acute ziekte vindt er volgens een natuurlijk verloop een <b>genezingscrisis</b> plaats.
Gedurende deze crisis is het heel belangrijk hoe er gehandeld wordt. Vaak belt men voor
hulp de dokter wanneer de ziekte op zijn hoogtepunt is. Hulp is <b>meestal</b> dan niet meer nodig
daar het lichaam de genezing zelf al in gang heeft gezet. Komt de crisis niet over zijn
hoogtepunt heen dan is hulp noodzakelijk. Als de koorts te hoog oploopt, 
een <b>complicatie</b> dreigt, de ziekte niet doorzet of de crisis langer duurt dan 40 uur moet het
lichaam geholpen worden. Als men een onderdrukkend geneesmiddel inzet dan zet men het
lichaam/de levenskracht stop die juist probeert om de <b>toxische</b> manifestatie te overwinnen. <b>Homeopathische</b> geneesmiddelen 
ondersteunen/versterken de levenskracht op weg naar
genezing.<br/>
Natuurlijk, als er geen juist homeopathisch middel aanwezig of de ziekte levensbedreigend
is, moet er <b>regulier</b> gehandeld worden.
    </P>

    <Title>Onderdrukking</Title>
    <P>
Van ziekte naar genezing verloopt <b>wetmatig</b>. De symptomen verdwijnen van binnen naar
buiten, van boven naar beneden. <b>Eczeem</b> wat onderdrukt wordt kan leiden naar <b>astma</b>, en
als dit onderdrukt wordt zelfs naar <b>reuma</b>. Wordt er in dit voorbeeld een juiste klassiek
homeopathische behandeling ingezet dan zien we de reuma verdwijnen, de astma
terugkomen en verdwijnen waarop daarna het eczeem terugkomt en verdwijnt.
Een <b>chronische</b> aandoening zal genezen als het wordt teruggebracht naar de oorspronkelijke
acute ziekte en de daarmee gepaard gaande <b>eliminatie van toxinen</b>.
Tegenwoordig zien we deze wetmatigheid tijdens een behandeling niet zo mooi meer
verlopen daar er veel belastingen/verstoringen op velerlei gebieden voorkomen.
De natuurlijkste en grondigste manier voor het organisme om maatregelen te nemen tegen
de afvalstoffen die zijn binnengedrongen is het bevorderen van <b>uitscheidingsprocessen</b>.<br/>

Dit gebeurt d.m.v. het <b>spijsverteringskanaal, de longen, de huid en de nieren</b>.<br/>
<b>Dr. H. Reckeweg</b> heeft dit in een gedegen wetenschappelijk model weten te plaatsen.
    </P>
</div>

</>;


// Story 2
    const companyStory2 = <>
    <div>

    <h1 className='glow-white'>
    Reckeweg
    </h1>

    <P>
    <b>Dr. H. Reckeweg</b> gebruikte de term <b>‘Homotoxine’</b> in de zin van ‘menselijke toxine’.
substanties (<b>bacteriën</b> of andere <b>micro-organismen</b>) die giftig zijn voor de mens en reacties
oproepen die zich uiten in fysieke en/of emotionele symptomen.
Ziekte is:
    </P>

    <List 
    title={undefined}
    items={[
        <>Een uitdrukking van een biologisch doelmatig afweerproces van het lichaam
        tegen exogene en endogene toxines</>
        ,<>Een poging van het organisme om de opgelopen toxine-vergiftiging door compensatie
        te herstellen.
        </>
    ]}
    />

    <P style={{paddingTop: "50px"}}>
    Ziekte kan van tweeërlei oorsprong zijn
    </P>


    <List 
    title={undefined}
    numbers={true}
    items={[
        <>Reactie van het lichaam in het <b>humoraal stadium</b> (uitscheiding- reactie – en
            impregnatiefase) op lichaamseigen- of vreemde gifstoffen.</>
        ,<>Reactie op de al aangebrachte schade en het organisme reageert in het <b>cellulair
        stadium</b> (impregnatie-degeneratie- en neoplasmafase)
        </>
    ]}
    />
    <br/>

    <P className="italic">
    De afweerstrijd dat het lichaam levert speelt zich dus op verschillende opeenvolgende
niveaus af. Meestal heeft het lichaam genoeg vitaliteit om de gifstoffen in de eerste 2 fasen
zelf te verwijderen.
    </P>

    <h1 className='glow-white'>
    Humoraal stadium
    </h1>

    <Title>Uitscheidingsfase</Title>
    <P>
        Een uiting van de levenskracht die naar gezondheid streeft en deze met
uitscheiding probeert te bereiken.
    </P>

    <Title>Reactiefase</Title>
    <P>
    Deze fase treedt op als de <b>uitscheidingsfase</b> niet toereikend is om alle (giftige)afvalstoffen te
verwijderen.<br/>
Meestal gaat dit gepaard met koorts wat een doelmatige methode van het organisme is om
ontstekingen te ondersteunen en de verbranding in de cel te verhogen waardoor
afvalstoffen sneller worden omgezet en afgevoerd.
Het onderdrukken van de koorts en/of het inzetten van <b>antibacteriële</b> middelen is niet
bevorderlijk voor de gezondheid tenzij de koorts levensbedreigend is. Het innerlijke
reinigingsproces kan hierdoor onderdrukt/tegengehouden worden waardoor men fase 3,
de <b>depositiefase</b> kan uitlokken.
Kinderziektes, die wel degelijk nut hebben, vallen ook in de reactiefase.
Tijdens de zwangerschap kan een vervuiling plaatsvinden van de lichaamsvochten van het
ongeboren kind. De “normale” kinderziektes worden gezien als een zuiveringsactie van het
organisme. Er vindt huiduitslag en koorts ( reactiefase) plaats en er worden antilichamen
gevormd. Op deze wijze wordt een geheel eigen afweersysteem opgebouwd.
De heftigheid, ernst en duur van een kinderziekte is afhankelijk van de vitaliteit van het kind
waarbij erfelijke factoren, de hoeveelheid afvalstoffen en de manier waarop dit behandeld
wordt een rol spelen.
    </P>

    <Title>Depositiefase</Title>
    <P>
    Het organisme is niet bij machte geweest om via <b>uitscheiding–</b> en <b>reactiefase</b> voldoende uit
te scheiden.<br/>
In de depositiefase bevinden zich de goedaardige, omkeerbare afzettingen van afvalstoffen
nog tussen de cellen, namelijk in het <b>bind-vet-en onderhuidweefsel</b> om zodoende de
organen te ontzien.
    </P>

    <P className="italic">
    Vanaf hier vindt er een omslagpunt plaats en wordt de weg naar genezing moeilijker maar
nog wel mogelijk. Een niet onderdrukkende natuurlijke behandeling kan het lichaam weer
boven de biologische scheidslijn krijgen.
    </P>

    <h1 className='glow-white'>
    Cellulair stadium
    </h1>

    <Title>Impregnatiefase</Title>

    <P>
    Erg giftige stoffen van buitenaf kunnen meteen binnendringen in de cellen en deze
aantasten.<br/>
Deze fase geeft in 1<sup>ste</sup> instantie weinig <b>acute</b> symptomen. Er ontstaat eerst vermoeidheid,
gebrek aan initiatief, geen eetlust etc. alvorens het zich openbaart in een chronische
aandoening.<br/>
Er kan een nieuwe <b>virusinfectie</b> plaats vinden en genezing kan dan gepaard gaan met de
uitscheiding-of een reactiefase. Dit is een uiting van de levenskracht en deze
reacties moeten de ruimte krijgen.
    </P>

    <Title>Degeneratiefase</Title>

    <P>
    Ook wel de uitputtingsfase genoemd. De cellen, dus ook de organen zwichten voor de druk
van voortdurende impregnatie.
    </P>

    <Title>Neoplasmafase</Title>

    <P>
    Hier beschadigen giftige afvalstoffen de celkern met haar chromosomen waardoor de
celdeling wordt verstoord. Nieuwvorm is de eindfase van talrijke “vergiftigingen”.
Er vindt nieuwe groei plaats op die plaats waar onophoudelijk en in een lange tijd
vergiftigingen hebben plaatsgevonden.<br/>
Men ziet meestal geen andere fase meer optreden waardoor men vaak voor het uitbreken
van kanker weinig andere klachten vertoont maar wel extreme vermoeidheid en
vermagering. Als er naast de kanker wel een andere fase optreedt, komt deze meestal uit
het gezwel zelf voort waar de afvalstoffen in opgeslagen worden. Een laatste poging van het
lichaam om de afvalstoffen op te ruimen. Wordt het gezwel verwijderd of aangeraakt dan
zien we vaak dat iemand uitzaaiingen of een andere ziekte ontwikkeld. Het organisme kan
de toevloed van de afvalstoffen door het wegnemen van het gezwel niet meer aan.
    </P>

    <P className="italic">
    Belangrijk is om te vermelden dat het verloop naar de diepere fasen langzaam kan verlopen
en een heel leven kunnen beslaan. Ook blijft de mens vaak tussen een aantal fasen heen en
weer gaan. Periodes van redelijke gezondheid naar ziekte en terug. Afhankelijk hoe de
erfelijke belasting (miasma) is, de leefstijl (epigentica) en hoe de ziekten behandeld worden.
    </P>

    <P>
    De excretiefase correspondeert met het acute miasma,
    <br/>De Reactiefase met Psora,
    <br/>De Depositiefase met Sycosis,
    <br/>Impregnatiefase met Sycosis/ Tuberculosis,
    <br/>De Degeneratiefase met Syfilis
    <br/>De Neoplasmafase met Carcinosis/Aids.
    </P>

    <P>
    Ook Hahnemann had ervaring met dit fenomeen:
    </P>

    <P>
    <b>Organon §282 voetnoot 1:</b> "Op de regel, dat men bij de homeopathische behandeling van
de chronische ziekten met de kleinst mogelijke doses moet beginnen en ze pas heel
geleidelijk moet verhogen, bestaat een opmerkelijke uitzondering in de behandeling van de
drie grote miasmata, zolang ze zich nog op de huid manifesteren, d.w.z. bij de recent
uitgebroken schurft, de onbehandelde sjanker en condylomata."
    </P>

    <WebsiteLink href={'http://www.homeopathie-info.com/artikelen/classificatie/reckeweg.html'}>http://www.homeopathie-info.com/artikelen/classificatie/reckeweg.html</WebsiteLink>

    </div>
    </>;

    



    // companyStory = jsxToString(companyStory, {detectFunctions: true});
    const title = undefined;//`Wie is Anita Schuit`;

    return (
        <>
            <div className="page">
                {/* <HeaderSection video={'/videos/video-1.mp4'} showInfo={true} logo={true} /> */}
                {/* <HeaderSection video={_media} showInfo={true} logo={true} /> */}
                
                {/* <HeaderSectionHome title={'Even Voorstellen'} /> */}

                <HeaderSection_ThisPage title={headerTitle} />

                {/* <HeaderSection title={`Even Voorstellen`} mediaHeight={'350px'} image={_image} showInfo={false} logo={false} /> */}
                {/* <HeaderSection title={`${title}`} mediaHeight={'450px'} imageArray={_imageArray} showInfo={false} logo={false} /> */}

                <div className="page-content">
                    <CompanyStory title={title} showAppointmentButton={false} showAppointmentButtonBottom={false}>
                        {companyStory}
                    </CompanyStory>

                    <hr/>

                    <CompanyStory title={title} showAppointmentButton={false}>
                        {companyStory2}
                    </CompanyStory>
                </div>
            </div>
        </>
    );
};

export function ToelichtingPage_Filosofie () {
    const headerTitle = ``;

	let _video = `${process.env.PUBLIC_URL}/videos/video-1.mp4`;
	let _image = `${process.env.PUBLIC_URL}/images/layout/klassieke-homeopathie/dragonfly.jpeg`;

	const _imageArray = importAllArray(
		require.context('../../../public/images/layout/home', true, /^(?!.*\/not-used\/).*\/.*.\.(JPG|png|jpe?g|svg)$/)
	);

    const companyStory = <>
    <div>

    <h1 className='glow-white'>
    Filosofie
    </h1>

    <P>
    Is een wereldbeschouwing die ver van zijn oorsprong is afgeraakt. Philein betekent
    beminnen en Sophia betekent wijsheid. Maar wat is wijsheid, daar kan men over blijven
    filosoferen, een feit is dat heden ten dage wijsheid bemind wordt door en gekoppeld is aan
    het denken, aan kennis. ik denk, dus ik besta (Descartes betekent letterlijk “van de kaart af”)
    Maar wie is ik en wat is denken, wat is bestaan. We zijn allemaal op zoek naar de Liefde en
    de Waarheid die alleen te her-vinden en te her-inneren is in volvertrouwen met je hart en
    niet met je brein.
    </P>

    <P>
    De Griekse arts Hippocrates, de grondlegger van de Westerse geneeskunde (460-359 v. Chr.),
    formuleerde de volgende stelling: “Ziekte ontstaat door invloeden die op dezelfde wijze
    werken als de geneesmiddelen en de ziektetoestand wordt verdreven door middelen, die
    daarop gelijkende verschijnselen tevoorschijn roepen.” Hoewel hij niet de term ‘homeopathie’
    gebruikte, formuleerde Hippocrates wel het basismodel dat aan de homeopathie ten grondslag
    ligt: Het ‘similia-principe’.
    </P>

    <P>
    Ook het ‘contraria-principe’ was hem bekend: Symptomen bestrijdt men, door het
    tegengestelde toe te passen. Hippocrates maakte hiermee een duidelijk onderscheid tussen
    symptoombestrijding (latere reguliere geneeskunde, ofwel allopathie) en het genezen van de
    ziektetoestand (homeopathie).
    </P>

    <h3 className='glow-white'>
    Paracelsus
    </h3>

    <P>
    De Zwitserse arts Paracelsus (1493-1541) stelde dosis sola fecit venenum’ oftewel: alleen de
    dosering maakt iets giftig. `hij ontdekte dat een arsenicumvergiftiging niet kon worden
    genezen door een tegengif, maar door toediening van een kleine dosis arsenicum. Zijn
    conclusie was dus dat ook ziekteverschijnselen die gelijken op de symptomen van een
    arsenicumvergiftiging, verdwijnen door het toedienen van arsenicum.
    </P>

    <h1 className='glow-white'>
    Ziekte en gezondheid
    </h1>

    <P>
    Binnen de klassieke homeopathie wordt uitgegaan van het holisme.
    Dit betekent heelheid, eenheid, heiligheid, heil brengen.
    Gezond zijn betekent evenwichtig zijn, heel zijn, zijn waarvoor je bedoeld bent.
    Holisme betekent niet alleen uitgaan van het fysieke, emotionele en mentale maar ook de
    levenskracht, de vitaliteit.<br/>
    De mens wordt met een bepaalde hoeveelheid levenskracht/vitaliteit geboren die de
    eigenheid van het individu weergeeft. Dat wat je maakt tot je zijn, je Eigen Aardigheden, hoe
    je er uitziet, hoe je basaal reageert op situaties, je aanleg om bepaalde ziektes te
    ontwikkelen etc.<br/>
    Naast de erfelijke belasting die men meekrijgt, spiritueel en in de vorm van DNA, drukken de
    eventuele opgedane epidemische ziekte(s) en de incidenten die we opdoen zich gedurende
    het leven ook in deze vitaliteit uit (epigenetica) die essentieel is voor genezing.
    Helaas raakt onze levenskracht tegenwoordig steeds meer verstoord door de extreme zware
    belastingen die van buitenaf zijn opgedaan en de cultuur waarin men opgroeit. Generaties
    lang pilgebruik, medicatie tijdens de zwangerschap, verontreiniging in de lucht/bodem, voedsel, vaccinaties, onderdrukking etc.
    </P>

    <P>
    De mens vormt op aarde samen met de dieren, planten en mineralen een
    aardgemeenschap. De mens heeft gedurende de evolutie een groot gedeelte van de
    eigenschappen van de omringende natuurrijken zoals dierlijke impulsen (begeerten, driften,
    hartstochten) van zich afgestoten. Hierdoor heeft men zich kunnen ontwikkelen tot een
    volledig mens zijn. Misschien zou je kunnen zeggen dat ziekte en stagnatie de prikkel vormt
    om zich van dit (lagere) deel te bevrijden, om zich te helen tot een hogere vorm van mens
    zijn. Ziekte kan een uitdaging zijn, een mogelijkheid om een oud stuk los te laten om
    vervolgens weer vernieuwd en verlost het pad verder te kunnen gaan. Het overwinnen van
    een ziekte geeft meer heel zijn, beter dan voor het ziek zijn, er vindt groei en bewustwording
    plaats.<br/>
    Het idee dat ziekte buiten de omgevingsfactoren ook uit je zelf kan komen kan heel
    confronterend zijn. Aan de andere kant kan iemand meer zijn verantwoordelijkheid en
    genezing in eigen hand nemen.<br/>
    De verstoring voordat het ziek zijn zich uit vindt plaats in het verleden, krijgt zijn weerslag in
    de toekomst, maar kan alleen in het nu opgelost worden. Men wist een stuk van het
    verleden en de toekomst dan uit.<br/>
    Het lichaam is niet ziek, de mens is ziek. Ziek zijn vertoond zich in het lichaam als een
    symptoom.<br/>
    Bij de opvoering van een tragedie is niet het toneel tragisch maar het stuk.
    </P>

    <h1 className='glow-white'>
    Delusions
    </h1>

    <P>
    Iedereen neemt de wereld om zich heen waar vanuit zijn eigen beleving, zijn eigen
    wanen/innerlijke overtuigingen wat een gevoel is dat niet door feiten gestaafd kan worden.
    Binnen de homeopathie probeert men ook dit te achterhalen en de middelen werken hier
    diep op in. Men maakt het onderscheid in de incidentele en basiswanen. Sommige mensen
    reageren panisch op sommige situaties waarop een ander niet, nauwelijks of op een heel
    andere manier reageert.<br/>
    Met incidenteel wordt bedoeld dat iemand tijdelijk iets verkeerd waarneemt. Bijvoorbeeld
    panisch worden als je een grote hond ziet aankomen. Je hebt een keer een nare ervaring
    hiermee gehad. Vervolgens reageer je bij elke hond alsof je door een leeuw achterna
    gezeten wordt, je bent veel banger dan eigenlijk nodig is. Dit incidentele waanidee berust op
    een dieperliggend waanidee (basiswaan), namelijk het gevoel niet in staat te zijn om
    hiermee om te gaan, waar weer een gebrek aan zelfvertrouwen achter zou kunnen zitten.
    Het waanidee zal dus als een rode draad door iemands leven lopen. De basiswaan blijft
    hetzelfde en komt op verschillende manieren tot uiting, in gevoelens, emoties, manier van
    spreken, taalgebruik, dromen, keuzes in hobby’s, werk, relaties etc.<br/>
    Wanneer de basiswaan en emoties onderdrukt worden slaan deze naar binnen, worden
    stoffelijk en uiten zich in ziektesymptomen. De voorafgaande verstoring op energetisch
    niveau wordt zichtbaar en geeft een signaal om iets te veranderen.<br/>
    Dit proces gebeurt voor een groot deel onbewust. Hoewel men vaak achteraf kan zeggen: “Ik
    zat al lange tijd niet lekker in mijn vel”.<br/>
    We kunnen in ons taalgebruik aanwijzingen vinden hoe ziektesymptomen zich zullen gaan
    uiten
    </P>

    <List 
    title={undefined} 
    items={[
    `Ik heb een brok in mijn keel, vaak na hevig verdriet (o.a. angina)`
    ,`Ik kan het mijn strot niet uit krijgen, vaak na irritatie`
    ,`Het zit mij tot hier, vaak na veel problemen`
    ,`Zit mij niet op mijn huid, men neemt of krijgt te weinig ruimte (o.a. huidklachten)`
    ,`Ik heb er de schurft aan, men wil iets negeren/vermijden`
    ,`Ergens op zitten broeden, men kan iets niet loslaten (o.a. obstipatie)`
    ,`Ik heb iets op mijn lever, men kan zich moeilijk uiten, zorgen maken (o.a. galstenen)`
    ,`Ik moet mijn gal spuwen.`
    ,`Ik heb hartzeer, hevig verdriet wat moeilijk geuit wordt`
    ,`Het ligt mij na aan het hart, zich te veel aantrekken.`
    ,`Ik neem te veel op mijn schouders, men neemt te veel verantwoordelijkheid`
    ,`Door de knieën gaan, te weinig voor jezelf opkomen (o.a. gewrichtsklachten)`
    ,`Ik zal niet buigen, men is te star`
    ]} />

    <h1 className='glow-white'>
    Dynamis
    </h1>

    <P>
    Samuel Hahnemann ontdekte een onzichtbare kracht in de mens, een kracht dat het
    ‘leven’ in zich had wat maakt dat iemand reageert. Het verschil tussen een overledene en
    een levend mens, het verschil dat maakt dat iemand reageert. Hij noemde dit de
    ‘dynamis’, ofwel ‘levenskracht’ of zelf genezend vermogen. Iets, wat ‘iets’ oppakt en laat
    reageren iets wat niet zichtbaar is bij ziektesymptomen, op een scan, een echo, een ECG,
    EEG, MRI etc.<br/>
    We bezitten dus allemaal een zelf herstellend vermogen, iets wat ons weer probeert in
    balans te brengen wanneer iets ons ziek maakt.<br/>
    Dit zelf genezend vermogen is bijvoorbeeld te zien bij het genezen van een wondje of
    snee. Wie doet dit? Wie stuurt dit aan? Wie maakt het weer dicht? Wie zorgt ervoor dat
    dit onzichtbaar weer geneest? We kunnen met behulp van deze eigen kracht, als het
    ware “vanzelf” beter worden.
    </P>

    <P>
    Maar soms heeft de dynamis, de levenskracht of zelf genezend vermogen het te moeilijk.
    We komen allemaal van tijd tot tijd uitdagende situaties tegen waarin we het moeilijk
    hebben. Situaties waarin je te weinig jezelf kunt zijn, te hoge eisen, te veel hooi op je
    vork, verlies, verdriet, angst, stress, slechte voeding, chemische vervuiling, vaccins etc.
    We fietsen als het ware tegen een te zware storm in en dreigen om te vallen met als
    gevolg b.v. oververmoeidheid, slaapproblemen, depressie, lichamelijk ongemak. Het
    organisme zal te allen tijde proberen opnieuw evenwicht te zoeken.<br/>
    De levenskracht of zelf genezend vermogen van de één is soms door omstandigheden als
    erfelijkheid, milieu, levensgeschiedenis en leefomstandigheden groter dan die van de
    ander. Elke levenskracht echter, zal altijd proberen zichzelf van ziekte te herstellen, tot
    een zo goed mogelijk herstel te komen. Wat inhoudt dat wanneer het niet lukt, dit als
    zwakte blijft bestaan, onze zwakke plek.<br/>
    Wanneer we de ziekte willen helpen genezen, moeten we niet de symptomen
    onderdrukken met chemische medicatie maar werken op de dynamis, op onstoffelijk
    niveau om zodoende het zelf genezend vermogen sterker te maken wat duurzaam kan
    lukken met homeopathische middelen.
    </P>

    <br/>
    <hr/>
    <br/>

    <QuoteByPerson text={'“Je kunt een probleem niet oplossen met de denkwijze die het heeft veroorzaakt”'} who={'Albert Einstein'}/>
    <br/>
    <P>
    Albert Einstein (1879-1955) is één van de belangrijkste natuurkundigen uit de
    geschiedenis. Hij werd vooral bekend vanwege zijn relativiteitstheorieën. Verder heeft
    Einstein veel belangrijk werk verricht op het terrein van de kwantummechanica, de
    kosmologie en de veldentheorie. Einstein is zijn halve leven bezig geweest te bewijzen
    dat massa gelijk is aan gestolde energie (E = mc2).<br/>
    De giftige stoffen die Hahnemann als uitgangsmateriaal koos verdunde hij eerst verregaand
    alvorens ze op zijn patiënten toe te passen. Door de verdunning namen de gevaarlijke
    effecten van het uitgangsmateriaal natuurlijk af maar bleven ook onwerkzaam.
    Hahnemann meende echter door het schudden (potentiering) de essentie van het
    uitgangsmateriaal op het oplosmiddel over te brengen. Meer schudbewegingen
    betekenden volgens hem een grotere versterking. Op deze wijze wordt de specifieke
    energie die bij een bepaalde stof hoort geactiveerd en de in de materie gestolde
    energetische kracht weer tevoorschijn gehaald, waarbij het natuurlijk wetenschappelijk
    bewijs hiermee geleverd is.<br/>
    Door het stapsgewijs minimaliseren en potentiëring van de homeopathische middelen
    bleek inderdaad de geneeskracht enorm toe te nemen en langer aan te houden.
    Hahnemann deed vanuit zijn arts zijn hier een fantastische ontdekking.
    </P>


    <h1 className='glow-white'>
    Vitruvius
    </h1>

    <PhotoAndText image={`/images/layout/more/virtuvius3.jpg`} width={'90'} imageLeft={false}>
    <P>
    Door de eeuwen heen hebben kunstenaars en wetenschappers het menselijk lichaam en
    zijn proporties bestudeerd.<br/>
    De Homo Vitruvianus, het menselijk lichaam passend in cirkel en vierkant, is gebaseerd
    op de theorie van de Romeinse architect Vitruvius.<br/>
    Een van zijn belangrijkste stellingen is dat de lengte, breedte, hoogte en diepte van een gebouw de menselijke maat (de
    verhoudingen van het menselijk lichaam) dienen te weerspiegelen.<br/>
    Hij legde uit dat het lichaam precies in een omgeschreven cirkel of vierkant met de navel als
    middelpunt past, respectievelijk Homo ad circulum (mens in cirkel) en Homo ad quadratum
    (mens in vierkant). Voor Vitruvius was het menselijke lichaam in dit opzicht het perfecte
    voorbeeld van een proportioneel geheel.<br/>
    De (incorrecte) 'Vitruviaanse man' van Leonardo Da Vinci weerspiegelt dit oeroude
    gebruik, dat al bij de Egyptische beeldhouwkunst werd toegepast.<br/>
    De Vitruvius man weerspiegelt de verschillende dimensies van het mens-zijn namelijk
    fysiek, emotioneel, mentaal, energetisch en existentieel. Het zijn deze dimensies die allen
    binnen een homeopathische behandeling versterkt worden.
    </P>
    </PhotoAndText>

    <br/>

    </div>
    </>;

    



    // companyStory = jsxToString(companyStory, {detectFunctions: true});
    const title = undefined;//`Wie is Anita Schuit`;

    return (
        <>
            <div className="page">
                {/* <HeaderSection video={'/videos/video-1.mp4'} showInfo={true} logo={true} /> */}
                {/* <HeaderSection video={_media} showInfo={true} logo={true} /> */}
                
                {/* <HeaderSectionHome title={'Even Voorstellen'} /> */}

                <HeaderSection_ThisPage title={headerTitle} />

                {/* <HeaderSection title={`Even Voorstellen`} mediaHeight={'350px'} image={_image} showInfo={false} logo={false} /> */}
                {/* <HeaderSection title={`${title}`} mediaHeight={'450px'} imageArray={_imageArray} showInfo={false} logo={false} /> */}

                <div className="page-content">
                    <CompanyStory title={title} showAppointmentButton={false}>
                        {companyStory}
                    </CompanyStory>
                </div>
            </div>
        </>
    );
};