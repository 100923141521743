import React from 'react';
import {HeaderSection_Page } from '../layout/header/HeaderSection';
import CompanyStory from '../layout/text/CompanyStory';
import { List, PhotoAndText, Quote, VideoPage } from 'components/layout/text/TextComponents';
import { AppointmentButton } from 'components/layout/interacion/button/Button';
import { P, QuoteByPerson } from 'styling/TextTypes';

const values = { 
    titleSubject: `Klassieke<wbr> Homeopathie<wbr>`
    ,quote: ``
    ,image: `${process.env.PUBLIC_URL}/images/layout/more/header_h2.jpg`///images/company/plaats.jpeg`
    // ,height: '400px'
    ,imageStyle: {width: '43%', right: '0', left: 'auto'}
    ,imageClassName: 'medpopacity pwidth100'
    ,styleContentWrapper: {backgroundColor: '#f2f2e4'}
    ,classNameContentWrapper: 'morePageHeaderWrapper'
}

// image opacity 0.3
function HeaderSection_ThisPage(props) {
    let _return = <></>;

	_return = <HeaderSection_Page 
    // title={props.title} 
    titleBackground={values.titleSubject} 
    quote={values.quote} 
    image={props.image || values.image}
    imageStyle={props.imageStyle || values.imageStyle}
    imageClassName={props.imageClassName || values.imageClassName}
    styleContentWrapper={props.styleContentWrapper || values.styleContentWrapper}
    classNameContentWrapper={props.classNameContentWrapper || values.classNameContentWrapper}
    story={props.story || headerStory}
    height={props.height || values.height}
    />

	return _return;
}

// Header
const headerStory = <>
<div className='font-p padding-horizontal medpwidth100' style={{float: 'left', width: '50%', padding: '3% 2%'}}>

<QuoteByPerson color={'text-accentDarker'} text={<div style={{fontWeight: "normal"}} className='font-size-flex-medium'>
"Probeer niet een deel te genezen zonder het geheel te behandelen,<br/>
niet het lichaam te genezen zonder de ziel er bij te betrekken<br/>
{/* Als u het hoofd en lichaam gezond wilt maken, begint u met het helen van de ziel,<br/>
Dit laatste is het belangrijkste<br/> */}
De grootste fout die artsen in onze tijd bij de behandeling maken is dat ze beginnen met de ziel van het lichaam te scheiden."
</div>} who={'Plato  427-347 v. Chr'}/>
<br/>


</div>
<div className='font-p padding-horizontal medpwidth100' style={{}}>

<div style={{position: "relative", width: '100%', height: '350px'}}>

<QuoteByPerson style={{position: "absolute", bottom: '0', width: '100%', padding: '17px 3%', paddingRight: '15%'}} color={'text-accentDarker'} text={<>Alleen dat wat iemand werkelijk is heeft helende kracht</>} who={'Carl G. Jung'}/>
</div>

</div>
</>;

export function KlassiekeHomeopathie_Inleiding () {
    const headerTitle = `Inleiding`;
    const pageTitle = `${values.titleSubject} - ${headerTitle}`;
    
    var companyStory = <>
<div>
    <P>
    Regulier gezien stelt men dat je ziek bent als er iets niet goed in een lichaam functioneert, het is dus een soort diagnostisch etiket. Bij b.v. diabetes werken de eilandjes van Langerhans niet goed en regulier geeft de arts dan een geneesmiddel die deze stimuleren en/of het tekort aanvult. Onderdrukt men door chemische medicijnen dan raakt men steeds meer uit balans en wordt de mogelijkheid tot heling ons ontnomen. Natuurlijk is behandeling met chemische medicijnen en/of een operatie in zeer acute gevallen noodzakelijk en soms zelfs levensreddend, maar het haalt op langere termijn <b>nooit</b> de <b>oorzaak</b> weg. 
    </P>

    <P>
    Een homeopaat kijkt op een hele andere manier naar (in dit voorbeeld diabetes) en zegt dat iemand deze aandoening <b>ontwikkeld</b>.
Naast dat de oorzaak ligt in het al dan niet goed functioneren van de pancreas, is ook de psyche van belang. 
Twee mensen met diabetes zullen beide een toegenomen aandrang tot urineren, dorst en een stijgende bloedsuikerspiegel vertonen maar het is erg onwaarschijnlijk dat ze ook beide dezelfde levensbiografie en etiologie hebben. 
Het verloop en de complicaties zullen ook verschillen. 
    </P>

    <P>
De bedoeling van deze vergelijkingen is niet om de geneeskunde in diskrediet te brengen, de
beoefenaren handelen integer vanuit hun eigen waarheid.
De bedoeling is om een kritische blik te ontwikkelen, het zelf-onderzoek en bewustwording
te stimuleren bij zoiets belangrijks als onze gezondheid.
Het is toch logisch dat als je een probleem wezenlijk wilt oplossen je de oorzaak aan moet
pakken?
    </P>

    <P>
    Zoals al gezegd wordt ziekte in aanleg veroorzaakt door een niet goed functionerend afweersysteem en disbalans, met uiteindelijk lichamelijk aantoonbare ziekten als gevolg, een uitdrukking van de verstoorde afweer. Bij meer dan de helft van mensen die zich ziek voelen kan op stoffelijke niveau nog geen ziekelijke verandering worden geconstateerd. Er zijn nog geen symptomen waar te nemen, het bevindt zich nog uitsluitend op het niveau van de afweer en de gebrekkige mogelijkheid om vitaal en zelf genezend te reageren.
    <br/>
Een ziekte kan zich uiten naar aanleiding van een aangrijpende gebeurtenis, b.v. een verlies, schrik, ongeluk of een exogene stoffelijke trigger b.v. pollen. Het lijkt alsof deze zich spontaan openbaren maar vaak sluimeren ze in de constitutie en komen ze door een schokkende ervaring aan de oppervlakte en worden we ziek. 
    </P>

    <P>
    Het zelf genezende vermogen van de natuur ofwel de <b>‘medicatrix naturae’</b> is gebaseerd op
een fundamentele natuurwet, de 3de wet van <b>Newton</b> die luidt:
Iedere actie uitgeoefend op een levend organisme roept een tegengestelde reactie op,
                even groot in richting en kracht.
                <br/>
Een goed voorbeeld van de werking van deze wet is het volgende:
Wanneer men zijn hoofd stoot verschijnt er als gevolg geen deuk maar een bult. Beter
gezegd, men krijgt eerst een deuk maar de afweer of natuurlijk herstelvermogen maakt er
in de reactie een bult van. De reactie van de natuurlijke afweer op een verstoring van
buiten is altijd tegengesteld aan de eerste verstoring. 100% actie heeft op basis van deze
natuurwet altijd een 100% tegengestelde reactie ten gevolge.
Van de werking van deze natuurwet, die altijd en overal werkzaam is wordt in de
homeopathie gebruik gemaakt om de mens te stimuleren zichzelf te genezen. Als reactie
op de in feite ziekmakende werking van een homeopathisch geneesmiddel geneest als
reactie de mens zichzelf.
    </P>

    <P>
    De kracht van de homeopathie ligt in de preventie. Als er nog geen of
nauwelijks afwijkingen in het bloedbeeld en/of organen te vinden zijn kan genezing het
gemakkelijkst en snel gerealiseerd worden. Ziekten die zich pathologisch hebben
ontwikkeld hebben een langere ontwikkeling doorgemaakt en vragen een langere tijd van
behandeling tot genezing en soms kunnen lichamelijke afwijkingen nauwelijks of niet
hersteld worden. Maar ook in deze ernstige chronische aandoeningen met zijn pathologie
biedt homeopathie oplossingen om de natuurlijke afweer zodanig maximaal te stimuleren
dat men in die betreffende situatie binnen mogelijke grenzen tot gezondheid kan komen.
    </P>
    <br/>
    <P>
    Er is een verschil tussen <b>klassieke homeopathie</b> en <b>klinische homeopathie</b>.  
In de klinische homeopathie wordt een complex aan middelen tegelijk gegeven op grond van een klinische term. Deze middelen zijn gewoon in de winkels te koop. Ze zijn van een lage potentie, namelijk de D-potentie wat verwijst naar diluut, is verdunning/oplossing. Een D- potentie bevat nog veel moleculen van de oorspronkelijke materie en kunnen daarom bij langdurig gebruik mogelijk toxisch erkend worden en nog infecties veroorzaken. Hooguit geeft het alleen effect bij acute klachten en geen diepliggend effect bij een chronische ziekte. In de klassieke homeopathie wordt de grondoorzaak aangepakt.
    </P>

    <P>
    Homeopathie is niet toxisch en onschadelijk voor lever en nieren die wel zwaar te
lijden hebben onder de onnatuurlijke chemisch en synthetische middelen.
Homeopathische middelen zijn niet belastend voor het milieu en worden niet getest op
dieren in tegenstelling tot de chemische medicatie.
    </P>

    <P>
    Homeopathie is gebaseerd op natuurwetten en de werking van de middelen zijn ontelbare
keren wetenschappelijk bewezen, ook al doet de media er van alles aan om ons anders te
doen geloven.
Homeopathische middelen worden al meer dan 200 jaar ingezet in de behandeling van ziekten en zijn tot op de dag van vandaag nog even werkzaam als destijds en dat zonder
enige schadelijke bijwerking.
    </P>

    <P className='bold'>
    Duurzaam en veilig dus...............................
    </P>

    <VideoPage 
    title={<>YouTube video van Stichting Hahnemann Homeopathie: "Hoe werkt klassieke homeopathie?"</>}
    videoID={'GNXud8bkHDs'}    
    />
    
    </div>
</>;

    return (
        <>
            <div className="page">
                <HeaderSection_ThisPage title={headerTitle} />

                <div className="page-content">
                    <CompanyStory title={`${pageTitle}`} showAppointmentButton={false}>
                        {companyStory}
                    </CompanyStory>
                </div>
            </div>
        </>
    );
};

export function KlassiekeHomeopathie_Ziekteclassificatie () {
    const headerTitle = `Ziekteclassificatie`;
    const pageTitle = `${values.titleSubject} - ${headerTitle}`;


    var companyStory = <div>

<P>
    Samuel Hahnemann vond dat de klacht slechts een uiting van een verstoorde situatie, een teken van onbalans was. Hij wilde de verschillende factoren die bij zouden kunnen dragen aan een ziekte in z’n geheel bekijken. Hij classificeerde de verschillende factoren en noemde deze werkwijze de Ziekteclassificatie. 
    <br/>(zie ook: <a target='blank' href='https://nl.wikipedia.org/wiki/Homeopathische_ziekteclassificatie'>Wikipedia: Ziekteclassificatie</a>). 
    </P>

    <P>
    Hahnemann onderscheidde de volgende categorieën;
    </P>

    <h3>Erfelijke ziekten</h3>
    <P>
    Ziektes en risico’s die we vanuit onze ouders of grootouders mee gekregen hebben;
familiaire risico’s.
    </P>
    
    <h3>Iatrogene ziekten</h3>
    <P>
            Ziektes en risico’s die ontstaan zijn vanuit geneesmiddelgebruik en/of vaccinaties, gifstoffen in het milieu en voeding.
            Hierbij wordt niet alleen gekeken naar de korte termijn, maar ook de schade
op de lange termijn.
    </P>

    <h3>Incidenten</h3>
    <P>
    Hiermee bedoelde Hahnemann onze biografie. Dingen die we meegemaakt hebben en veel
impact op ons hebben gehad kunnen invloed hebben op hoe we ons voelen. Er wordt
gekeken welke sporen bijvoorbeeld het overlijden van opa, een ernstig ziek zusje of een
scheiding op ons hebben achtergelaten.
    </P>

    <h3>Acuut miasmatisch</h3>
    <P>
    Acuut miasmatische ziekten kennen vaak een heftig verloop. De patiënt heeft een
longontsteking, een galsteenaanval, hooikoorts, een migraineaanval,
dikkedarmontstekingen, blaasontsteking enz. Iemand is op dat moment heel erg ziek. Het
is een ziekte van binnenuit, vaak getriggerd door een incident, een prikkel van buitenaf. De
berken bloeien en de periode van hooikoorts breekt aan. We vatten kou en krijgen een
longontsteking. Opa overlijdt en de dochter raakt in een depressie. De echtgenoot wordt
ontslagen en krijgt een burn-out.
    </P>

    <h3>Epidemische ziekten (tussenziekten)</h3>
    <P>
    Epidemische ziekten zijn ziekten die via een besmetting van buitenaf worden
overgebracht. Als we goed gezond zijn dan zijn we prima in staat deze ziektes door te
maken zonder ook maar één enkele complicatie. De besmetting en het doormaken van de
ziekte blijft dan keurig binnen de perken en maken ons niet ziek.
    </P>

    <h3>Constitutie</h3>
    <P>
    We worden geboren met ons eigen lichaam, onze eigen erfelijke belasting, mogelijkheden
en onmogelijkheden, kwaliteiten en zwakke plekken en deze optelsom staat garant voor
onze constitutie. Met onze fysieke en onze mentaal/emotionele gesteldheid valt of staat
ons leven. De grootste kunst is om te zorgen voor een optimale balanssituatie. Een
optimaal actie-reactiepatroon wat de verstorende invloeden van buitenaf kan elimineren
en ons afweersysteem zo wakker mogelijk houdt.
    </P>

    <br/>


    

    <PhotoAndText image={`images/layout/klassieke-homeopathie/ziekteclassificatie1.jpg`} imageLeft={false}>
        <P>
        De <b>Stichting Hahnemann Homeopathie</b> ontwikkelde op basis van de ziekteclassificatie een visuele weergave in een bloem. In het hart staat de <b>Psora</b>, onze constitutie (wie we zijn) centraal. In de bloembladeren de verschillende categorieën weergegeven. Bij de geboorte zijn ze nog in balans, maar naar mate we ouder worden en verschillende factoren ons welzijn beïnvloeden kunnen ze uit balans raken.
        </P>
    </PhotoAndText>

    <P>
    Met deze methode kunnen alle factoren die aanleiding geven tot ziekte (analyseren) geanalyseerd worden waardoor inzicht ontstaat in; 
    </P>

    <List 
    title={""} 
    items={[
        "De oorzaken van acute klachten, zoals een epidemie of een sluimerend aanwezige erfelijke aandoening, die soms opleeft"
        ,"De oorzaak van chronische ziekten door chronisch medicijngebruik en vaccinaties (iatrogene ziekten)"
        ,"De oorzaak van ziekten ontstaan door een verkeerde leefstijl (pseudo chronische ziekten)"
        ,"De erfelijke chronische ziekten (chronisch-miasmatische)"
    ]}
    />
    
    <br/>

    {/* Source */}
    <h2 style={{fontSize: 'clamp(10px, 10vmin, 20px)'}}><b>Bron:</b> <a target='blank' href='https://www.klassiekehomeopathie.nl/de-ziekteclassificatie/'>https://<wbr/>www.klassiekehomeopathie<wbr/>.nl/de-ziekteclassificatie/</a></h2><br/>
    
    <VideoPage 
    title={<>YouTube video van Stichting Hahnemann Homeopathie: "Wat is homeopathie en hoe werkt homeopathie?"</>}
    videoID={'XiJmbdqOZOw'}    
    />

    </div>;

    return (
        <>
            <div className="page">
                <HeaderSection_ThisPage title={headerTitle} />

                <div className="page-content">
                    <CompanyStory title={`${pageTitle}`} showAppointmentButton={false}>
                        {companyStory}
                    </CompanyStory>
                </div>
            </div>
        </>
    );
};

export function KlassiekeHomeopathie_De_Banerji_Protocollen () {
    const headerTitle = `De Banerji Protocollen`;
    const pageTitle = `${values.titleSubject} - ${headerTitle}`;

    //de-banerji-protocollen.png
    
    var companyStory = <div>

<PhotoAndText image={`images/layout/klassieke-homeopathie/de-banerji-protocollen.png`} imageWide={false} imageLeft={false} afterImage={!window.isPhoneSize ? <><br/> <AppointmentButton /></> : ''}>
<P>
    De <b>Banerji’s</b>, Homeopathische artsen uit India (4 generaties lang) met een zeer drukke
praktijk zien honderdduizenden klanten per jaar. Zij hebben in hun lange staat van dienst
een zeer grote hoeveelheid medische gegevens verzameld en daarmee onderzoek gedaan.
Met hun team hebben de Banerji’s ontdekt welke protocollen bij welke ziekten het meest effectief waren, veilig en zonder bijwerkingen. Deze kennis is gebundeld in de zogenoemde <b>Banerji-protocollen</b>, waarvan het behandelen van kanker de boventoon voert.
De laatste zestien jaar zijn deze protocollen met een opleiding door deze artsen de hele
wereld overgegaan.
    </P>

    <P>
    In Nederland wordt hun gedachtegoed exclusief uitgedragen door de <b>Stichting Hahnemann
Homeopathie (SHH)</b>. De SHH vormt het steunpunt voor de <b>Prasanta Banerji Homeopathic
Research Foundation</b> in <b>Nederland</b>. Op verzoek van beide artsen vertegenwoordigen wij
exclusief de Banerji-protocollen in Nederland.
    </P>

    <P>
    Ook ik ben geschoold in de toepassing van deze protocollen.
    </P>

    <div className='text-center'>
        <img className='width100 pwidth100 center-div-horizontal' src='images/recognition/KHBP-logo.png'/>
        <h2 style={{fontSize: 'clamp(10px, 10vmin, 20px)'}}><a target='blank' href='http://www.banerjiprotocolsnederland.nl'>http://www.banerjiprotocols<wbr/>nederland.nl</a></h2><br/>
    </div>
    </PhotoAndText>

    

    {/* showAppointmentButtonBottom={false} */}

    

    </div>;

    return (
        <>
            <div className="page">
                <HeaderSection_ThisPage title={headerTitle} />

                <div className="page-content">
                    <CompanyStory title={`${pageTitle}`} showAppointmentButtonBottom={window.isPhoneSize} showAppointmentButton={false}>
                        {companyStory}
                    </CompanyStory>
                    
                    {/* {window.isPhoneSize ? <>
                        <div className='fit-content margin-center'>
                            <AppointmentButton />
                            <br/>
                        </div>
                    </> : ''} */}
                </div>
            </div>
        </>
    );
};

export function KlassiekeHomeopathie_CEASE () {
    const headerTitle = `CEASE`;
    const pageTitle = `${values.titleSubject} - ${headerTitle}`;

    var companyStory = <>
    <div>
        <h2 className='font-p text-accent5 italic glow-white'>
        CEASE cure with ease
        </h2>
        <br/>

        <P>
        Cease staat voor <b>Complete Elimination of Autistic Spectrum Expression</b>.
        Het is een behandelmethode voor voornamelijk stoornissen binnen het autisme spectrum en AD(H)D of andere gedragsstoornissen.
        Jarenlang heeft de Nederlandse Homeopathische arts <b>Tinus Smits</b> (1946 / ✝2010) gewerkt aan
        het ontstoren van het <b>P</b>ost-<b>V</b>accinaal-<b>S</b>yndroom
        Hij is een verband gaan zien tussen vaccinaties en het toenemende aantal chronische
        klachten dat zich bij kinderen in stijgende mate voor ging doen.
        Tinus behandelde door de <b>nosode</b> van het desbetreffende vaccin te geven. Hij heeft deze

        behandeling verder ontwikkeld tot de <b>CEASE</b> therapie omdat er aanvankelijk, na de PVS-
        behandeling, nog klachten overbleven die niet aan de vaccinaties alleen konden worden

        toegeschreven.
        </P>

        <P>
        Tinus Smits heeft in de laatste jaren zo’n 300 kinderen met bovenstaande aandoeningen,
        van welke ernst dan ook, behandeld met positief resultaat.
        In de meeste gevallen bleek de oorzaak een opeenstapeling van verschillende factoren te
        zijn.
        Bij ongeveer 70 tot 80 % van de kinderen waren vaccinaties medeverantwoordelijk. Bij 80 tot
        90 % van de kinderen waren ook toxische medicijnen en andere stoffen betrokken. Bij
        ongeveer 5% van de kinderen waren ook doorgemaakte ziekten van de ouders of het kind
        zelf betrokken.
        Men kan dan ook terecht stellen dat CEASE een zeer effectieve behandelmethode kan zijn.
        </P>

        <PhotoAndText image={`/images/layout/klassieke-homeopathie/autism-beyond-despair.jpg`} imageWide={false} imageLeft={false}>
            <List 
            subTitle={"In de praktijk blijkt dat er meer oorzaken een verstorende werking kunnen geven. Te denken valt aan:"}
            items={[
                "Voedingsvervangers en toevoegingen (Aspartaam, suiker, MSG (E621/E635) en andere E-nummers."
                ,"Doorgemaakte ziekten van de ouders en/of het kind zelf."
                ,"Medicatie die het kind of zijn ouders hebben ontvangen in het verleden en/of moeder en kind tijdens de zwangerschap."
                ,`Industrialisatie met zijn producten en gebruiksvoorwerpen. Xylomethazoline,
                fluoride, nicotine/alcohol, drugs, weekmakers uit plastic en heden ten dage ook
                andere chemische toxische stoffen en zware metalen (Aluminium, Barium etc.),
                grafeenoxide, chemtrails, electro magnetische straling, Echo, magnetron, etc.`
                ,`Hormonale medicatie (Anticonceptie, ivf-behandeling, corticosteroïden,
                    oxytocine), psychofarmaca, narcose etc.`
                ,"Ook onverwerkte traumatische gebeurtenissen verspreiden zich als een ‘gif’ in ons lichaam."
            ]}
            />
        </PhotoAndText>
            
        <br/>

        <P>
        Zware metalen en chemische medicatie passeren ongehinderd de bloed-hersenbarrière en belangrijke functies van de hersenschors vallen dan (tijdelijk) uit.
        Men spreekt dan van een <b>stapelingsziekte</b>. De hersenen kunnen niet meer optimaal
        functioneren en maken minder <b>neurotransmitters</b> en <b>hormonen</b> aan of worden niet meer
        goed opgenomen vanuit het lichaam.
        </P>

            <P>
            
        Naarmate we ouder worden kunnen we belastende stoffen steeds minder zelf uitscheiden en deze gaan zich stapelen in ons lichaam.
        Veel klachten waar men tegenwoordig last van heeft zijn voeding
        gerelateerd of zijn bijwerkingen van vaccinaties of medicijnen.
        <br />
        Iedereen is dus te ontstoren van toxische stoffen want we komen allen gedurende ons leven in
        aanraking met belastende stoffen en kunnen net als een trauma,
        ons immuunsysteem en onze levenskracht (ernstig) verstoren. We krijgen voortdurend
        zware metalen naar binnen. De dagelijkse inname van kwik via de voeding is ongeveer
        20mcg. Oude amalgaamvullingen geven een 1mcg tot 450mcg/per dag af. In vaccins wordt
        voornamelijk aluminiumhydroxide gebruikt (25mcg per vaccin). Alleen het griepvaccin bevat
        nog kwik.
        </P>

        <h3>Burn-Out/ (post natale) depressie/Angsten/CVS etc.</h3>
        <P>
        Door toxische belasting en/of voedingstekorten kan het toch al hectische leven steeds
        moeilijker volgehouden worden. Men kan zich oppeppen met koffie/roken/alcohol,
        medicatie, gaan uitrusten door voor een beeldscherm te gaan zitten, of lang in bed blijven waardoor men in een vicieuze cirkel kan belanden. 
        Is de diagnose Burn-out, depressie of angststoornis
        gesteld dan kan er psychofarmaca voorgeschreven worden waardoor de toxische belasting
        nog meer gaat toenemen. Ogenschijnlijk lijken deze middelen tijdelijk rust te geven, maar op
        langere termijn is dit niet de oplossing.
        </P>

        <h3>Medicijnafhankelijkheid</h3>
        <P>
        Het is mogelijk om van psychofarmaca en andere medicatie af te komen maar doe dit nooit zelf. Wanneer verbetering van de klachten tijdens een behandeling merkbaar is zou in overleg met de huisarts of specialist het medicijngebruik kunnen worden afgebouwd. Het komt ook voor dat de men moet stoppen met de voorgeschreven medicijnen omdat onder andere de leverfuncties achteruitgaan. Men is dan “uitbehandeld” binnen de reguliere zorg. 
        </P>

        <P>
        CEASE-therapie <b>kan</b> zeer succesvol zijn, maar mislukt als de oorzaak niet te achterhalen is of als er sprake is van een hersenbeschadiging. Bij de meeste kinderen is er <b>enkel</b> sprake van een blokkade waarop CEASE het antwoord kan hebben. 
        </P>

        <P>
        Sinds 2009 ben ik een gecertificeerd CEASE therapeut, opgeleid door Tinus Smits. Ook maakt
        deze praktijk deel uit van een netwerk van goed opgeleide en ervaren CEASE-therapeuten. Er
        is onderling contact via een forum zodat kennis en ervaring snel gedeeld kunnen worden.
        </P>

        <h2 style={{fontSize: 'clamp(10px, 10vmin, 20px)'}}><a target='blank' href='http://www.cease-therapie.nl'>http://www.cease-therapie.nl</a></h2><br/>

        {/* <iframe width="100%" height="315" src="https://www.youtube-nocookie.com/embed/Wp_YF2Y-NOM" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe> */}

        </div>
    </>;

    return (
        <>
            <div className="page">
                <HeaderSection_ThisPage title={headerTitle} />

                <div className="page-content">
                    {/* <Quote text={'CEASE cure with ease'} color={'text-accent5'}/> */}
                    <CompanyStory title={`${pageTitle}`} showAppointmentButton={false}>
                        {companyStory}
                    </CompanyStory>
                </div>
            </div>
        </>
    );
};

