import React, { useEffect, useState } from 'react';
import {HeaderSection_Page } from '../layout/header/HeaderSection';
import CompanyStory from '../layout/text/CompanyStory';
import { Container, ContainerContent, ContainerWrapper, ContainerGroup } from 'components/layout/containers/Containers';
import { List, PhotoAndText, Quote } from 'components/layout/text/TextComponents';
import ContactContainer from 'components/layout/text/ContactContainer';
import { AppointmentButton } from 'components/layout/interacion/button/Button';
import { ImagePage } from './Vaccinaties';
import { P } from 'styling/TextTypes';
import { CardsContainer } from 'components/layout/cards/Cards';
import { _cardsErkenningen } from './Home';

const values = { 
    titleSubject: ``
    ,quote: `Voor ieder een behandeling`
    ,image: `${process.env.PUBLIC_URL}/images/layout/de-praktijk/boek-header.jpeg`
}

function HeaderSection_ThisPage(props) {
    let _return = <></>;

	_return = <HeaderSection_Page 
    title={props.title} 
    titleBackground={values.titleSubject} 
    quote={values.quote} 
    image={props.image || values.image}
    />

	return _return;
}

const _imagePage = <>
<div className='width100'>
<ImagePage className={`margin-center aaa`} style={{maxWidth: 'var(--maxWidth)'}} roundEdge={false} src={`${process.env.PUBLIC_URL}/images/company/plaats_l.jpeg`}/>
</div>
</>;

export function Tarieven () {
    const headerTitle = `Tarieven`;
    const pageTitle = `${values.titleSubject} ${headerTitle}`;
    
    var _text1 = <>
    <P>
    Consulten kunnen in de praktijk, telefonisch of via beeldbellen plaatsvinden.
    </P>

    <P>
    € 55,- per half uur / max € 130,- per consult, <b>inclusief</b> homeopatische middelen die rechtstreeks vanuit de praktijk worden verstrekt.
<br/>
1ste consult 1 tot 1,5 uur. Vervolgconsult 0,5 tot 1 uur<br/>
Thuisconsult idem plus reiskostenvergoeding € 0,50 per km.<br/>
Telefonisch (spoed) consult vanaf € 20,- afhankelijk van tijd en middelen.<br/>
    </P>

    <P>
    Schusslerzouten, tincturen en/of supplementen kunnen geadviseerd worden. Deze gaan op
recept via de Hahnemann apotheek of de Roode Roos.
    </P>

    <P>
    De door mij uitgevoerde behandelingen worden voor een deel of volledig vergoed vanuit de
(aanvullende) zorgverzekering, aangezien ik ben aangesloten bij de NVKH / RBCZ / KVK /
SCAG / BIG (valt niet onder het eigen risico).
    </P>

    <P>
    Consulten gaan op afspraak en dienen bij verhindering minimaal 24 uur van tevoren te
worden afgezegd. Anders wordt de gereserveerde tijd voor de helft in rekening gebracht.
    </P>

    <P>
    Betaling geschiedt contant of via betaalverzoek. Indien betaling uitblijft dan wordt verdere
incasso overgedragen aan Bos incasso te Groningen.
    </P>

        
    </>;

    var companyStory = <>
    <div>

            {_text1}

    </div>
</>;

    return (
        <>
            <div className="page">
                {/* <HeaderSection_ThisPage title={headerTitle} /> */}
                {/* {_imagePage} */}

                <div className="page-content">
                    <CompanyStory title={`${pageTitle}`} showAppointmentButton={false}>
                        {companyStory}
                    </CompanyStory>
                </div>
            </div>
        </>
    );
};

export function Contact () {
    const headerTitle = `Contact`;
    const pageTitle = `${values.titleSubject} ${headerTitle}`;

    var companyStory = <>
    <div>

    <P>
    asdf
    </P>

    </div>
</>;

    return (
        <>
            <div className="page">
                {/* <HeaderSection_ThisPage title={headerTitle} image={`${process.env.PUBLIC_URL}/images/company/plaats.jpeg`} /> */}
                {/* {_imagePage} */}

                <div className="page-content">


                    <ContactContainer klachten={false} />

                    {/* Recognitions */}
                    <CardsContainer title={'Erkenningen'} cards={_cardsErkenningen} background={false} />

                    {/* <CompanyStory title={`${pageTitle}`}>
                        {companyStory}
                    </CompanyStory> */}

                    <div className='fit-content margin-center'>
                        <AppointmentButton />
                        <br/>
                    </div>
                </div>
            </div>
        </>
    );
};

export function Privacy () {
    const headerTitle = `Privacyverklaring`;
    const pageTitle = `${values.titleSubject} ${headerTitle}`;
    
    var _text1 = <>
    <P>
    Voor een goede behandeling is het noodzakelijk dat ik, als uw behandelend homeopaat, een
    dossier aanleg. Dit is ook een wettelijke plicht opgelegd door de WGBO. Uw dossier bevat
    aantekeningen over uw gezondheidstoestand en gegevens over de uitgevoerde onderzoeken
    en behandelingen. Ook worden in het dossier gegevens opgenomen die voor uw
    behandeling noodzakelijk zijn en die ik, na uw expliciete toestemming, heb opgevraagd bij
    een andere zorgverlener bijvoorbeeld bij de huisarts.
    Ik ga uiterst zorgvuldig om met uw persoonlijke en medische gegevens en heb ervoor
    gezorgd dat onbevoegden geen toegang hebben tot uw gegevens. Als uw behandelend
    homeopaat heb ik als enige toegang tot de gegevens in uw dossier. Ik heb een wettelijke
    geheimhoudingsplicht (beroepsgeheim). De gegevens uit uw dossier kunnen ook nog voor
    de volgende doelen gebruikt worden:
        </P>
        
        <List 
            title={"Maar ook ter behandeling/ondersteuning van diepere aandoeningen zoals:"} 
            items={[
                 "Om andere zorgverleners te informeren, bijvoorbeeld als de behandeling is afgerond of bij een verwijzing naar een andere behandelaar. Dit gebeurt alleen met uw expliciete toestemming."
                ,"Voor het gebruik voor waarneming, tijdens mijn afwezigheid."
                ,"Voor het geanonimiseerde gebruik tijdens intercollegiale toetsing."
            ]}
            />


    <P>
    </P>

        
    </>;

    var companyStory = <>
    <div>

            {_text1}

    </div>
</>;

    return (
        <>
            <div className="page">
                {/* <HeaderSection_ThisPage title={headerTitle} /> */}
                {/* {_imagePage} */}

                <div className="page-content">
                    <CompanyStory title={`${pageTitle}`} showAppointmentButton={false}>
                        {companyStory}
                    </CompanyStory>
                </div>
            </div>
        </>
    );
};

export function OverigeExample () {
    const headerTitle = `Example`;
    const pageTitle = `${values.titleSubject} ${headerTitle}`;
    
    var _text1 = <>
    <P>
    Consulten kunnen in de praktijk, telefonisch of via beeldbellen plaatsvinden.
    </P>

    <P>
    € 55,- per half uur / max € 130,- per consult, <b>inclusief</b> homeopatische middelen die rechtstreeks vanuit de praktijk worden verstrekt.
<br/>
1ste consult 1 tot 1,5 uur. Vervolgconsult 0,5 tot 1 uur<br/>
Thuisconsult idem plus reiskostenvergoeding € 0,50 per km.<br/>
Telefonisch (spoed) consult vanaf € 20,- afhankelijk van tijd en middelen.<br/>
    </P>

    <P>
    Schusslerzouten, tincturen en/of supplementen kunnen geadviseerd worden. Deze gaan op
recept via de Hahnemann apotheek of de Roode Roos.
    </P>

    <P>
    De door mij uitgevoerde behandelingen worden voor een deel of volledig vergoed vanuit de
(aanvullende) zorgverzekering, aangezien ik ben aangesloten bij de NVKH / RBCZ / KVK /
SCAG / BIG (valt niet onder het eigen risico).
    </P>

    <P>
    Consulten gaan op afspraak en dienen bij verhindering minimaal 24 uur van tevoren te
worden afgezegd. Anders wordt de gereserveerde tijd voor de helft in rekening gebracht.
    </P>

    <P>
    Betaling geschiedt contant of via betaalverzoek. Indien betaling uitblijft dan wordt verdere
incasso overgedragen aan Bos incasso te Groningen.
    </P>

        
    </>;

    var companyStory = <>
    <div>

            {_text1}

    </div>
</>;

    return (
        <>
            <div className="page">
                {/* <HeaderSection_ThisPage title={headerTitle} /> */}
                {/* {_imagePage} */}

                <div className="page-content">
                    <CompanyStory title={`${pageTitle}`} showAppointmentButton={false}>
                        {companyStory}
                    </CompanyStory>
                </div>
            </div>
        </>
    );
};

