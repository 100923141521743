import React, { useEffect, useState } from 'react';
import {HeaderSection_Page } from '../layout/header/HeaderSection';
import CompanyStory from '../layout/text/CompanyStory';
import { Container, ContainerContent, ContainerWrapper, ContainerGroup } from 'components/layout/containers/Containers';
import { List, PhotoAndText, Quote, WebsiteLink } from 'components/layout/text/TextComponents';
import ContactContainer from 'components/layout/text/ContactContainer';
import { AppointmentButton } from 'components/layout/interacion/button/Button';
import { P } from 'styling/TextTypes';

const values = { 
    titleSubject: `Vaccinaties`
    ,quote: `Kies vrij, de angst voorbij`
    ,image: `${process.env.PUBLIC_URL}/images/layout/vaccinaties/flower-header.jpeg`
}

export function ImagePage(props) {
    const _roundEdge = props.roundEdge == undefined || props.roundEdge != false ? 'round-edge-big' : '';

    return <>
    <img style={{...props.style}} className={`width100 ${_roundEdge} ${props.className}`} src={props.src}></img>
    </>;
}

function HeaderSection_ThisPage(props) {
    let _return = <></>;

	_return = <HeaderSection_Page 
    title={props.title} 
    titleBackground={values.titleSubject} 
    quote={values.quote}
    quoteStyle={{
         color: '#df8a66'
        ,fontSize: '191%'
        ,right: '13px'
        ,width: 'auto'
        ,fontWeight: 'bold'
    }}
    image={props.image || values.image}
    />

/*
quote color #cc4544 !important
#df8a66 !important
float right
quote size: 191%
*/

	return _return;
}


export function Vaccinaties_Inleiding () {
    const headerTitle = `Inleiding`;
    const pageTitle = `${values.titleSubject} - ${headerTitle}`;
    
    var _text1 = <>

    <PhotoAndText image={`/images/layout/vaccinaties/inleiding-prik.jpeg`} imageLeft={false}>

    <h2>Waarom is er nog steeds een hoge vaccinatiegraad?</h2>
    <P>
    Sinds Louis Pasteur heeft ontdekt dat er zoiets bestaat als micro-organismen is er een
aanwijsbare vijand ontstaan die ons leven van alle kanten bedreigt. De farmaceutische industrie, blijft vaccins
ontwikkelen en inspelen op angst. 
<br/>
Angst zorgt ervoor dat we niet meer objectief kunnen waarnemen en dan lijkt het effect van
vaccineren al snel enorm succesvol. Hoe geweldig is het dat we vele ziektes kunnen
uitbannen door een simpele prik te geven, we zijn in 1 klap van onze zorgen en angst af. Ook
hoeven we geen volle verantwoordelijkheid te nemen hoe om te gaan met ziekten en staan
we niet voor de moeilijke keuze om wel of niet te gaan enten.
Als je terugkijkt in de geschiedenis is onderzoek pas in vele gevallen na de schadelijke
gevolgen van medische interventie gestart. Het wordt hoog tijd en is wijs om preventief
verantwoordelijkheid in de hand te gaan nemen. Dit door vaccinaties nader te gaan bekijken
en te relativeren in plaats van pas te handelen na de nadelige gevolgen.
    </P>

    </PhotoAndText> 
    
    <P>
    Het nut van vaccineren is wetenschappelijk nog steeds niet volledig aangetoond. Dat het
aantal chronisch zieke mensen, ten gevolge van het vaccineren is toegenomen is wel
wetenschappelijk bewezen. En toch blijven we vaccineren, waarom? Omdat ieder ouder wil
dat hij/zij en zijn/haar kind gezond door het leven gaat, omdat de wetenschap en de arts het
wel beter zal weten.
    </P>

    <P>
    Bent u een ouder die verder durft te kijken dan wat u aangedragen/verteld wordt door de huidige 
    wetenschap en des-kundigen? Bent u een ouder die ervaren heeft dat uw kind (ernstig) ziek
is geworden na een vaccinatie? Heeft uzelf ervaren dat uw gezondheid achteruit is gegaan
na een reisvaccinatie / mRNA vaccin?<br/>
Wanneer u uit de comfortzone stapt en op zoek gaat naar meer kennis dan wordt u
overladen met een wirwar van tegenstrijdigheden en informatie waarvan niet altijd de bron
te achterhalen is. Toch zijn er voldoende betrouwbare sites met informatie en
bronvermelding te vinden waar ik u aan kan helpen.
Weet dat u niet alleen bent, de populatie ouders groeit die hun kinderen niet of gedeeltelijk
laten inenten of besluiten tot alternatieven voor zichzelf.
    </P>

    <P>
    Wanneer u van het gebaande pad afwijkt zal u stuiten op onbegrip. Veelal
van diegene die zich niet goed verdiept hebben in de materie en vanuit een rotsvast
vertrouwen opvolgen wat de farmaceutische industrie naar buiten brengt.
De meest gehoorde is toch wel dat u mee profiteert in de bescherming door de hoge
vaccinatiegraad want de ziekten zijn toch verdwenen. Maar is dit wel zo of is dit een
wijdverbreid misverstand. Het terugdringen van bepaalde ziekten is zeker ook toe te
schrijven aan betere hygiëne, voeding en welvaart.<br/>
Een ander geluid is dat u niet verantwoordelijk bezig bent. Het tegendeel is waar, u hebt de
moed en neemt de moeite en tijd om te onderzoeken wat het beste is voor uw kind en/of
uzelf. En wat u dan ook kiest u heeft het bewust gedaan en eigen verantwoordelijkheid
genomen in plaats van deze af te schuiven op diegene die het vaccin zet als er iets mis gaat.
Trouwens, diegene houd zich niet verantwoordelijk, het is nog steeds een vrijwillige keuze.
    </P>

    <P>
    Het feit dat uw kind niet welkom is op een school of opvang wanneer het niet of gedeeltelijk
gevaccineerd is berust op een onwaarheid. Vaccineren is nog niet verplicht in Nederland en
men kan dit dus ook niet juridisch winnen om uw kind te weigeren. Er komen steeds meer
scholen en opvangmogelijkheden die hier niet moeilijk over doen.
<br/>
Stel de vraag waar men bang voor is. Als een kind gevaccineerd is dan is hij/zij toch
beschermd?
    </P>

    <P>
    Ook de CB-arts kan u nergens toe verplichten. Als respect uitblijft voor uw keuze dan zou u
kunnen wisselen van bureau, b.v. een antroposofisch bureau of een ander alternatief
vinden. Een bezoek aan het bureau blijft op vrijwillige basis, zij zijn er voor de ouders en het
kind en niet andersom.
    </P>

    <List 
    title={"Stelregels met betrekking tot vaccineren"} 
    items={[
         <>Een gezond sterk kind met gezonde ouders en familie heeft minder kans op
         complicaties bij kinderziekten en daarom minder belang bij vaccineren en ook minder
         kans op ernstige acute bijwerkingen. Kans op neveneffecten op lange termijn is nooit
         uitgesloten. Dit hangt af van de verdere belasting met gifstoffen gedurende het leven.</>
        ,<>Een zwakker kind of duidelijk risicovolle familiaire belasting heeft een grotere
        behoefte aan welke bescherming dan ook voor ziekte en eventuele complicaties maar
        heeft ook een duidelijk grotere kans op ernstige bijwerkingen van het vaccin. Ook
        heeft het een vergrote kans op blijvende schade wat zich uit in een chronische
        aandoening of allergieën wat zich ook nog later kan uiten in een auto-
        immuunziekte.</>
    ]}
    /><br/>

    <h3>
    Welke 1<sup>ste</sup> energetische boodschap krijgt een gevaccineerde baby mee wanneer het ter
wereld komt:
    </h3>
    <Quote>“Welkom klein groot wonder, je bent van begin af aan niet sterk genoeg om met je unieke
zelf op een natuurlijke wijze te overleven”</Quote>

<WebsiteLink href={'www.nvkp.nl'}>www.nvkp.nl</WebsiteLink>
<WebsiteLink href={'www.vaccinvrij.nl'}>www.vaccinvrij.nl</WebsiteLink>
        
    </>;

    var companyStory = <>
    <div>

            {_text1}

    </div>
</>;

    return (
        <>
            <div className="page">
                <HeaderSection_ThisPage title={headerTitle} />

                <div className="page-content">
                    <CompanyStory title={`${pageTitle}`} showAppointmentButton={false}>
                        {companyStory}
                    </CompanyStory>
                </div>
            </div>
        </>
    );
};

export function Vaccinaties_Inhoudsstoffen () {
    const headerTitle = `Inhoudsstoffen`;
    const pageTitle = `${values.titleSubject} - ${headerTitle}`;
    
    var _text1 = <>
    <h2>Productie van een vaccin</h2>
    <br/>
    
    <h3>Stap 1</h3>
    <P>
    Allereerst wordt een micro-organisme van een ziek individu uit de ontlasting of
lichaamsvochten geïsoleerd en op een kweek gezet voor vermenigvuldiging
<br/>
Bacteriële vaccins hebben alleen voeding nodig van plasma (bloed zonder rode
bloedlichaampjes), vaak kalfsplasma.
<br/>
Virale vaccins hebben naast de plasma ook gastheercellen nodig (afkomstig van
kippen en/of apen nieren of geaborteerde menselijk embryo's) om zich te
vermenigvuldigen.
    </P>
    
    <h3>Stap 2</h3>
    <P>
    Vervolgens wordt de ziektekiem verzwakt of gedood door onder andere formaldehyde daar
het anders te gevaarlijk is om in te spuiten zoals Pasteur reeds ontdekte.
Daarna moet de immuunrespons geactiveerd worden omdat de verzwakte of dode
ziektekiem niet voldoende antilichamen aanmaakt wat juist het doel is van het vaccineren.
Hiervoor worden hulpstoffen (adjuvans zoals aluminiumfosfaat) voor gebruikt.
    </P>
    
    <h3>Stap 3</h3>
    <P>
    Om het vaccin houdbaar te maken worden er nog eens chemische conserveer-en
stabiliseermiddelen toegevoegd.
<br/>
Het zijn met name de toevoegingen en de mogelijke vervuiling in de vaccins die schade
berokkenen.
    </P>

    <br/>
    <h3>Wat wordt er ingespoten?</h3>
    <P>
    Een combinatie van 'werkzame bestanddelen' en 'andere bestanddelen', aldus de bijsluiter.
De werkzame bestanddelen van een vaccin (de antigenen) zijn de levende, verzwakte of
gedode bacteriën en virussen die verondersteld worden de ziekte te veroorzaken.<br/>
Andere bestanddelen zijn:
    </P>
    
    <List 
title={undefined} 
items={[
    "Adjuvans, (aluminiumfosfaat (DKTP) of squeleen), (neurotoxisch) om de antigenen te versterken"
    ,`2-phenoxythanol, en formaldehyde (kankerverwekkend/neurotoxisch) om te conserveren`
    ,`Gehydroliseerde varkensgelatine, monosodium glutamaat (neurotoxisch) (E621) of phenol voor te
    stabiliseren`
    ,`Polysorbaat 80 (kankerverwekkend) voor verdunning of emulsiefieren`
    ,`Het vaccin wordt verder nog aangevuld met antibiotica (neomycine,
    streptomycine) (neuro en/of
    respiratoir toxisch) en vervuilingen: onbekende virussen, of erfelijk materiaal (stukjes
    RNA en DNA) afkomstig van het medium waarop vaccins gekweekt worden. Met
    water worden de ziekteverwekkers, chemische en andere ingrediënten van het
    vaccin opgelost, zodat ze makkelijk ingespoten kunnen worden.`
]}
/><br/>

    <P>
    Doordat de ziektekiem gekweekt wordt op dierlijke (nier)cellen wordt erfelijk materiaal mee
ingespoten in de mens. Een goed voorbeeld is het RS-virus wat een gevolg is van het
poliovaccin wat gekweekt wordt op apennieren. Het SV-40 virus is een apenvirus wat is
teruggevonden in botkankers, longkankers en leukemie.<br/>
(bron: “Vaccine Safety Manual" van N.Z. Miller)
    </P>
    
<ImagePage  style={{paddingBottom: '32px'}} src={`${process.env.PUBLIC_URL}/images/layout/vaccinaties/Inhoudsstoffen.jpeg`}/>

<P>
Onlangs is ook het HPV vaccin toegevoegd aan het RVP voor jongens vanaf 9 jaar.<br/>
</P>

<h3>Lees meer</h3>
<WebsiteLink href={'https://www.geneesmiddeleninformatiebank.nl/ords/f?p=111:3:0:SEARCH:NO::P0_DOMAIN,P0_LANG,P3_RVG1:H,NL,73202'}></WebsiteLink>

    <P>
    Baby’s krijgen in totaal 27 dosis ingespoten in hun 1<sup>ste</sup> levensjaar en het aantal vaccins
neemt nog steeds toe<br/>
De belangrijke uitscheidingsorganen van de baby zijn nog niet volgroeid waardoor stoffen als
hulpstoffen niet uit het lichaampje verdwijnen maar opgeslagen worden in met
name het hersen(vet)weefsel. Uit onderzoek blijkt dat immuuncellen direct de hersenen
kunnen binnendringen, en wat nog belangrijker is, dat het eigen specifieke immuunsysteem
van de hersenen door vaccinaties kan worden geactiveerd.
    </P>
    
    

    <h3>Bronnnen</h3>
    <br/>
    <P>
    <b>“Vaccinatie, Het einde van een mythe" van Viera Scheibner</b>, het resultaat van haar
uitvoerige onderzoek van de medische literatuur over vaccinatie uit de afgelopen honderd
jaar, naar de negatieve bijverschijnselen van vaccinaties, op een rij.
    </P>

    <WebsiteLink href={'https://stichtingvaccinvrij.nl/het-failliet-van-een-mythe/'}>https://stichtingvaccinvrij.nl/het-failliet-van-een-mythe/</WebsiteLink>
    <WebsiteLink href={'https://stichtingvaccinvrij.nl/ziektes-vaccins/vaccins/vaccineren/inhoudsstoffen-veiligheid/'}>https://stichtingvaccinvrij.nl/ziektes-vaccins/vaccins/vaccineren/inhoudsstoffen-veiligheid/</WebsiteLink>
    
    <WebsiteLink href={'https://stichtingvaccinvrij.nl/italiaanse-krant-over-een-schokkende-analyse-van-inhoudsstoffen-vaccins/'}>Italiaanse krant over een 'schokkende analyse van inhoudsstoffen vaccins' - Stichting Vaccin Vrij</WebsiteLink>

    </>;

    var companyStory = <>
    <div>

            {_text1}

    </div>
</>;

    return (
        <>
            <div className="page">
                <HeaderSection_ThisPage title={headerTitle} />

                <div className="page-content">
                    <CompanyStory title={`${pageTitle}`} showAppointmentButton={false}>
                        {companyStory}
                    </CompanyStory>
                </div>
            </div>
        </>
    );
};

export function Vaccinaties_Bijwerkingen () {
    const headerTitle = `Bijwerkingen`;
    const pageTitle = `${values.titleSubject} - ${headerTitle}`;
    
    var _text1 = <>
    
    <h3>“Vaccinatie is een juiste aanvulling om uw kind te beschermen tegen allerhande ziekten”</h3>
    <P>
    Was dit maar zo, het was wel het standpunt toen u met het RVP begon.
De praktijk lijkt helaas niet altijd zo rooskleurig.
U gaat naar het CB voor zijn 1<sup>ste</sup> enting, nu zelfs tijdens de 20 weken zwangerschap.
U denkt: “het moet en het is goed” maar u voelt ook dat het wel eens een hele belasting zou
kunnen zijn. U probeert wat meer te weten te komen op het bureau, maar wordt er vaak
niet veel wijzer van.
Op het internet leest u veel tegen- en voorargumenten, met
verschrikkelijke verhalen die verwarring en/of angst kan laten toenemen.
Wat nu, toch maar gewoon gaan enten? De wetenschap zal het toch wel weten en bijna
elke ouder kiest toch voor totaal enten?
<br/>
Maar dan: Na 1 of meerdere entingen begint uw kind te veranderen. Er komen fysieke
klachten, en/of het slaappatroon, het gedrag verandert, en/of is er een teruggang in
ontwikkeling waar te nemen.
Gaat u hiermee naar de huisarts, dan krijgt uw kind al snel medicatie wat tijdelijk lijkt te
helpen, maar vaak keren de klachten terug of er komen nieuwe voor in de plaats.
De ongerustheid dat er meer aan de hand is blijft en versterkt zich alleen maar.
Uw kind zou dan wel een last kunnen hebben van vaccinatieschade.
    </P>

    <h3>Is vaccineren wel zo onschuldig als het lijkt?</h3>
    <P>
    Nee, lang niet zo vaak als we zouden willen.
Bij een natuurlijke (kinder) ziekte komt er 1 soort ziektekiem in het lichaam via de 
1<sup>ste</sup> wachters. Je kunt op een natuurlijke wijze niet verschillende ziekten tegelijk oplopen.
Bij een vaccin komen relatief grote hoeveelheden ziektekiemen tegelijk binnen direct via de
spier in het bloed, zij het verzwakt of dood.
Er zitten toevoegingen in het vaccin zoal bewaar- en hulpstoffen waarvan een aantal nodig
zijn om de afweer aan te sporen. De hele procedure wordt nog eens een aantal keren
herhaald.
Als het kind instinctief met koorts reageert op de vaccins dan wordt dat vaak nog onderdrukt
met paracetamol o.i.d.
    </P>

    <P>
    De mensen op het CB die onze kinderen enten zijn absoluut gewetensvol.
Ze zijn net zoals de ouders eenzijdig geïnformeerd en bang
gemaakt voor ziekten.
De gevolgen worden vaak niet onderkend daar men ervan uitgaat dat een reactie op een
enting binnen 48 uur moet komen.
Maar de incubatietijd van een besmetting met een ziektekiem duurt ook langer dan 48 uur
voordat het zich openbaart in een lichaam, waarom dan niet na een vaccinatie? De praktijk
wijst uit dat een acute reactie zelfs na 2 weken zich nog kan voordoen of zich langzaam
sluimerend openbaart in een chronische aandoening.
    </P>

    <PhotoAndText image={`/images/layout/vaccinaties/bijwerkingen-meisje.jpg`} imageLeft={false}>

    <h3>Wat gebeurt er bij contact met een ziektekiem</h3>
    {/* TODO Image */}
    <P>
    De tonsillen zijn de 1<sup>ste</sup> wachters van het lymfatisch systeem en controleren wat er
binnenkomt. Daarna passeert een ziektekiem de lymfeklieren in het spijsverteringskanaal
(maag/darm/buikvlies, lever) en de luchtwegen.<br/>
Vervolgens de milt, de thymus en het
beenmerg en pas daarna komt de ziektekiem in het bloed terecht. Dan worden er leukocyten en
antistoffen gemaakt waardoor er koorts ontstaat zodat de ziektekiem
slomer raakt en zo uitgeschakeld wordt. Hoe hoger de koorts, hoe beter deze werking.<br/>
Het lichaam heeft dus de middelen om onbekende ziektekiemen te lijf te gaan en om langs de natuurlijke weg de afweer te mobiliseren.<br/>
We leren om de ‘som’ te maken, en als het lichaam dat eenmaal onder de knie heeft, dan
vergeten we dat nooit meer.<br/>
In ons darmflora vinden we tal van soorten bacteriën die
ervoor zorgen dat voedingsstoffen in onze cellen
opgenomen worden.<br/>
We huisvesten in ons lichaam wel 100 triljoen micro-organismen, zonder kunnen we niet
leven. Daarvan is 80% goedaardig en de rest wordt door een gezonde afweer afgebroken en
uitgescheiden. We leven met deze ziekteverwekkers zonder ziek te worden.
    </P>

    </PhotoAndText> 
    
    <P>
    Uw kind is gedurende het 1<sup>ste</sup> jaar bezig met de opbouw van zijn/haar eigen cellulaire
(algehele) afweer en krijgt eerst de antistoffen van de moeder mee. 
<br/>
Een baby van 2 maanden oud heeft dus nog een onervaren
immuunsysteem en is daardoor erg kwetsbaar, laat staan een foetus waarvan de moeder nu
al een 1<sup>ste</sup> enting krijgt met 20 weken zwangerschap.
<br/>
Iedere kinderziekte heeft een functie, het schoont een
deel van de meegekregen genetische belasting en
versterkt het eigen immuunsysteem. Doordat we vaccineren maken onze kinderen geen volledig
immuunsysteem meer aan en blijven ze zitten met een
genetische zwakte (predispositie). 
De gevolgen zijn zichtbaar, chronische aandoeningen en auto-immuunziekten op jonge leeftijd zijn schrikbarend toegenomen. 
<br/>We zitten in de 3e generatie van
vaccineren en de gevolgen zitten diep in ons genesteld (
miasmatisch, epigenetisch)
<br/>
<br/>
De afweer die het kind van de moeder heeft meegekregen kan de
lichaamsvreemde eiwitten en chemische verontreinigingen en toevoegingen van de vaccins
vaak maar moeilijk verwerken. 
<br/>Het gevolg hiervan is dat er juist in die periode allerlei
chronische klachten van verminderde algemene weerstand kunnen ontstaan.
Ook wordt een kind door de vaccins gedwongen om zich met de specifieke afweer voor de
gevaccineerde ziekten bezig te houden en heeft extra moeite om de eigen cellulaire
(algehele) afweer op te bouwen.
    </P>

    <P>
    Kinderziektes lijken een functie te hebben. Enerzijds om de erfelijke belasting (miasma’s) te
zuiveren, anderzijds om het eigen immuunsysteem te sterken en leren om te gaan met de
prikkels van buitenaf. Kinderziektes geven doorgaans alleen ernstige complicatie bij kinderen
met al onderliggend lijden en zwakte en door onderdrukking van chemische medicatie.
Sinds de vaccinaties zijn de auto-immuunziekten onder jonge kinderen schrikbarend
toegenomen. De genetische predispositie (zwakte) blijven ze immers met zich meedragen.
Dit heeft lichamelijk en geestelijk gevolgen voor het immuunsysteem. Meerdere
onderzoeken leggen verband tussen autisme en kwik/zware metalen.
    </P>   

    <List 
    title={'Reacties van direct merkbare schade tot aan ernstige schade.'} 
    items={[
         `Een normale reactie is koorts, hangerig, een rode plek, onrustig, pukkeltjes`
        ,`Symptomen van de (kinder)ziekte waartegen ingeënt wordt maar dan in een mildere
        vorm`
        ,`Bij cocktailprikken verschijnselen van een combinatie van de ziekten waartegen geënt
        wordt.`
        ,`Duidelijke complicaties die bij de ziekten horen waartegen geënt wordt.`
        ,<>Allergische reactie op één van de bijproducten die in een vaccin zitten zoals
        conserveermiddelen en bewaarstoffen. Probleem is dat voor de 1<sup>ste</sup> enting nog niet
        bekend is of het kind allergisch gaat reageren</>
        ,`Aantasting van de darmen en het zenuwstelsel door totaal verbruik van mineralen die
        nodig zijn voor de afbraak van de vele gifstoffen in het vaccin. Vooral een groot risico
        bij te veel vaccins tegelijk en te snel herhalen.`
        ,`Groeiachterstand of tijdelijk stoppen met ontwikkelen of terugvallen in ontwikkeling.
        Alle energie gaat in het verwerken van de vaccins zitten`
        ,`Geen enkele reactie. Dit kan juist duiden op schade die later zichtbaar wordt. Het
        lichaam heeft niet de mogelijkheid om tot reactie te komen om de ziekteverwekkers
        en gifstoffen uit het lichaam te halen.`
    ]}
    /><br/>




    <List 
    title={'Symptomen oplopend van mild naar zeer ernstig kunnen zijn:'} 
    items={[
          `Huiduitslag, koorts, onrustig`
          ,`Chronische oor ontstekingen`
          ,`Eczeem / Astma`
          ,`Darm ontstekingen`
         ,`Verandering van gedrag na vaccineren`
         ,`Slaapproblemen`
         ,`Onophoudelijk hard en hoog huilen`
         ,`Fysieke klachten van darm, huid of luchtwegen`
         ,`Eetproblemen`
         ,`Angstaanvallen`
         ,`Concentratieproblemen`
         ,`Contactstoornissen`
         ,`ADHD/Autisme en alle aanverwante ontwikkelingsstoornissen`
         ,`Epilepsie`
         ,`Allergie`
         ,`Auto-immuunziekten`
         ,`Narcolepsie`
         ,`SIDS (Sudden Infant Death Syndrome)`
    ]}
    /><br/>

    <List 
    title={'Waarom geven vaccinaties deze bijwerkingen?:'} 
    items={[
          `Door de wijze van toediening. Bij het inspuiten worden belangrijke barrières in de afweer overgeslagen`
         ,`Door de hoeveelheid verschillende bacteriën en virussen tegelijk in het vaccin`
         ,`Door de toevoegingen in het vaccin`
         ,<>Door het tijdstip van toediening. Het duurt tot tenminste het 1<sup>ste</sup> levensjaar dat een kind zelf de normale niveaus van afweerstoffen kan aanmaken</>
    ]}
        /><br />
        



    <P>
    Het blijft een moeilijke keuze voor ouders om wel of niet te vaccineren.
Gelukkig hoeft het niet zo zwart/wit te zijn.
Het is daarom belangrijk om vanuit verschillende bronnen informatie in te winnen alvorens
te gaan enten.
        </P>
        
        <div style={{paddingTop: '1%'}}>
<P>
Er zijn wereldwijd talrijke schadelijke bijwerkingen gemeld bij meisjes na het ontvangen van de Gardasil/Cervarix.<br/>
</P>
    <Quote contentStyle={{lineHeight: '170%', paddingBottom: '0'}}>
        <P style={{fontWeight: "bold"}} noBreak={true}>
        MEISJE, WIST JE DAT…
        </P>
        <P>
        …baarmoederhalskanker op jouw leeftijd helemaal niet voorkomt?<br/>
        …als je vanaf je 30ste (!!) elke vijf jaar een uitstrijkje laat doen, eventuele voorstadia van baarmoederhalskanker vroegtijdig ontdekt en goed behandeld kunnen worden?<br/>
        …op het internet duizenden artikelen en YouTubes circuleren om je te waarschuwen voor de bijwerkingen van het HPV vaccin?<br/>
        </P>
    </Quote>
</div>




<h3>Lees meer</h3>
<WebsiteLink href={'https://stichtingvaccinvrij.nl/ziektes-vaccins/vaccins/specifieke-vaccins/hpv-vaccin/'}></WebsiteLink>
<WebsiteLink href={'https://stichtingvaccinvrij.nl/weer-een-onnodig-en-risicovol-vaccin-toegevoegd-aan-het-rvp-hpv-vaccin-voor-jongetjes/'}></WebsiteLink>
<WebsiteLink href={'https://www.wanttoknow.nl/dossiers/gezondheid/vaccinaties-gezondheid/'}>https://www.wanttoknow.nl/dossiers/gezondheid/vaccinaties-gezondheid/</WebsiteLink>

    {/* <h2>- Het huidige RVP</h2> */}

    {/* <WebsiteLink href={'www.nvkp.nl'}>www.nvkp.nl</WebsiteLink> */}
        
    </>;

    var companyStory = <>
    <div>

            {_text1}

    </div>
</>;

    return (
        <>
            <div className="page">
                <HeaderSection_ThisPage title={headerTitle} />

                <div className="page-content">
                    <CompanyStory title={`${pageTitle}`} showAppointmentButton={false}>
                        {companyStory}
                    </CompanyStory>
                </div>
            </div>
        </>
    );
};

export function Vaccinaties_Keuzemogelijkheden () {
    const headerTitle = `Keuzemogelijkheden`;
    const pageTitle = `${values.titleSubject} - ${headerTitle}`;
    
    var _text1 = <>
    <h3 className='italic'>Het lichaam is geen machine waar je van alles instopt om het draaiende te houden</h3>
    <P>
    Iedereen is anders en kan dus ook anders reageren op vaccinaties. De erfelijke belasting, de
constitutie, het verloop van de geboorte, de sociale en emotionele omstandigheden spelen allemaal mee hoe hij/zij zal reageren op de vaccins.
Chemische middelen en koortsverlagende middelen kunnen de werking ontnemen tot
herstel maar kunnen in acute bedreigende situaties tijdelijk levensreddend zijn.
    </P>

    <ImagePage style={{paddingBottom: '32px'}} src={`${process.env.PUBLIC_URL}/images/layout/vaccinaties/Inhoudsstoffen.png`}/>
    {/* <br/><br/> */}

    <List 
    title={"Keuzemogelijkheden in afwijking van het RVP"} 
    items={[
         <>
         Starten wanneer uw kind zijn cellulaire (algehele afweer) voldoende opgebouwd heeft. 
         Begin bij vroeggeboorte te tellen vanaf de uitgerekende datum. Vaak
         worden vroeggeboren kinderen door de ent-administratie veel te vroeg opgeroepen
         met alle risico’s van dien.
         Wereldwijd zijn er te veel verschillen in de leeftijd waarop het kind zijn eerste
         vaccinatie krijgt om een eerste balans op te kunnen maken van de voor- en nadelen.
         Een vergelijkend onderzoek zou uitgevoerd moeten worden door kinderen in een
         bepaalde regio te volgen die tijdens de
         zwangerschap of met 2 maanden of met 10 maanden de
         1<sup>ste</sup> vaccin krijgen.
         </>
        ,`Sommige vaccins weglaten.`
        ,`Slechts een enkel vaccin tegelijk geven. Meerdere entingen tegelijk betekent ook
        dubbel zoveel hulpstoffen. Helaas is dit nog maar heel beperkt mogelijk.`
        ,`Met minder herhalingen volstaan of ruimere tussenpozen
        tussen de vaccins. Het nut van herhaalvaccins is nooit
        duidelijk aangetoond. De aanmaak van hoeveelheid
        antistoffen is per kind verschillend en afhankelijk van de
        constitutie en leeftijd.`
        ,`Na gedeeltelijke vaccinatie alsnog stoppen of afwijken van het schema.`
        ,`Ondersteunen met klassieke homeopathie volgens de ziekteclassificatie.`
        , <>
        Homeopathische Profylaxe.
        Doctor Golden uit Melbourne heeft 10 jaar onderzoek gedaan naar de werking van
        homeopathische profylaxe, en kwam tot de conclusie dat het een bescherming bood
        van gemiddeld 89 %. Vergelijkbaar dus met de beschermingsscore van 75 tot 95 % bij
        vaccinaties. Bij een kleine groep trad de ziekte nog op maar werd milder
        doorgemaakt en sneller genezen.
        Er zijn in de geschiedenis veel voorbeelden van succesvolle homeopathische
            beschermingen tijdens epidemieën. Bijvoorbeeld:
            <List 
    // title={"Keuzemogelijkheden in afwijking van het RVP"} 
    items={[`- Hahnemann bestreed in 1801 een tyfusepidemie met de homeopathische
    gepotentieerde uitscheidingen van een tyfuspatiënt.`
    ,`- In 1849 was het sterftecijfer van een cholera-epidemie in Europa 90%. Onder
    degenen die zowel preventief als achteraf met homeopathie werden behandeld, was
    het sterftecijfer slechts 16%.`
    ,`- In 1902 werd, tijdens een pokkenepidemie in Iowa aan 2806 mensen de profylaxe
    gegeven, waarmee een bescherming van 97% behaald werd.`
        
        ]}/>  
        </>
        
            ]}
        />       
        <br />

        

    <h3>Lees meer</h3>
    <a target='blank' href='/media/presentation-files/Homeopathische Profylaxe[281].pdf'><h3>PDF Bestand (Homeopathische Profylaxe[281])</h3></a>
    <br/>

    {/* <h2>Voor meer informatie hierover lees hier het PDF bestand: "Homeopathische Profylaxe"</h2> */}

    <List 
    title={"Waar moet u op letten als u besluit te vaccineren"} 
    items={[
         `Laat nooit uw zieke kind enten, zelfs een verkoudheid kan al een contra-indicatie zijn. Stel bij twijfel de
         vaccinatie uit, het vaccinatieschema is geen wet van Meden en Perzen. Zorg eerst voor een goede conditie en goede Vitamine C status. 
         Moeders kunnen bij borstvoeding zelf 3-4x daags 1000mg Vitamine C innemen. 
         Anders kan het met poeder in de flesvoeding of vaste voeding. Voor de allerkleinsten geldt maximaal 50-100mg per
         maand leeftijd over de dag verdeeld, minimaal in 3 doseringen. Vanaf een jaar geldt
         ongeveer 1000mg per jaar leeftijd met een maximum van 3 gram per dag. Van
         Vitamine C kun je geen overdosering krijgen, wel kan het bij het bereiken van de
         maximale dosering diarree ontstaan (bowel tolerance dose). De dosering dient dan verminderd te worden.`
        ,`Goede voeding is essentieel voor het verkrijgen en behouden van een goede
        gezondheid.`
        ,`Gebruik nooit een magnetron om flesvoeding of babyvoeding op te warmen. Het
        denatureert de voeding met veel kwaliteitsverlies en kan allerlei klachten geven,
        waaronder darmklachten en eczeem. verhit ook geen plastic fles.`
        ,`Geef uw kind essentiële vetzuren in de juiste verhouding Omega 6 en 3.`
        ,<>
        Er kan naast de acute reactie van een enting ook een reactie op de
        2<sup>de</sup>, 5<sup>de</sup>, 7<sup>de</sup>, 14<sup>de</sup> en mogelijk nog ongeveer drie tot vier weken erna plaatsvinden. Houd uw kind ook ‘s nachts goed in de gaten en
        noteer elke verandering.
        </>
        ,`Bagatelliseer een signaal niet, dit is een uiting om te zeggen dat uw kind moeite heeft met het verwerken van het vaccin. 
        Geef daarom nooit een volgend vaccin als de signalen nog aanwezig zijn. De reactie op de tweede kan dan heftiger
        zijn.`
        ,`Meld de reactie bij het CB en het RIVM/Lareb en verzeker uzelf dat het geregistreerd
        wordt. Meld de bijwerkingen ook bij de NVKP`
        ,`Eerst volledig herstel van post-vaccinale ziekteverschijnselen alvorens verder te
        vaccineren. Het is aan te raden om kinderen met een vermoedelijk post-vaccinaal
        syndroom met behulp van homeopathie volgens de ziekteclassificatie en/of de
        CEASE-therapie te laten behandelen`
    ]}
    /><br/>

    
<h3>Lees meer</h3>
<WebsiteLink href={'https://www.gezichtspunten.nl/boek-inenten-51.html'}>Gezichtspunten - Inenten Waarom wel? Waarom niet?</WebsiteLink>
    {/* <WebsiteLink href={'www.nvkp.nl'}>www.nvkp.nl</WebsiteLink> */}
        
    </>;

    var companyStory = <>
    <div>

            {_text1}

    </div>
</>;

    return (
        <>
            <div className="page">
                <HeaderSection_ThisPage title={headerTitle} />

                <div className="page-content">
                    <CompanyStory title={`${pageTitle}`} showAppointmentButton={false}>
                        {companyStory}
                    </CompanyStory>
                </div>
            </div>
        </>
    );
};

export function Vaccinaties_Virussen () {
    const headerTitle = `Virussen`;
    const pageTitle = `${values.titleSubject} - ${headerTitle}`;
    
    var _text1 = <>
    <h2>Bron: Tom Barnett (Australië)</h2>
    <P>
    Studeerde 6 jaar medische geneeskunde en 6 jaar natuurgeneeskunde. Deed 3 jaar
onderzoek naar virussen, bacteriën en schimmels.
    </P>

    <h3>Bestaan virussen?</h3>
    <P>
    Een virus is moeilijk te isoleren en lokaliseren, over hun bestaan moet nog gedebatteerd
worden, het is dus nog steeds een kwestie van geloof in plaats van een feit.
    </P>

    <h3>Wat is een virus dan wel?</h3>
    <P>
    Een virus ontwikkelt zich in het lichaam en niet erbuiten, het is de zeep (oplosmiddel) van
een cel die wordt gecreëerd in de cellen van ons lichaam die wel 100.000 verschillende
soorten kan creëren. Het is een persoonsgebonden reinigingsmechanisme wat probeert ons
in leven te houden. In feite is een virus dood en dus niet besmettelijk. Je kan het niet van
een land, ras of dier krijgen.
    </P>
    <P>
    We zitten vol virussen, zonder virussen is er geen leven mogelijk. Het lichaam slaat gifstoffen
die niet in staat zijn om direct het lichaam uitgewerkt te worden op in de vetcellen.
Gifstoffen uit onder andere vaccinaties, Nano fijnstof, voeding, roken, luchtvervuiling,
drinkwater, glyfosaat, medicatie , drugs , drank, 5G, hormonen en emotionele vervuiling.
Afhankelijk van de weerstand van een persoon en de ernst van de vervuiling wordt eens in
de zoveel tijd het reinigingssysteem door moeder natuur aangezet en dan word je ziek. Ziek
zijn is een proces tot heling die afhankelijk is van ernst van de vervuiling en de werking van
het immuunsysteem. Is deze verzwakt, goed of overactief? Is er onderliggend lijden?
    </P>

    <h3>En griep dan?</h3>
    <P>
    Een griep is een virale ontgifting of reiniging. Een virus probeert binnen in de cel de
opgehoopte gifstoffen af te breken waardoor je je grieperig voelt. Hoe meer afvalstoffen,
hoe zieker je je voelt en bij ernstige extreme hoeveelheden kunnen de cellen beschadigen of
leiden tot celdood. De plek waar virussen gecreëerd worden hangt af van de plek van de
verontreiniging. Vindt deze b.v. plaats in de lever dan worden andere organen in 1ste
instantie niet aangetast. Met andere woorden, het kan niet overgaan maar blijft op de plek
van de verontreiniging. Als het niet overslaat dan kan dus ook niet, zoals men beweerd,
overgedragen worden. De afvoer van de gifstoffen vindt wel plaats via de
uitscheidingsorganen.<br/>
Een relevante vaak gehoorde vraag is waarom men dan wel een epidemie ziet ontstaan. Een
plausibele verklaring kan zijn dat we een aangeboren universele intelligentie bezitten die in
contact staat met de medemens en precies weet op welke tijden het lichaam gereinigd moet
worden, net als moeder natuur dit doet via de seizoenen en het dierenrijk intuïtief weet
wanneer wat te doen.
    </P>

    <h3>Wat is dan wel besmettelijk?</h3>
    <P>
    Bacteriën en schimmels want dit zijn levende organismen en kunnen geïsoleerd en
gelokaliseerd worden en daarvoor besmettelijk. Ze reageren op Antibiotica wat een virus
niet doet.<br/>
We zien bacteriële infecties en schimmels heden ten dage enorm toenemen nu ons
immuunsysteem veel te verduren heeft.
    </P>

    <h3>Wat is het Corona virus dan?</h3>
    <P>
    Dit virus heeft een patent sinds 2019 genaamd Covid19. Was dit ook niet het jaar dat Corona
uitbrak?<br/>
Een logische conclusie kan zijn dat je alleen het Corona virus kan krijgen als je lichaamscellen
vergiftigd zijn en dat kan via een injectie wat massaal toegediend wordt. Het corona virus
kan je dus alleen krijgen als het geïnjecteerd is in je bloedbaan. Zoals al eerder vermeld, het
lichaam maakt pas een virus aan als een cel vergiftigd is, het is de afvalstof van een cel, het
wordt in je lichaam gecreëerd en niet erbuiten. Dus krijgt een hele groep mensen hetzelfde
virus, dan moet het massaal geïnjecteerd zijn in de bloedbaan.
In vaccins zitten veel gifstoffen zoals bewaar en hulpstoffen. Ook zijn vaccins vervuild met
dierlijk/foetaal weefsel.
Het doel van vaccineren is om virussen te elimineren, hoe kan dat als een virus niet levend
is?
    </P>

    <h3>- Tom Barnett (Australië)</h3>


    {/* <WebsiteLink href={'www.nvkp.nl'}>www.nvkp.nl</WebsiteLink> */}
        
    </>;

    var companyStory = <>
    <div>

            {_text1}

    </div>
</>;

    return (
        <>
            <div className="page">
                <HeaderSection_ThisPage title={headerTitle} />

                <div className="page-content">
                    <CompanyStory title={`${pageTitle}`} showAppointmentButton={false}>
                        {companyStory}
                    </CompanyStory>
                </div>
            </div>
        </>
    );
};

export function Vaccinaties_mRNA () {
    const headerTitle = `mRNA`;
    const pageTitle = `${values.titleSubject} - ${headerTitle}`;
    
    var _text1 = <>

{/* Image */}
    <div className='width80 margin-center'>
    <ImagePage className={'width50 pwidth100'} src={`${process.env.PUBLIC_URL}/images/layout/vaccinaties/mRNA.jpg`}/>
    </div>
    <br/>

    <h3>Reguliere visie</h3>
    <WebsiteLink href={'https://www.gezondheidenwetenschap.be/gezondheid-in-de-media/hoe-werkt-het-mrna-coronavaccin-en-is-het-veilig#article-detail-wrapper'}>https://www.gezondheidenwetenschap.be/gezondheid-in-de-media/hoe-werkt-het-mrna-coronavaccin-en-is-het-veilig/</WebsiteLink>

    <h3>Een andere kijk</h3>
    <WebsiteLink href={'/media/documents/theo-schetters.pdf'}>- PDF Bestand (Theo Schetters.pdf)</WebsiteLink>
    
    <br/>
{/* Text */}
    <P>
    Op 23-7-22 roept Tedros Ghebreyesus, directeur van de WHO,
apenpokken uit als een ‘noodsituatie op het gebied van de
volksgezondheid van internationaal belang’.
    Op dat moment zijn volgens dezelfde WHO wereldwijd slechts 5
mensen overleden aan – of met – de diagnose ‘apenpokken’.<br/>
<br/>
    Is het mRNA vaccin, deze (experimentele) gentherapie wel zo veilig?<br/>
<br/>
    Wat zit er nog meer in de spuit als toegevoegde stoffen? 

<br/>Vóór 2020 was er nog geen mRNA-
technologieplatform (medicijn of vaccin) goedgekeurd

voor gebruik bij mensen. De proefperiode (testfase) van de
mRNA Covid-19 vaccins loopt nog tot november 2023
    </P>

    <P className='italic'>
    Inmiddels kennen we de bijwerkingen, de oversterfte............
    </P>

{/* Links */}
<br/>
<br/>
<h3>Lees meer</h3>
    <WebsiteLink href={'https://artsencollectief.nl/category/onderbouwing/vaccinatie-onderbouwing/'}>https://artsencollectief.nl/category/onderbouwing/vaccinatie-onderbouwing/</WebsiteLink>
    <WebsiteLink href={'https://artsencollectief.nl/dna-verontreiniging-in-pfizer-en-moderna-mrna-vaccins/'}>https://artsencollectief.nl/dna-verontreiniging-in-pfizer-en-moderna-mrna-vaccins/</WebsiteLink>
    <WebsiteLink href={'https://stichtingvaccinvrij.nl/ziektes-vaccins/vaccins/covid-19-vaccin/'}>https://stichtingvaccinvrij.nl/ziektes-vaccins/vaccins/covid-19-vaccin/</WebsiteLink>
    <WebsiteLink href={'https://stichtingvaccinvrij.nl/het-spike-eiwit-is-gevaarlijk-waarom-geven-wij-het-lichaam-de-opdracht-om-spike-eiwitten-te-maken/'}>https://stichtingvaccinvrij.nl/het-spike-eiwit-is-gevaarlijk-waarom-geven-wij-het-lichaam-de-opdracht-om-spike-eiwitten-te-maken/</WebsiteLink>
        
    </>;

    var companyStory = <>
    <div>

            {_text1}

    </div>
</>;

    return (
        <>
            <div className="page">
                <HeaderSection_ThisPage title={headerTitle} />

                <div className="page-content">
                    <CompanyStory title={`${pageTitle}`} showAppointmentButton={false}>
                        {companyStory}
                    </CompanyStory>
                </div>
            </div>
        </>
    );
};

export function Vaccinaties () {
    const headerTitle = `Inleiding`;
    const pageTitle = `${values.titleSubject} - ${headerTitle}`;
    
    var _text1 = <>
    <h2>asdf</h2>
    <P>
    asdf
    </P>

    <WebsiteLink href={'www.nvkp.nl'}>www.nvkp.nl</WebsiteLink>
        
    </>;

    var companyStory = <>
    <div>

            {_text1}

    </div>
</>;

    return (
        <>
            <div className="page">
                <HeaderSection_ThisPage title={headerTitle} />

                <div className="page-content">
                    <CompanyStory title={`${pageTitle}`} showAppointmentButton={false}>
                        {companyStory}
                    </CompanyStory>
                </div>
            </div>
        </>
    );
};

/*let oudeText = <>
<P>
    Een sub-eenheid-proteïnevaccin of sub-unit-vaccin is een inert vaccin dat geen intacte,

volledige viruspartikels bevat maar één of meerdere gezuiverde of semi-
gezuiverde eiwitten waarbij andere viruscomponenten zijn verwijderd.

Vaak worden slechts enkele zorgvuldig geselecteerde eiwitten van het virus of de bacterie
gebruikt als antigenen voor de samenstelling van het vaccin.
Als vaccinatie tegen coronavirussen produceert men bijvoorbeeld de spike-eiwitten van het
virusomhulsel.
Bij de sub-unit-vaccins worden de micro-organismen eerst in celculturen gekweekt en
daarna worden de antigenen (eiwitten) via chemische weg losgeweekt.
De immunogeniciteit kan ook worden verhoogd door de sub-unit-eiwitten in te bouwen in

grotere partikels of te laten assembleren tot grotere partikels: virus-achtige partikels (‘virus-
like particles’, VLP) of Nano partikels (NP).
    </P>

    <P>
    Een RNA-vaccin of mRNA-vaccin is een type vaccin dat een kopie van messenger- RNA
(mRNA) gebruikt om een immuunrespons op te wekken.
Het vaccin transfecteert moleculen van synthetisch RNA (brengt deze in) immuniteitscellen.
Eenmaal in de immuun cellen functioneert het RNA van het vaccin als mRNA, waardoor de
cellen het vreemde eiwit bouwen dat normaal gesproken door een pathogeen (zoals een
virus) of door een kankercel wordt geproduceerd.
Die cellen doen dat dan met de instructies van het RNA dat in het vaccin zat.
De eiwitmoleculen die door het lichaam worden geproduceerd, stimuleren een adaptieve
immuunrespons die het lichaam leert de overeenkomstige ziekteverwekker of kankercellen
te identificeren en te vernietigen in het geval men later besmet zou geraken met de
ziekteverwekker.
Een DNA-vaccin is een type vaccin dat een specifieke antigeen-coderende DNA- sequentie
transfecteert in de cellen van een organisme als mechanisme om een immuunrespons op te
wekken.
De techniek dateert uit de jaren 1990, er is sindsdien ervaring mee opgedaan bij de
behandeling van kankerpatiënten.
De vaccins van BioNTech/Pfizer en Moderna zijn specifiek ontwikkeld om preventief
werkzaam te zijn tegen het SARS-CoV-2 virus.
Beide vaccins van deze bedrijven zijn van hetzelfde type: een zogeheten mRNA-vaccin.
In de spuit zit een stukje in het laboratorium gemaakte genetische code van het coronavirus.
Dit bevat de informatie waarmee cellen een eiwit kunnen maken dat de stekels vormt die
zichtbaar zijn op alle illustraties van het virus.
Met die stekels plakt het zich vast aan de cellen in ons lichaam om ze te infecteren.
Geïnfecteerde cellen gaan vervolgens nieuwe virusdeeltjes maken, die op hun beurt nog
meer cellen infecteren.
Dat stukje genetische code is verpakt in een vetbolletje. Na injectie in bijvoorbeeld de
bovenarm zullen cellen van het afweersysteem de vetbolletjes ophappen, waarna die het
stekel-eiwit gaan maken.

Het afweersysteem herkent dat eiwit als een vreemde indringer en gaat er vervolgens
antistoffen tegen maken.
    </P>

    <P>
    De stukjes genetische code van het virus zijn na een paar dagen al verdwenen.
Maar het afweersysteem heeft nu geleerd om het stekel- eiwit te herkennen en maakt er
afweerstoffen tegen.
Een tweede injectie met het vaccin (na drie of vier weken) zorgt dat er nog meer cellen
komen die afweerstoffen produceren.
Als een gevaccineerd persoon besmet raakt met het coronavirus, zullen deze afweerstoffen
aan het stekel-eiwit gaan zitten, waardoor de virusdeeltjes niet goed aan de cellen van het
slachtoffer kunnen plakken.
Mocht een cel toch nog geïnfecteerd worden, dan staat het afweersysteem klaar om hem
aan te vallen en op te ruimen, zodat hij geen nieuwe virusdeeltjes kan maken.
    </P>

    <P>
    Maar is deze (experimentele) gentherapie wel zo veilig? Wat zit er nog meer in de spuit als
toegevoegde stoffen?
Op 23-7-22 roept Tedros Ghebreyesus, directeur van de WHO, apenpokken uit als een
‘noodsituatie op het gebied van de volksgezondheid van internationaal belang’.
Op dat moment zijn volgens dezelfde WHO wereldwijd slechts 5 mensen overleden aan – of
met – de diagnose ‘apenpokken’.
Vóór 2020 was er nog geen mRNA-technologieplatform (medicijn of vaccin) goedgekeurd
voor gebruik bij mensen.
De proefperiode (testfase) van de mRNA Covid-19 vaccins loopt nog tot november 2023
    </P>
</>;*/