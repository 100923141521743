import React from 'react';
import { InfoEmail, InfoLocations, InfoPhone } from '../pages/home/company_info/Info';
import './HeaderSection.css';
import 'react-slideshow-image/dist/styles.css'
import { Slideshow,  } from 'images';
import parse from 'html-react-parser';


// import { InfoEmail, InfoLocations } from './company_info/InfoEmail';
// import  from './company_info/InfoLocations';

export default function HeaderSection(props) {
	
	let _mediaHeight = props.mediaHeight || "100%";

	// Default to true
	let _foreground = (props.foreground === undefined || props.foreground === false) ? false : true;

	let _fadedBackground = (props.fadedBackground === undefined || props.fadedBackground === true) ? true : false;

	let _offset = props.offset;
	
	// let _mid = 30;
	// let _mid2 = 60;
	let _mid = 10;
	let _mid2 = 30;

	let _min = 0;
	let _max = 100;

	let _color = '255, 255, 255';
	let _bgr = `linear-gradient(to right, 
		rgba(${_color}, ${0}%) 0%, 
		rgba(${_color}, ${_min}%) ${_mid}%, 
		rgba(${_color}, ${_max}%) ${_mid2}%,
		rgba(${_color}, ${_max}%) 100%)`;
	let _bgrWebkit = `-webkit-linear-gradient(to right, 
		rgba(${_color}, ${0}%) 0%, 
		rgba(${_color}, ${_min}%) ${_mid}%, 
		rgba(${_color}, ${_max}%) ${_mid2}%,
		rgba(${_color}, ${_max}%) 100%)`;

	const fadeImages = props.imageArray;
	//  importAllArray(
	// 	require.context('../../../../../public/images/portfolio/layout/pages/home/', true, /^(?!.*\/not-used\/).*\/.*.\.(JPG|png|jpe?g|svg)$/)
	// );
	  //style={{height: props._mediaHeight}} 
//, from-transparent
    return (<>
        <div style={{maxWidth: props.width}} className='hero-container-wrapper shadow-under'>
            <div className='hero-container'>
                {/* <img className="background" src="./images/img-header-spanen.jpeg"></img> */}
                <div className={`${props.classNameContentWrapper} hero-content-wrapper`} style={{height: _mediaHeight, ...props.styleContentWrapper}}>
                	{/* <div className='colored-background bgr bgr-big'></div> */}
					<div className='hero-media-wrapper'>

						{/* Video */}
						{props.video != undefined ?
						<video className="header-media header-video" style={{height: _mediaHeight}} src={props.video} autoPlay loop muted />
						: ""}

						{/* Image */}
						{props.image != undefined ?
						<>
							{/* The blurred background */}
							{/* {_fadedBackground ? <div className='header-image-bgr-wrapper'>
								<img className="header-image-bgr" style={{height: _mediaHeight}} src={props.image}></img> 
							</div> : ''} */}


							<img className={`${props.imageClassName} header-media header-image`} style={{...props.imageStyle, height: _mediaHeight}} src={props.image}></img> 
						{/* <div style={{height: "17vw"}}></div> for a moving navbar */}
						</>
						: ""}
						
						{/* Multiple Images */}
						{props.imageArray != undefined ?
						<>
						{/* style={{transform: 'translateX(1px)'}} */}
						<div className='slideshow-container'><Slideshow offset={[_offset]} className={`header-media ${props.className}`} images={fadeImages} mediaHeight={_mediaHeight}/></div>
						</>
						: ""}

						{/* Foreground */}
						{_foreground ? <div className='header-media-foreground' style={{height: _mediaHeight}}></div> : ""}
						{/* backgroundImage: _bgr,  */}

						

					</div>

					<div className='hero-content'>
						{/* Logo */}
						{props.logo ? <img className="header-logo" src={window.publicURL + global.config.companyMedia.logoWhite}></img> : ""}
						{/* {props.logo ? <img className="header-logo" src={global.config.companyMedia.logoWhite}></img> : ""} */}

						{/* {props.titleBackground ? <h1 className='hero-content-wrapper-title a-to-transparent a-duration13 text-background'>{props.titleBackground}</h1> : ""} */}
							
						{(props.title || props.quote) ? <>
							<div className='hero-content-wrapper-title-wrapper'>
								<h3 className='hero-content-wrapper-title'>{props.title}</h3>
								<div className='hero-content-wrapper-quote-wrapper'>
									{props.quote ? <h2 className='hero-content-wrapper-quote glow-black a-from-bottom a-from-transparent a-duration20' style={{...props.quoteStyle}}>{props.quote}</h2> : ''}
								</div>
							</div>
						</> : ''}

						{/* Als er een einde aan komt aan de oorzaak, houd ook het gevolg op! met rose */}

						{/* <p>Generic slogan here</p> */}
						
						{/* <div className="hero-btns">
							<Button className='btns' buttonStyle='btn--outline' buttonSize='btn--large'>PORTFOLIO</Button>
							<Button className='btns' buttonStyle='btn--primary' buttonSize='btn--large'>OFFERTE AANVRAGEN <i className="fas fa-arrow-circle-right arrow"></i></Button>
						</div> */}

						{(props.story != undefined) ? <>
								{props.story}
							</> : ""}

						{(props.showInfo != undefined && props.showInfo == true) ? 
							<div className="contact-info-wrapper">
								<ul className="contact-info">
									<li><InfoPhone index='0' /></li>
									<li><InfoEmail index='0' /></li>
									{/* <li><InfoEmail index='1' /></li> */}
									<li><InfoLocations index='0' /></li>
								</ul>
							</div>
						: ""}
					</div>
                </div>

            </div>
				
        </div>
    </>)
}

//	<h1 className='header-column-image-title'>Welkom</h1>
// /projecten/testwebsites/homeopathie
export function HeaderSectionHome(props) {
	let _return = <></>;

	let headerStory = <><div className='shadow-none header-story-content'>
<div className='header-column-image-wrapper'>
    <div className='header-column-image-foreground'><div className='header-column-image-foreground-2'></div></div>
    

	{/* Image */}
    <img className='header-column-image' src='/images/layout/home/home_girl2.jpg'/>

	{!window.isPhoneSize ? (props.title ? <h1 className='text-center text-accent5Dark glow-white header-column-title'>Even Voorstellen</h1> : "") : ''}

	</div>
	
	
<div className='column-wrapper column-wrapper-header height100'>
    <div className='column column-filler'>

    </div>
    <div className='column'>
        <div className='header-story'>
            <ul className='header-list glow-white text-accentDarker from-left'>
			{window.isPhoneSize ? (props.title ? <h2 className='text-center text-accent5Dark glow-white'>Even Voorstellen</h2> : '') : ''}
                <li><div className='header-list-item'>
                    Natuurlijk vrij en ongebonden
                    <br/>los van wat ziekmakend bindt
                    <br/>Natuurlijk heelt oude wonden
                    <br/>vervliegen met de wind
                </div></li>
                <li><div className='header-list-item'>
                    Geduldig ligt te wachten 
                    <br/>vredig, kalm en stil
                    <br/>Het ware zelf zonder klachten 
                    <br/>en natuurlijk de vrije wil
                </div></li>
                <li><div className='header-list-item'>
                    Dat ik u en het kind 
                    <br/>respectvol en aandachtig zie
                    <br/>Dat ik de passende middelen vind 
                    <br/>natuurlijk met klassieke homeopathie
                </div></li>
                <li><div className='header-list-item'>
                    Dat jong en oud, vrij van pijn
                    <br/>geheel/d gezond mogen zijn!
                </div></li>	
            </ul>
        </div>
    </div>
    <div className='column'>
        <div className='header-namelist text-accent5 capital'>
            <ul className='text-accent5 header-list text-center'>
                <li className='header-namelist-big'><div className='header-list-item'>ANITA SCHUIT</div></li>
                <li><div className='header-list-item'>KLASSIEKE HOMEOPATHIE</div></li>
                <li><div className='header-list-item'>NATUURGENEESKUNDE</div></li>
                <li><div className='header-list-item'>FYTOTHERAPIE</div></li>
                <li><div className='header-list-item'>SPELTHERAPIE</div></li>
            </ul>
        </div>
    </div>
</div>

</div>
</>;

	_return = <HeaderSection offset={'50%'} mediaHeight={'470px'} className={'home'} story={headerStory} foreground={false} showInfo={false} logo={false} />//width={'1200px'}


	return _return;
}

export function HeaderSection_Page(props) {
	let _return = <></>;

	const _quote = props.quote;

	const _title = props.title;
	const _pageTitle = props.headerTitle;
	const _titleBackground = props.titleBackground;
	const _image = props.image;
	const _imageStyle = props.imageStyle;
	const _quoteStyle = props.quoteStyle;
	const _story = props.story;
	const _height = props.height || '350px';

	_return = <HeaderSection imageClassName={props.imageClassName} classNameContentWrapper={props.classNameContentWrapper} styleContentWrapper={props.styleContentWrapper} title={_title} imageStyle={_imageStyle} headerTitle={_pageTitle} quote={_quote} story={_story} quoteStyle={_quoteStyle} titleBackground={_titleBackground} mediaHeight={_height} image={_image} showInfo={false} logo={false} />


	return _return;
}



//   const fadeImages = [
// 	{
// 	  url: 'https://images.unsplash.com/photo-1509721434272-b79147e0e708?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1500&q=80',
// 	  caption: 'First Slide'
// 	},
// 	{
// 	  url: 'https://images.unsplash.com/photo-1506710507565-203b9f24669b?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1536&q=80',
// 	  caption: 'Second Slide'
// 	},
// 	{
// 	  url: 'https://images.unsplash.com/photo-1536987333706-fc9adfb10d91?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1500&q=80',
// 	  caption: 'Third Slide'
// 	},
//   ];

//   const Slideshow = (props) => {
// 	console.log(props._mediaHeight);
// 	return (
// 	  <div className="slide-container header-media">
// 		<Fade infinite={true} style={{height:'100%'}}>
// 		  {fadeImages.map((fadeImage, index) => (
// 			<div  key={index}>
// 			  <img className='header-slideshow' style={{ width: '100%'}} src={fadeImage.url} />
// 			  <h2>{fadeImage.caption}</h2>
// 			</div>
// 		  ))}
// 		</Fade>
// 	  </div>
// 	)
//   }