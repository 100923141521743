/*
In your main file "index.js" put this line:
import './config';

Everywhere you need your object use this:
global.config.companyInfo
*/
// import 'entities.js';


module.exports.global = global.config = {
    websiteInfo: {
        language: "nl"
        ,creationDate: "2023"
    }
    //global.config.companyInfo
    ,companyInfo: {
        name: "Klassiekehomeopathie Groningen"
        ,abbreviation: ""
        ,establishedDate: new Date(2007, 9, 12)
        ,description: ""
        ,owner: "Anita Schuit"
        ,ownerImage: '/images/company/anita.jpeg'

        // asdf
        ,nvkh: "01-0935"
        ,rbcz: "170401R"
        ,agb: "90(0)02745/90023889"
        ,kvk: "01110468"
        ,btw: "NL001119172B05"

        ,businessHours: {
            maandag: ["7:30", "16:30"]
            ,dinsdag: ["7:30", "16:30"]
            ,woensdag: ["7:30", "16:30"]
            ,donderdag: ["7:30", "16:30"]
            ,vrijdag: ["7:30", "14:30"]
        }

        // Location
        ,country: "Nederland"
        ,address: {
            whole: "Allardsoogsterweg  6" + ", " + "9354VR Zevenhuizen"
            ,street: "Allardsoogsterweg"
            ,number: "6"
            ,city: "Zevenhuizen"
            ,stateProvince: "Friesland"
            ,zipCode: "9354VR"
        }
        
        // Email's
        ,emailMain: "ageschuit@gmail.com"
        ,emailExample: "email.example@example.com"
        ,emails: {
            offerte: "email.example@example.com"
        }

        // Phone numbers
        ,phoneNumberMain: "(+31) 6 36 20 27 65"
        ,phoneNumbers: {
            phoneNumber01: "(+31) 5 55 55 55 555"
        }
    }
    ,companyMedia: {
        logo: "/logo/logo_KHH_big.png"
        ,logoWhite: "/logo/logo_KHH_small.png"
    }
    ,authorInfo: {
        name: "Peter Schreuder"
        ,emailMain: ""
        ,phoneNumberMain: ""
        ,website: ""
    }
	,placeholder: {
		image: "/images/placeholder/placeholder.jpg"
	}
}



/*

,variable: ""

*/