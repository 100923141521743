import React, { useEffect, useState } from 'react';
import {HeaderSection_Page } from '../layout/header/HeaderSection';
import CompanyStory from '../layout/text/CompanyStory';
import { Container, ContainerContent, ContainerWrapper, ContainerGroup } from 'components/layout/containers/Containers';
import { List, PhotoAndText, Quote, VideoPage } from 'components/layout/text/TextComponents';
import { P, QuoteByPerson, Title } from 'styling/TextTypes';

const values = { 
    titleSubject: `De Praktijk`
    // ,quote: `Als er een einde komt aan de oorzaak, houdt ook het gevolg op`
    ,image: `${process.env.PUBLIC_URL}/images/layout/de-praktijk/boek-header.jpeg`
}

//Als er een einde komt aan de oorzaak, houdt ook het gevolg op


const headerStory = <>
<div className='font-p padding-horizontal medpwidth100' style={{paddingLeft: '200px', width: '100%', padding: '2%'}}>
<br/>
<QuoteByPerson style={{paddingLeft: 'clamp(15px, 6vw, 150px)'}} whoClassName={'text-white margin-center'} text={
<div className='font-size-flex-medium text-white glow-black'>
<br/>
"Niet de arts maar het lichaam geneest de ziekte."<br/>
"Laat voedsel uw medicatie zijn en niet medicatie uw voedsel."</div>} who={'Hippocrates 460-370 v. Chr'}/>
</div>
<br/>

</>;

function HeaderSection_ThisPage(props) {
    let _return = <></>;

	_return = <HeaderSection_Page 
    title={props.title} 
    titleBackground={values.titleSubject} 
    quote={values.quote} 
    image={props.image || values.image}
    story={headerStory}
    />

	return _return;
}

export function DePraktijk_Inleiding () {
    const headerTitle = `Inleiding`;
    const pageTitle = `${values.titleSubject} - ${headerTitle}`;
    
    var _text1 = <>
<P>
Een erkende homeopaat moet aangesloten zijn bij een beroeps- of branchevereniging, wat
ook maakt dat u de consulten (deels) vergoed krijgt uit het aanvullende zorgpakket. Een
beroepsvereniging is discipline-uniek en heeft specifieke kwaliteitseisen. Voor de klassiek
homeopaat is deze beroepsvereniging de <b>NVKH</b> die een eisenpakket heeft om lid te worden.
Een uitgebreide visitatie, elk jaar verplichte nascholingen waaronder medisch en deelname
aan een intervisiegroep.
</P>   

<P>
        Omdat ik een bijzondere affiniteit heb met kinderen behandel ik deze het meest in mijn
praktijk, maar ik ben er natuurlijk voor jong en oud, iedereen is uniek en
elk levensverhaal/ziektegeschiedenis treft mij. Mijn passie en doel is om elk lijden met zijn
klachten om te zetten naar heling.<br/>
In mijn 25 jaar ervaring is gebleken dat veel klachten veroorzaakt worden door vaccinaties.
Alsmede hierdoor ben ik mij gedurende de jaren gaan specialiseren in de vaccinatie
problematiek. Ik ben lid van de <b>N</b>ederlandse <b>V</b>ereniging voor <b>K</b>ritisch <b>P</b>rikken (NVKP) en
erkend behandelaar van de <b>CEASE</b> therapie en de <b>Banerji protocollen</b>.
</P>    

        
    </>;

    var companyStory = <>
    <div>
    <h2 className='font-p text-accent5 italic glow-white'>
    Een vakkundige homeopaat!
        </h2>
        {/* <br/> */}

        <PhotoAndText image={`/images/layout/filler/drie2.jpg`} imageLeft={false}>
            {_text1}
        </PhotoAndText>

<P>
Wat mij het diepste raakt is als een gezinsstructuur zich hersteld wanneer een ziek kind weer opknapt en/of een (langdurige) kinderwens vervuld wordt. Mijn hart gaat zingen als ik hoor “ ik ben zwanger” of “ mijn kind maakt contact, hij/zij begint te praten of “ ik heb mijn kind terug”.
</P>

<P>
Ik werk volgens de ziekteclassificatie daar dit een breed en logisch inzicht geeft in de
verschillende oorzaken van de klachten met zijn ziekteverloop. Met deze methode kan ik alle
factoren die aanleiding hebben gegeven tot ziekte analyseren en de behandeling vanuit vele
invalshoeken met de passende middelen inzetten.
</P>

<P>
Hahnemann heeft in de 6de editie van de Organon de ziekteclassificatie uitvoerig beschreven. Door erfrechten is de 6de editie pas 80 jaar na zijn overlijden uitgebracht. Ondertussen waren de inzichten van de 4de editie (Kentiaans) onder homeopaten wereldwijd verspreid en de meeste van hen werken nog vanuit die inzichten. 
De basis voor een anamnese, namelijk het nauwkeurig in beeld brengen van de symptomen met zijn ziekteverloop, is bij beide hetzelfde maar de behandeling is verschillend.
<br/>
<b>Kentiaans</b>: 1 toediening van 1 korrel (vaak C of K-potentie) die wekelijks, maandelijks herhaald wordt.
<br/>
<b>Ziekteclassifcatie</b>: Toediening via reuk/inhalatie methode (LM-potentie) met meerdere middelen die elkaar in volgorde versterken. Met schudslagen komt de energie vrij die direct via het neusslijmvlies opgenomen wordt.<br/>
Hahnemann ontdekte dat de LM-potenties het diepst en tevens mildst werken. 
            </P>
            
<P>
Een homeopathische behandeling stimuleert het zelf-genezend vermogen om zich te her- inneren wat en waar het iets moet doen om te helen. Het proces tot heling doen we samen. Ik heb uw actieve inbreng nodig om de klachten in beeld te brengen met zijn achterliggende oorzaak op fysiek, mentaal, emotioneel en spiritueel vlak.
</P>    



<Title>Anamnese</Title>
<P>
In een eerste consult geef ik alle ruimte om uw verhaal te doen u heeft mijn 100% aandacht, goed luisteren is een 1ste vereiste. Daarnaast het systematisch vastleggen van alle lichamelijke symptomen met de belangrijkste 
                gebeurtenissen in uw leven. Wat heeft u gevormd tot de persoon die tegenover mij zit en om hulp vraagt.
</P>
<P>
Wanneer er sprake is van een chronische situatie moeten er diepere en meerdere middelen
ingezet worden. Een tussenmiddel om de dynamis te activeren en het lichaam te reinigen,
een nosode om erfelijke belasting aan te spreken en een acuut middel (plantaardig of
dierlijk) voor de opleving. In een later stadium pas het constitutiemiddel (mineraal) om het
geheel tot rust te brengen.
</P>  
            
<List 
subTitle={"Wat heb ik hiervoor nodig:"} 
items={[
     "Alle huidige ziektesymptomen met zijn bijzonderheden;"
    ,"Het algemeen welbevinden: bijzonderheden over energie, slaap, menstruatie, kouwelijk of juist zeer snel warm, uit- en afscheidingen, eet- en drinkgewoonten etc. "
    ,"De huidige emotionele/mentale gemoedstoestand, ziekten en gebeurtenissen die zich in het leven hebben afgespeeld en de manier waarop dit werd beleefd en daarmee omgegaan werd. "
    ,"Hoe was de kindertijd "
    ,<>Informatie over medicijngebruik. Ik zal dan ook naar de symptomen vragen <b>voor</b> de inname van medicatie. Reguliere en homeopathische medicijnen kunnen naast elkaar gebruikt worden. Bij verbetering van de klachten kan er veilig afgebouwd worden van de reguliere medicatie </>
    ,"In de familie voorkomende ziekten zijn enorm belangrijk om mee te nemen in de behandeling. "
]}
/>

<br/>
<br/>
     
<Title>Het vervolgconsult</Title>
<P>
Ik neem 10 dagen na de anamnese telefonisch contact met u op voor evaluatie om zo nodig bij te sturen en voor eventuele nadere uitleg (hier zijn geen kosten aan verbonden) 
<br/>
Het vervolgconsult vindt doorgaans plaats na 6 a 8 weken. 
De ernst van de klachten bepaalt de hoeveelheid consulten. De juiste middelen ondersteunen het zelf-genezend vermogen, uw lichaam geeft zelf aan wanneer het geheeld is.
</P>    

  

<Title>Acuut consult</Title>
<P>
Een enkelvoudige acute situatie kan volstaan met 1 acuut middel. Denk dan b.v. aan een
hersenschudding, brandwond, ontsteking of emotionele gebeurtenis. De meeste klanten in
mijn praktijk hebben een EHBO doos met homeopathische middelen die dan ingezet kan
worden. Een telefonisch spoedconsult kan dan volstaan.
                <br />
                <br />
                
                Alle symptomen op alle niveaus van het mens-zijn leiden naar een uniek homeopathisch behandelplan. 
                U krijgt meerdere middelen die elkaar in volgorde versterken in reukdosis (met een LM Potentie), direct mee.
                <br />
<br/>
Naast de homeopathische middelen zet ik regelmatig tincturen in om een orgaan extra te
ondersteunen/versterken en adviseer ik voedingssupplementen (orthomoleculair).
Voeding krijgt ook de nodige aandacht.
</P>    

<P>
<Quote>
Regelmatig krijg ik de vraag om de vaccins en/of geneesmiddelen, narcose, oxytocine, straling, ander gif en nu ook de mRNA vaccin te ontstoren.
</Quote>

Dit kan mogelijk zijn met behulp van de <b>CEASE-therapie</b>, wat betreft de <b>mRNA</b> vaccin is dit
een ander verhaal en licht ik graag toe.
<br/>
Meestal ontstoor ik ook volgens de <b>ziekteclassificatie</b>. Dit houdt in dat ik breder kijk dan
alleen de stof die ontstoord dient te worden, namelijk naar de gehele mens. Wat speelt er
nog meer als storende factor mee.
</P>    

<P>
In een situatie met een diepe chronische verstoring kan ik besluiten om ook te behandelen
volgens <b>de Banerji protocollen</b>.
            </P>    
            
     {/* Video */}
<div className={'width100'}>
<VideoPage classNameVideo={'width75 pwidth100 center-div-horizontal'}
title={<>YouTube video van Stichting Hahnemann Homeopathie: "Wat is homeopathie en hoe werkt homeopathie?"</>}
videoID={'XiJmbdqOZOw'}    
/>
</div>       

    </div>
</>;

    return (
        <>
            <div className="page">
                <HeaderSection_ThisPage title={headerTitle} />

                <div className="page-content">
                    <CompanyStory title={`${pageTitle}`} showAppointmentButton={false}>
                        {companyStory}
                    </CompanyStory>
                </div>
            </div>
        </>
    );
};


export function DePraktijk_Verloskunde () {
    const headerTitle = `Verloskunde`;
    const pageTitle = `${values.titleSubject} - ${headerTitle}`;
    let image = undefined;
    // image = `${process.env.PUBLIC_URL}/images/layout/de-praktijk/zwanger.jpg`;
    
    var companyStory = <>
    <div>
<P>
        Zwangere vrouwen voelen vaak een weerstand tegen het nemen van reguliere medicatie.
Homeopathische middelen kunnen dan een veilig alternatief zijn voor moeder en kind.
Te denken valt aan de volgende klachten:
</P>    

        <PhotoAndText image={`/images/layout/de-praktijk/zwanger.jpg`} width={80} imageWide={true} imageLeft={false}>
            <List 
            title={"Tijdens de zwangerschap:"} 
            items={[
                "Misselijkheid"
                ,"Bekkeninstabiliteit"
                ,"Emotionele disbalans/Hormonen"
                ,"Slaapstoornissen"
                ,"Maagzuur"
                ,"Aambeien"
                ,"Spataderen"
                ,"Eczeem"
                ,"Schildklierklachten"
                ,"Diabetes/pre-eclampsie"
                ,"Bloedarmoede"
                ,"Etc........."
            ]}
            />
        </PhotoAndText>        
        <br/> 
        
        <ContainerGroup style={{justifyContent: 'space-evenly', alignItems: 'stretch'}}>
            <ContainerWrapper className={'container-wrapper-grow'}>
                <Container style={{overflow: 'visible', alignItems: 'stretch'}} background={false}>
                    <ContainerContent noPadding={true}>
                    <List 
                    title={"Tijdens de bevalling:"} 
                    items={[
                        "Stuitligging"
                        ,"Zwakke, storm/rug of naweeën"
                        ,"Onvolledige ontsluiting"
                        ,"Vastzittende placenta"
                        ,"Bloedingen"
                        ,"Herstel van sectio"
                        ,"Angst"
                        ,"Etc......."
                    ]}/>
                    </ContainerContent>
                </Container>
            </ContainerWrapper>
            <ContainerWrapper className={'container-wrapper-grow'}>
                <Container style={{overflow: 'visible'}} background={false}>
                    <ContainerContent noPadding={true}>
                    <List 
                    title={"Tijdens de kraamtijd:"} 
                    items={[
                        "Emotionele disbalans/Hormonen"
                        ,"Postpartum depressie"
                        ,"Wondgenezing"
                        ,"Bostvoedingproblemen"
                        ,"Borstontsteking en andere ontstekingen"
                        ,"Etc........."
                    ]}
                    />
                    </ContainerContent>
                </Container>
            </ContainerWrapper>
            <ContainerWrapper className={'container-wrapper-grow'}>
                <Container style={{overflow: 'visible'}} background={false}>
                    <ContainerContent noPadding={true}>
                    <List 
                    title={"Bij de baby:"} 
                    items={[
                        "Geelzucht"
                        ,"Slaapproblemen /huilen"
                        ,"Reflux"
                        ,"Darmklachten/koemelk-intolerantie"
                        ,"Berg"
                        ,"Spruw"
                        ,"Luieruitslag"
                        ,"Eczeem"
                        ,"Ontstekingen"
                        ,"Klachten na de vaccinatie"
                        ,"Doorkomende tandjes"
                        ,"Etc.........."
                    ]}
                    />
                    </ContainerContent>
                </Container>
            </ContainerWrapper>
        </ContainerGroup>


        

        <br/> 

        {/* <List 
        title={"Bij de baby:"} 
        items={[
            "Geelzucht"
            ,"Slaapproblemen /huilen"
            ,"Reflux"
            ,"Darmklachten/koemelk-intolerantie"
            ,"Berg"
            ,"Spruw"
            ,"Luieruitslag"
            ,"Eczeem"
            ,"Ontstekingen"
            ,"klachten na de vaccinatie"
            ,"Etc.........."
        ]}
        /> */}
        <br/> 

    </div>
</>;

    return (
        <>
            <div className="page">
                <HeaderSection_ThisPage title={headerTitle} image={image} />

                <div className="page-content">
                    <CompanyStory title={`${pageTitle}`} showAppointmentButton={false}>
                        {companyStory}
                    </CompanyStory>
                </div>
            </div>
        </>
    );
};

export function DePraktijk_KinderenPubers () {
    const headerTitle = `Kinderen / Pubers`;
    const pageTitle = `${values.titleSubject} - Kinderen`;
    const pageTitle2 = `${values.titleSubject} - Pubers`;
    
    var companyStory = <>
    <div>
<P>
        Homeopathie is bij uitstek geschikt voor kinderen omdat zij minder belast, nog vitaal en
veerkrachtig zijn en daardoor snel reageren op de middelen.
Ze komen met veel situaties in aanraking waardoor ze uit evenwicht kunnen raken. Ze
worden regelmatig blootgesteld aan ziektekiemen en toxines, maar ook kunnen
gebeurtenissen als een scheiding, verlies, een nieuwe school, pesten erg ingrijpend zijn.
</P>    
<P>
        In mijn praktijk kan uw kind naar hartenlust spelen, wil hij/zij bij papa en/of mama blijven,
ook goed, vertrouwen moet groeien. Allereerst stel ik uw kind gerust en leg uit dat we over
de klachten gaan praten en als er iets gezegd word wat voor zijn/haar gevoel niet juist is dit
kan zeggen, daar hij/zij alleen weet hoe iets werkelijk zit en/of voelt. Verder maak ik
duidelijk dat alles goed is zoals het is maar dat er iets is wat stoort. Tijdens het gesprek
observeer ik met een schuin oog en maak ik contact wanneer ik opmerk dat uw kind iets wil vertellen.
Ik ga uit van de mogelijkheden in plaats van de beperkingen en met luchtigheid en humor
komen we snel bij de verdieping.
</P>    
<P>
        In de meeste gevallen vinden kinderen het reuze interessant dat ze aan flesjes waar de
passende middelen in zitten mogen ruiken en lopen dan ook blij de praktijkdeur uit. 
Het geeft mij telkens weer een bijzonder dankbaar gevoel.. Heel
vaak willen ze blijven en komen de 2de keer zonder schroom binnen stormen en vertellen
breeduit over de (verdwenen) klachten.
Kinderen voelen nog intuïtief aan wat goed voor ze is, de wonderlijke ervaring leert dat ze
exact aan kunnen geven welk middel ze (nog) nodig hebben en welke niet. Zelfs baby’s slaan
of het reukflesje weg of steken hun neusje juist diep in het flesje.
</P> 

        <PhotoAndText image={`/images/layout/de-praktijk/kind.jpeg`} imageLeft={false}>
            <List 
            title={"Te denken valt aan de volgende klachten:"} 
            items={[
                "Acute en chronische ontstekingen"
                ,"Allergieën"
                ,"Spijsverteringsklachten"
                ,"Luchtwegklachten"
                ,"KNO-klachten"
                ,"Huidklachten"
                ,"Bedplassen/blaasontstekingen"
                ,"Kinderziekten"
                ,"Auto-immuunziekte"
                ,"Autisme/AD(H)D"
                ,"Ontwikkelingsstoornissen"
                ,"Vaccinatieschade"
                ,"Slaapproblemen"
                ,"Eetproblemen"
                ,"Angsten"
                ,"Gebitsproblemen"
                ,"Gevolgen van een traumatische gebeurtenis"
                ,"Ondersteuning bij vaccinaties"
                ,"Ondersteuning bij leerproblemen"
                ,"Ondersteuning bij ingrijpende veranderingen"
                ,"Pfeiffer"
                ,"Lyme"
            ]}
            />
        </PhotoAndText> 

        

        <div style={{paddingTop: '1%'}}>
        <Quote>
        Er leven in deze tijd veel hoog gevoelige kinderen die niet mee kunnen/willen komen in het huidige <b>onder-wijssysteem</b> omdat ze verlangen naar <b>boven-wijs.</b>
        </Quote>
        </div>
{/* // TODO hier */}
<P>
        Er leven in deze tijd veel hoog gevoelige kinderen die overgevoelig zijn voor sfeer, geluid,
voeding, medicatie etc. en niet mee kunnen/willen komen in het huidige onder-wijssysteem
omdat ze verlangen naar boven-wijs.
Dit valt in veel gevallen niet altijd te veranderen daar de oorzaak buitenaf ligt maar homeopathie kan wel het kind sterker en meer weerbaarder maken.
Ook kan de oorzaak te vinden zijn in vaccinaties en een zware erfelijke belasting. Homeopathie kan veel gedrags- en
ontwikkelingsstoornissen aanzienlijk verminderen.
</P>  

    </div>
    </>;

    var companyStory2 = <>
    <div>
<P>
Pubers worden vaak in 1ste instantie door hun ouders gestuurd en is de welwillendheid nog
niet aanwezig daar ze de noodzaak (nog) niet in zien. Vaak nemen ze zichzelf en de klachten
niet serieus, is oorzaak en gevolg nog niet (helemaal) helder en denken dat het vanzelf wel
weer over gaat.
Ook hier moet eerst het vertrouwen groeien, het is ook eng in deze fase van het leven om
jezelf bloot te durven geven. Het werkt al heel bevrijdend wanneer ze merken gedurende
het gesprek dat ik ze wel serieus neem, dat alles goed is wat ze zeggen, ze zonder kritiek en
veroordeling volledig geaccepteerd worden.
</P>

<P>
        Door hun drukke leven zijn pubers niet altijd therapiegetrouw maar als ze de positieve
resultaten gaan bemerken dan gaan ze er helemaal voor en lopen de vervolgconsulten
gemakkelijker.
Het leven op dit moment drukt extra op hun schouders, veel is ze afgenomen en de
toekomst is nog meer onzeker als voorheen.
</P>  

        <PhotoAndText image={`/images/layout/de-praktijk/puber.jpeg`} imageLeft={false}>
            <List 
            title={"Naast de klachten die ook bij kinderen te vinden zijn valt nog te denken aan:"} 
            items={[
                "Acne"
                ,"Menstruatieklachten"
                ,"Migraine"
                ,"Vaccinatieschade (mRNA)"
                ,"Verslavingen"
                ,"Depressie"
                ,"Gebitsproblemen"
                ,"Gedragsstoornissen"
                ,"Suïcidale gedachten"
                ,"Identiteitsontwikkeling"
                ,"Eetproblemen / anorexia / boulimia"
                ,"Tics / gilles de la tourette"
                ,"Dwangstoornis"
                ,"Pfeiffer"
                ,"Lyme"
            ]}
            />
        </PhotoAndText> 

        
        <br/>  

    </div>
    </>;

    return (
        <>
            <div className="page">
                <HeaderSection_ThisPage title={headerTitle} />

                <div className="page-content">
                    <CompanyStory showAppointmentButtonBottom={false} title={`${pageTitle}`}>
                        {companyStory}
                    </CompanyStory>
                    <hr/>
                    <CompanyStory showAppointmentButton={false} title={`${pageTitle2}`}>
                        {companyStory2}
                    </CompanyStory>
                </div>
            </div>
        </>
    );
};

export function DePraktijk_Volwassenen () {
    const headerTitle = `Volwassenen`;
    const pageTitle = `${values.titleSubject} - ${headerTitle}`;
    
    var companyStory = <>
    <div>
        <PhotoAndText image={`/images/layout/filler/stel.jpg`} imageLeft={false}>
            <List 
            title={"Ontdek de werking van klassieke homeopathie bij uiteenlopende klachten bij volwassenen:"} 
            items={[
                "Acute en chronische ontstekingen"
                ,"Luchtwegklachten"
                ,"Huidklachten"
                ,"Maag/darmklachten"
                ,"Cystitis"
                ,"Gal/nierstenen"
                ,"Menstruatieproblemen"
                ,"Migraine"
                ,"Vaccinatieschade (mRNA)"
                ,"Slaapproblemen/Apneu"
                ,"Hormonale klachten"
                ,"Subfertiliteit"
                ,"Geslachtsziekten"
                ,"Depressie"
                ,"Angsten"
                ,"Gebitsproblemen"
                ,"Burn-out"
                ,"Eetproblemen / anorexia / boulimia"
                ,"Tics / gilles de la tourette"
                ,"Dwangstoornis"
                ,"of welke andere klacht op fysiek, emotioneel en mentaal vlak."
            ]}
            />
        </PhotoAndText>  
    
        <br/>  

        <PhotoAndText image={`/images/layout/filler/ouder-stel2.jpg`} imageLeft={true}>
            <List 
            title={"Maar ook ter behandeling/ondersteuning van diepere aandoeningen zoals:"} 
            items={[
                "Kanker"
                ,"Poliepen/Cystes/Fibromen"
                ,"MS"
                ,"ALS"
                ,"Epilepsie"
                ,"Ménière/Tinnitus"
                ,"Oogaandoeningen"
                ,"Colitis ulcerosa/Crohn"
                ,"Lichen sclerose"
                ,"Diabetes"
                ,"Schildklierklachten"
                ,"Hart en vaatziekten"
                ,"Reuma"
                ,"Fibromyalgie"
                ,"CVS"
                ,"Candida"
                ,"Pfeiffer"
                ,"Lyme"
                ,"Etc................."
            ]}
            />
        </PhotoAndText>   

        <P> 
        Het is mogelijk kankerpatiënten, die regulier worden behandeld,
homeopathisch te ondersteunen. Zo zijn negatieve bestralingseffecten, gevolgen van
ingrijpende operaties en bijwerkingen van chemotherapie op te vangen. Mede samen
met orthomoleculaire ondersteuning en de juiste voeding kan de patiënt langer in
goede conditie worden gehouden.
</P>

    </div>
</>;

    return (
        <>
            <div className="page">
                <HeaderSection_ThisPage title={headerTitle} />

                <div className="page-content">
                    <CompanyStory title={`${pageTitle}`} showAppointmentButton={false}>
                        {companyStory}
                    </CompanyStory>
                </div>
            </div>
        </>
    );
};

export function DePraktijk () {
    const headerTitle = `Inleiding`;
    const pageTitle = `${values.titleSubject} - ${headerTitle}`;
    
    var companyStory = <>
    <div>
<P>
        Regulier gezien spreekt men van ziekte als er iets niet goed in een lichaam functioneert, het
        is dus een soort diagnostisch etiket. Men stelt dat je ziek bent omdat je b.v. diabetes hebt.
        De eilandjes van Langerhans werken niet goed en regulier geeft de arts dan een
        geneesmiddel die deze eilandjes stimuleren en/of het tekort aanvult.
        Negeert men het signaal van de verstoring of onderdrukt/stilt men deze door chemische
        medicijnen of een operatie dan raakt men steeds meer uit balans en wordt de verstoring
        steeds dieper in het organisme weggedrukt en wordt het steeds moeilijker om het
        probleem/de oorzaak te herkennen en te accepteren. Men ontneemt zichzelf de
        mogelijkheid tot groei en bewustwording, tot heling.
        Natuurlijk is behandeling met chemische medicijnen en/of een operatie in zeer
        acute gevallen noodzakelijk en soms zelfs levensreddend, maar het haalt op langere
        termijn <b>nooit</b> de <b>oorzaak</b> weg.
</P>    

    </div>
</>;

    return (
        <>
            <div className="page">
                <HeaderSection_ThisPage title={headerTitle} />

                <div className="page-content">
                    <CompanyStory title={`${pageTitle}`} showAppointmentButton={false}>
                        {companyStory}
                    </CompanyStory>
                </div>
            </div>
        </>
    );
};