import './config';

let entities = {};
export default entities;
export {default as entities} from 'entities.js';

entities.employees = {};
entities.companies = {};

const basicText = "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.";

// --- Entity Classes ---

const placeholderImage = '/images/placeholder/placeholder.jpg';

export class Entity {
	constructor(props) {
		this.active = props.active || true;
		this.name = props.name;
		this.role = props.role;
		this.logo = props.logo || new ImageSwitch(global.config.placeholder.image);// can also be a profile picture
		this.emails = props.emails;
		this.phoneNumbers = props.phoneNumbers;

		return {
			active: this.active
			,name: this.name
			,role: this.role
			,logo: this.logo
			,emails: this.emails
			,phoneNumbers: this.phoneNumbers
		};
	}
};

export class Company extends Entity{
	constructor(props) {
		super(props);
		this.kvk = props.kvk;
		this.btw = props.btw;

		// - Projects
		this.projects = [];
	

		this.GetProjectAmount = function() {
			return this.projects.length;
		}

		this.AddProject = function(_project, _owner = new InfoList()) {
			// console.log(_owner);
			if (!_owner.dataObjects) _project.owner = new InfoList(this);// If no owner is given. Set the default to this
			
			this.projects.push(_project);
		}

		// this.AddProjects = function(_projects = [], _owner = undefined) {
		// 	_projects.forEach(item => {
		// 		if (!_owner) item.owner = this;
		// 	});

		// 	this.projects.push(_projects);
		// }

		this.RemoveProject = function(_project) {
			this.projects.push(_project);
		}

		this.GetProjects = function() {
			return this.projects;
		}

		this.GetProjectByIndex = function(_index) {
			return this.projects[_index];
		}

		this.GetProjectByName = function(_name) {
			let _return = undefined;

			this.projects.forEach(item => {
				if (item.name) {
					_return = item;
					return false;
				}
			});

			return _return;
		}
	}
}

// --- Projects ---

export class Project {
	constructor(props) {
		this.owner = props.owner;
		this.name = props.name || "Example Project";
		this.description = props.description || (basicText + basicText + basicText);
		this.date = props.date;
	}
}

// --- Utilities ---AddProject
export class InfoList {
	constructor(..._dataObjects) {
		this.dataObjects = undefined;

		if (Array.isArray(_dataObjects)) {
			this.dataObjects = _dataObjects;
			
			// If there is only one instance
			if (_dataObjects.length == 1) {
				// this.dataObjects = [];
				this.dataObjects = _dataObjects[0];
			}
		}		

		return this.dataObjects;
	}
}

// --- Companies ---
let _company;

entities.companies.ExampleCompany = new Company({
	name: 'Example Company'
	,logo: ImageSwitch('logo/placeholder_logo2.png', 'logo/placeholder_logo2_white.png')
	,emails: new InfoList('email@example.nl', 'email2@example.nl')
	,phoneNumbers: new InfoList('(+31) 55 555 555', '(+31) 55 555 556')
});
_company = entities.companies.ExampleCompany;
// Test
_company.AddProject(new Project({
	name: "Test Project"
}))
_company.AddProject(new Project({
	name: "Test Project (Collaboration)"
	,description: "Test description collab"
}))


// --- Employees ---
// let a = entities.employees.peterSchreuder.logo(1);
// console.log(a);

// !!! This is only meant for the employees complete name and picture. No further personal info.  
// !!! Contact info is direct to that person inside the company (e.g. Phone number / company email)

const _tel = '(+31) 228 52 66 21';

entities.employees.exampleEmployee1 = new Entity({
	name: 'Example Employee'
	,role: "Planner"
	,logo: ImageSwitch(placeholderImage)
	,emails: new InfoList('email.example@example.com')
	,phoneNumbers: new InfoList(_tel, _tel + "1")
});

entities.employees.exampleEmployee2 = new Entity({
	name: 'Example Employee2'
	,role: "Programmeur"
	,logo: ImageSwitch(placeholderImage)
	,emails: new InfoList('peter.example@example.com')
	,phoneNumbers: new InfoList('(+31) 228 52 66 21')
});

// Add employees to the contact page list
entities.employees.contactPage = [];
entities.employees.contactPage.push(entities.employees.exampleEmployee1);
entities.employees.contactPage.push(entities.employees.exampleEmployee2);

// --- Functions & Data ---

// export function InfoList(...dataObjects) {
// 	let data = dataObjects;
	
// 	return data;
// };

export function ImageSwitch(...dataArray) {
	return function(_value) {
		return _value ? dataArray[_value] : dataArray[0];
	};
}


