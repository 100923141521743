import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { NavbarLogo } from './Navbar';
import { Button } from '../interacion/button/Button';
import OutsideClickHandler from 'react-outside-click-handler';

export default function NavbarButtons(props) {

    let _logo = <NavbarLogo 
            closeMobileMenu={props.closeMobileMenu}
            closeFoldMenu={props.closeFoldMenu}
            />;

return (
<div className='nav-menu-wrapper'>
    {/* Mobile Menu Hamburger */}
    {props.showMobileMenuButton ? <div className='menu-icon' onClick={props.handleClick}>
        {/* Switch the icon from 'open' to 'close' relative to the 'click' variable */}
        <i className={props.click ? 'fas fa-times' : 'fas fa-bars'} />
    </div> : ''}

    <div>{/* Switch to 'open' or 'close' when accessing an other page */}
    
    <ul className={props.click ? 'nav-menu active' : 'nav-menu'}>
        {/* 
            Be sure to add the right paths to the Router in App.js
        */}
        
        <MenuItem name="Home" location="" handleClick={props.handleClick} />
        
        <MenuItem name="Even Voorstellen" location="about" handleClick={props.handleClick} />
        
        <MenuItemFold index={0} name={"Klassieke Homeopathie"} baseLocation={'klassieke-homeopathie'} click={props.click} closeFoldMenu={props.closeFoldMenu} handleClick={props.handleClick}>
            <MenuItem name={"Inleiding"} location={"klassieke-homeopathie-inleiding"} handleClick={props.handleClick} className={'nav-item-foldable-item'}/>
            <MenuItem name={"Ziekteclassificatie"} location={"klassieke-homeopathie-ziekteclassificatie"} handleClick={props.handleClick} className={'nav-item-foldable-item'}/>
            <MenuItem name={"De Banerji Protocollen"} location={"klassieke-homeopathie-de-banerji-protocollen"} handleClick={props.handleClick} className={'nav-item-foldable-item'}/>
            <MenuItem name={"CEASE"} location={"klassieke-homeopathie-cease"} handleClick={props.handleClick} className={'nav-item-foldable-item'}/>
        </MenuItemFold>

        <MenuItemFold index={1} name={"De Praktijk"} baseLocation={'de-praktijk'} click={props.click} closeFoldMenu={props.closeFoldMenu} handleClick={props.handleClick}>
            <MenuItem name={"Inleiding"} location="de-praktijk-inleiding" handleClick={props.handleClick} className={'nav-item-foldable-item'}/>
            <MenuItem name={"Verloskunde"} location="de-praktijk-verloskunde-en-homeopathie" handleClick={props.handleClick} className={'nav-item-foldable-item'}/>
            <MenuItem name={"Kinderen / Pubers"} location="de-praktijk-kinderen-pubers-en-homeopathie" handleClick={props.handleClick} className={'nav-item-foldable-item'}/>
            <MenuItem name={"Volwassenen"} location="de-praktijk-homeopathie-bij-volwassenen" handleClick={props.handleClick} className={'nav-item-foldable-item'}/>
        </MenuItemFold>
        
        {/* Only show this logo in bigger than mobile size */}
        {props.showMobileMenuButton ? '' : _logo}

        <MenuItemFold index={2} name={"Vaccinaties"} baseLocation={'vaccinaties'} click={props.click} closeFoldMenu={props.closeFoldMenu} handleClick={props.handleClick}>
            <MenuItem name={"Inleiding"} location="vaccinaties-inleiding" handleClick={props.handleClick} className={'nav-item-foldable-item'}/>
            <MenuItem name={"Inhoudsstoffen"} location="vaccinaties-inhoudsstoffen" handleClick={props.handleClick} className={'nav-item-foldable-item'}/>
            <MenuItem name={"Bijwerkingen"} location="vaccinaties-bijwerkingen" handleClick={props.handleClick} className={'nav-item-foldable-item'}/>
            <MenuItem name={"Keuzemogelijkheden"} location="vaccinaties-keuzemogelijkheden" handleClick={props.handleClick} className={'nav-item-foldable-item'}/>
            <MenuItem name={"Virussen"} location="vaccinaties-virussen" handleClick={props.handleClick} className={'nav-item-foldable-item'}/>
            <MenuItem name={"mRNA"} location="vaccinaties-mrna" handleClick={props.handleClick} className={'nav-item-foldable-item'}/>
        </MenuItemFold>

        <MenuItemFold index={3} name={"Toelichting"} baseLocation={'toelichting'} click={props.click} closeFoldMenu={props.closeFoldMenu} handleClick={props.handleClick}>
            <MenuItem name={"Hahnemann"} location="toelichting-hahnemann" handleClick={props.handleClick} className={'nav-item-foldable-item'}/>
            <MenuItem name={"Miasmaleer / Epigenetica"} location="toelichting-miasmaleer-epigenetica" handleClick={props.handleClick} className={'nav-item-foldable-item'}/>
            <MenuItem name={"Infectieziekten / Reckeweg"} location="toelichting-infectieziekten-reckeweg" handleClick={props.handleClick} className={'nav-item-foldable-item'}/>
            <MenuItem name={"Filosofie"} location="toelichting-filosofie" handleClick={props.handleClick} className={'nav-item-foldable-item'}/>
        </MenuItemFold>
        
        {/* <MenuItem unavailable={false} name="Toelichting" location="toelichting" handleClick={props.handleClick} /> */}
    </ul>
    </div>
</div>
);
}

export function NavbarButtonsSecondary(props) {

    return (
    <div className='nav-menu-secondary-wrapper'>

        <div>{/* Switch to 'open' or 'close' when accessing an other page */}
        
        <ul style={{justifySelf: 'start'}} className={props.click ? 'nav-menu-secondary active' : 'nav-menu-secondary'}>
            
            <MenuItemSmall name="Tarieven" location="tarieven" handleClick={props.handleClick} />

            <MenuItemSmall name="Contact" location="contact" handleClick={props.handleClick} />
        </ul>
        </div>
    </div>
    );
}


export function MenuItem(props) {
	let _active = false;
    const _className = props.className == undefined ? '' : props.className;

	const baseLocation = `${window.baseLocation}`;
	
	const _location = `${props.location}`;
	// Check current path and set to active when true
    const _locationPath = window.publicURL + window.location.pathname;
	const _currentPath = (_locationPath ?  _locationPath : "");
    
	if (`${window.publicURL}/${_location}` == _currentPath) {
		_active = true;
	}

    const onClick = (props.unavailable ? () => {} : () => {props.handleClick({click: true, path: props.location})});

    return (
          <li className={`${props.unavailable ? 'unavailable2' : ''} ${props.smallButton ? 'nav-item nav-item-small' : 'nav-item'} ${_className}`}>
              <Link title={props.unavailable ? 'Pagina is onder constructie' : ''} to={_location} className={`font-light nav-links ${_active == true ? 'nav-links-active' : ''}`} onClick={() => {onClick(); if (props.onMyClick) props.onMyClick()}}>
                    {props.name}
              </Link>
          </li>
    );
  }

export function MenuItemSmall(props) {
    const _props = {...props};
    _props.smallButton = true;

    return MenuItem(_props);
}

export function MenuItemFold(props) {
    const [menuOpen, setMenuOpen] = useState(false);
    const [arrowActive, setArrowActive] = useState(false);

    const _baseLocation = props.baseLocation;// To check for the menu arrow
    const _url = document.location.pathname;

    useEffect(() => {
        if (_baseLocation && _url.includes(_baseLocation)) {
            if (arrowActive != true)
            setArrowActive(true);
        } else {
            if (arrowActive != false)
            setArrowActive(false);
        }
    });

    // TODO: research why the compilers turns the name type of the item to 'h'
    const localIP = '192.168.56.1';
    const menuItemName = (document.URL.includes('localhost') || document.URL.includes(localIP)) ? 'MenuItem' : "g";//MenuItem
    const propsMenuItems = props.children;
    let menuItems = [];

    // Add the "setMenuOpen" to all MenuItem elements
    if (propsMenuItems) {
        let _i = 0;
        propsMenuItems.forEach(item => {
            // Keep this log for checking the type name if the fold menu does not work
            // console.log('name', item, item.type.name);
            if (item.type.name == menuItemName) {
                const _item = React.cloneElement(item, {...item.props, onMyClick: () => {
                    if (menuOpen == true) {
                        setMenuOpen(false);
                    }

                }, key: _i});

                menuItems.push(_item);
                _i++;
            }
        });
    }

    let _active = menuOpen;
    let _activeArrow = arrowActive;
    const _activeClassName = menuOpen ? 'active' : '';
    const _activeClassNameArrow = _activeArrow ? 'active-color' : '';
    
    return (<>
        <OutsideClickHandler onOutsideClick={() => {
            if (menuOpen) {
                
                setMenuOpen(false);
            }
             
            }}>
          <li className={`nav-item nav-item-foldable ${_activeClassName}`}>
            <div className={`nav-links nav-links-foldable font-light ${_activeClassName}`} onClick={() => {setMenuOpen(!menuOpen)}}>
                {props.name}
                <i className={`fas fa-chevron-down foldable-menu-arrow ${_activeClassName} ${_activeClassNameArrow}`}></i>
            </div>

            {/* {_listJSX} */}
            <ul className={`nav-item-foldable-item-list ${_activeClassName}`}>
                {/* {menuOpen ? (props.index == 0 ? _listJSX : _listJSX2) : ''} */}
                {menuOpen ? <>
                {menuItems.map((item) => {
                    return <>{item}</>;
                })}
                </> : ''}
            </ul>
            
          </li>
        </OutsideClickHandler>
    </>);
  }
