import React from 'react'
import CardItem from './CardItem';
import './Cards.css';

var textTitle = 'Werkzaamheden';//Portfolio

var before = 'Custom ';
var after = '';

function TitleText(string) {
  return before + string + after
}

export default function Cards() {
    return (
      <div className='cards'>
        <h1>{textTitle}</h1>
        <div className='cards_container'>
          <div className='cards_wrapper'>
            <div className='cards_items'>
              <CardItem
                key={1}
                title={TitleText('Laswerk')}
                src={`/images/placeholder/placeholder.jpg`}
                label='Product'
                text={window.basicText}
                date='asd'
                path='/factuur'
                background={false}
              />
              <CardItem
              key={2}
                title={TitleText('Motor Onderdelen')}
                src={`/images/placeholder/placeholder.jpg`}
                label='Eigen Projecten'
                text={window.basicText}
                date='Product'
                path='/portfolio'
              />
            </div>
          </div>
        </div>
      </div>
    );
  }

  export function CardsContainer(props) {
    // var a = `${window.publicURL}/images/layout/filler/image2.jpg`
    var a = `${window.publicURL}/images/layout/filler/image2.jpg`;
    var bgr = props.background ? {objectFit: 'cover', backgroundPosition: 'center', backgroundImage: `url(${a})`, borderRadius: '5px'} : {};
    return (
      <>
        <div style={{...bgr}} className="cards">
          <h1>{props.title}</h1>
          <div className="cards_container">
            <div className="cards_wrapper">
              <div className="cards_items_spread">{props.cards}</div>
            </div>
          </div>
        </div>
      </>
    );
  }