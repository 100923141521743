import parse from 'html-react-parser';



export function ArrayToHTMLList(array, className="", style="") {
    array.map((item, index) => {
        // console.log(index);
        array[index] = parse(`<li className=${className} style=${style} key=${index}>${item}</li>`);
    })
    return array
}

