import React from 'react'
import './Button.css';
import {Link} from 'react-router-dom';

// Button styles
const STYLES = ['btn--primary', 'btn--outline'];

// Button sizes
const SIZES = ['btn--medium', 'btn--large'];

// export const Button = ({children, type, onClick, buttonStyle, buttonSize, className}) => {
    
//     // Check if the parameter has been filled in else set it to the default Style.
//     const checkButtonStyle = STYLES.includes(buttonStyle) ? buttonStyle : STYLES[0];

//     // Check if the parameter has been filled in else set it to the default Size.
//     const checkButtonSize = SIZES.includes(buttonSize) ? buttonSize : SIZES[0];

//     return (
//         <Link to='sign-up' className='btn-mobile'>
//             <button
//             className={`btn ${checkButtonStyle} ${checkButtonSize} ${className} un`}
//             onClick={onClick}
//             type={type}
//             >
//                 {children}
//             </button>
//         </Link>
//     )
// }

export function Button(props) {
    //float: "right", width: "100%",
    const _button = <>
    <div style={{fontSize: "larger"}} className={`${props.className} fit-content button round-edge`}>
        <div className='button-text text-white'>{props.children}</div><i className={`fas fa-chevron-right arrow text-white`}></i>
    </div>
    </>;

    return _button;
}

export function ButtonLink(props) {
    const _button = <>
        <a className={'button-link'} title={`${(props.title != undefined) ? props.title : ''}`} href={props.link} target={`${(props.target != undefined) ? props.target : '_self'}`}>
            <Button><p>{props.children}</p></Button>
        </a>
    </>;

    return _button;
}

export function AppointmentButton(props) {
    const _email = global.config.companyInfo.emailMain;

    const _button = <>
    <a className={'fit-content button-link'} title="Dit opent uw mail programma" href={`mailto:${_email}`} target='_self'>
        <Button className={'margin-center upper-case'}><p>Afspraak maken</p></Button>
    </a>
    <h6 className='text-center'>Dit opent uw mail programma</h6>
    </>;

    return _button;
}