import React from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

// to={props.path}
function CardItem(props) {

  var s = props.style;
  
  var _type = props.type ? props.type : '';

  var _background = (props.background == undefined && props.background != false) ? true : false;
  // console.log(_background);

  // if (props.zoom != undefined)
  // {
  //   var zoom = -(props.zoom / 6);
  //     //props.zoom = props.zoom / 100;
  //     s = {width: props.zoom + "%"};//{transform: "scale("+ (props.zoom / 100) + ")"}; , left: zoom + "%"
  //   }

  var _image = <img
              className={`cards_item_img ${_type}`}
              alt={`Image`}
              src={window.publicURL + props.src}
            />;

  return (
    <>
    
      <div key={props.key} className={`cards_item ${_background ? 'blur' : '' } ${_type} ${props.className}`} title="Image" style={props.style}> 
        
        <div className={`cards_item_link ${_background ? 'link_background' : '' }`}>       
          
          <figure className={`cards_item_pic_wrap ${_type}`} data-category={props.label}>
          <div className={`cards_item_label_wrap ${_type}`}>
            {props.label ? <h2 className={`cards_item_label ${_type}`}>{props.label}</h2> : ''}
            </div>
            <div>

            {props.imageLink ? <a href={props.imageLink} target='blank' className='logo'>
              {_image}
            </a> :
            _image
            }
            
            </div>
          </figure>

          {/* Info */}
          <div className={`cards_item_info ${_type}`}>
            <h5 className={`cards_item_title ${_type}`}>{props.title}</h5>
            <p className={`cards_item_text ${_type}`}>{props.text ? parse(props.text) : ""}</p>
            <p className={`cards_item_subText ${_type}`}>{props.subText ? (parse(props.subText)) : ""}</p>
          </div>

        </div>

      </div>
    </>
  );
}

export default CardItem;
