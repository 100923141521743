import config from 'config';
import 'App.css';
// import '../../../../../App.css'
import { AppointmentButton, Button } from 'components/layout/interacion/button/Button';
import './CompanyStory.css';
import parse from 'html-react-parser';
import { Container, ContainerContent, ContainerGroup, ContainerWrapper } from '../containers/Containers';


export default function CompanyStory(props) {
    let _textTitle = props.title ? parse(props.title) : undefined;
    let _childeren = props.children;

    let _titleComponent = <>
    <h1 className='company-info-title'>{_textTitle}</h1>
    <hr className="textUnderLine"></hr>
    </>;

    // If story is used, use that, else check if childeren is used and keep it empty, else use the basicText
    let _story = props.story ? parse(props.story) : (_childeren ? "" : window.basicText);
    
    let _titleSide = props.titleSide ? props.titleSide : undefined;

    // Does the button above the text go under the title or not
    let _scaleToPhone = (props.scaleToPhone == undefined || props.scaleToPhone != false) ? true : false;

    let under = false;
    let _underStyle = under ? {} : {flexDirection: 'row'};
    let _underButtonStyle = under ? 'width100 pwidth50' : '';
    // {`${(_scaleToPhone == undefined || _scaleToPhone != false) ? 'companystory-head-group' : ''}`}
// 
    return (
        <div className='company-info'>
            <div className='company-info-content'>
                
                <div style={{position: 'relative'}} >
                    <img style={{position: 'absolute', zIndex: '-100', opacity: '0.2', marginLeft: '-30px', top: '10px'}} src={window.publicURL + global.config.companyMedia.logo} width="150px"></img>
                </div>
                {/* for side by side, flexDirection: 'row' */}
                {(_textTitle || props.titleSide || (props.showAppointmentButton == undefined || props.showAppointmentButton != false)) ? <>
                    <ContainerGroup className={`${_scaleToPhone ? 'companystory-head-group' : ''}`} style={{justifyContent: 'space-between', ..._underStyle}}>
                    <ContainerWrapper>
                        <Container style={{overflow: 'visible'}} background={false}>
                            <ContainerContent noPadding={true}>
                                {/* Logo */}
                                
                                {/* Title */}
                                {_textTitle ? _titleComponent : ''}

                            </ContainerContent>
                        </Container>
                    </ContainerWrapper>
                    <ContainerWrapper>
                        <Container style={{overflow: 'visible'}} background={false}>
                            {/* for side by side,   */}
                            <ContainerContent  className={`${(_scaleToPhone) ? 'companystory-head-group' : ''} float-right ${_underButtonStyle}`} noPadding={true} style={{textAlign: 'right'}}>
                                {_titleSide ? _titleSide : ''}
                                {(props.showAppointmentButton == undefined || props.showAppointmentButton != false) ? <AppointmentButton /> : ''}
                                {_scaleToPhone ? <br/> : ''}
                            </ContainerContent>
                        </Container>
                    </ContainerWrapper>
                    {/* {props.titleSide ? (
                        
                    )
                    : ''} */}
                </ContainerGroup>
                </> : ''}

                    
                <div className='company-info-story font-p'>
                    
                    {/* <p>{companyName} {companyStory}</p> */}
                    {_story}
                    {_childeren ? _childeren : ''}

                </div>

            </div>

            {(props.showAppointmentButtonBottom == undefined || props.showAppointmentButtonBottom != false) ? <>
                <div className='fit-content margin-center'>
                    <AppointmentButton />
                    <br/>
                </div>
            </> : ''}
        </div>
    )
}
