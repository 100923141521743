import Navbar from 'components/layout/navbar/Navbar';
import {BrowserRouter as Router, HashRouter, Switch, Route} from 'react-router-dom';
import './App.css';
import './styling/Text.css';
import './styling/khh.css';
import './styling/Layout.css';
import './styling/Animations.css';
import './components/layout/containers/containers.css';
import './components/layout/grids/Grids.css';
// import './components/tools/Reset.css'

import Home from 'components/pages/Home';

import Footer from 'components/layout/footer/Footer';
import React from 'react';
import About from 'components/pages/About';

import { DePraktijk_Inleiding, DePraktijk_KinderenPubers, DePraktijk_Verloskunde, DePraktijk_Volwassenen } from 'components/pages/DePraktijk';
import { KlassiekeHomeopathie_CEASE, KlassiekeHomeopathie_De_Banerji_Protocollen, KlassiekeHomeopathie_Inleiding, KlassiekeHomeopathie_Ziekteclassificatie } from 'components/pages/KlassiekeHomeopathie';
import NotFound from 'components/pages/NotFound';
import { Contact, Privacy, Tarieven } from 'components/pages/Overige';
import { Vaccinaties_Bijwerkingen, Vaccinaties_Inhoudsstoffen, Vaccinaties_Inleiding, Vaccinaties_Keuzemogelijkheden, Vaccinaties_Virussen, Vaccinaties_mRNA } from 'components/pages/Vaccinaties';
import { ToelichtingPage_Hahnemann, ToelichtingPage_MiasmaleerEpigenetica as ToelichtingPage_MiasmaleerEpigenetica, ToelichtingPage_InfectieziektenReckeweg as ToelichtingPage_InfectieziektenReckeweg, ToelichtingPage_Filosofie as ToelichtingPage_Filosofie } from 'components/pages/ToelichtingPage';

window.basicText = "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.";

window.maxWidth = 1220;

class App extends React.Component {
	constructor() {
		super();
		// Set document variables
		document.title = global.config.companyInfo.name;
	
		window.baseLocation = document.location.pathname;
		
		window.publicURL = process.env.PUBLIC_URL;

		window.phoneWidth = 850;

		window.isPhoneSize = (window.innerWidth <= window.phoneWidth ? true : false);
		window.addEventListener("resize", () => {
			window.isPhoneSize = (window.innerWidth <= window.phoneWidth ? true : false);
		});
	}

	
    render() {
		const _pathName = `${window.location.origin}`;
		
		return (<>
			{/* <HashRouter basename={process.env.PUBLIC_URL}> */}
			<Router>
				<Navbar />
				<Switch>
					{/* 
						Be sure to add the right buttons to the navbar in NavbarButtons.js
					*/}

					<Route path={`/`} exact component={Home} />
					<Route path={`/about`} component={About} />

					<Route path={`/501`} component={NotFound} />
					<Route path={`/404`} component={NotFound} />
					{/* <Route path='*' element={NotFound}/> */}

					{/* Klassieke-Homeopathie */}
					<Route path={`/klassieke-homeopathie-inleiding`} component={KlassiekeHomeopathie_Inleiding} />
					<Route path={`/klassieke-homeopathie-ziekteclassificatie`} component={KlassiekeHomeopathie_Ziekteclassificatie} />
					<Route path={`/klassieke-homeopathie-de-banerji-protocollen`} component={KlassiekeHomeopathie_De_Banerji_Protocollen} />
					<Route path={`/klassieke-homeopathie-cease`} component={KlassiekeHomeopathie_CEASE} />

					{/* De Praktijk */}
					<Route path={`/de-praktijk-inleiding`} component={DePraktijk_Inleiding} />
					<Route path={`/de-praktijk-verloskunde-en-homeopathie`} component={DePraktijk_Verloskunde} />
					<Route path={`/de-praktijk-kinderen-pubers-en-homeopathie`} component={DePraktijk_KinderenPubers} />
					<Route path={`/de-praktijk-homeopathie-bij-volwassenen`} component={DePraktijk_Volwassenen} />
					
					{/* Vaccinaties */}
					<Route path={`/vaccinaties-inleiding`} component={Vaccinaties_Inleiding} />
					<Route path={`/vaccinaties-inhoudsstoffen`} component={Vaccinaties_Inhoudsstoffen} />
					<Route path={`/vaccinaties-bijwerkingen`} component={Vaccinaties_Bijwerkingen} />
					<Route path={`/vaccinaties-keuzemogelijkheden`} component={Vaccinaties_Keuzemogelijkheden} />
					<Route path={`/vaccinaties-virussen`} component={Vaccinaties_Virussen} />
					<Route path={`/vaccinaties-mrna`} component={Vaccinaties_mRNA} />
					
					<Route path={`/toelichting-hahnemann`} component={ToelichtingPage_Hahnemann} />
					<Route path={`/toelichting-miasmaleer-epigenetica`} component={ToelichtingPage_MiasmaleerEpigenetica} />
					<Route path={`/toelichting-infectieziekten-reckeweg`} component={ToelichtingPage_InfectieziektenReckeweg} />
					<Route path={`/toelichting-filosofie`} component={ToelichtingPage_Filosofie} />
					
					{/* Overige */}
					<Route path={`/tarieven`} component={Tarieven} /> 
					<Route path={`/contact`} component={Contact} />
					<Route path={`/privacy`} component={Privacy} />

					<Route path='*' component={NotFound}/>
					
				</Switch>
				<Footer />

			</Router>
			{/* </HashRouter> */}
			</>);
			
	}
}

export default App;