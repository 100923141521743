import React from 'react';
import HeaderSection, { HeaderSectionHome, HeaderSection_Page } from '../layout/header/HeaderSection';
import CompanyStory from '../layout/text/CompanyStory';
import Cards from '../layout/cards/Cards';
import CardsHorizontal from '../layout/cards/CardsHorizontal';
import { Slideshow, importAll, importAllArray } from 'images';
import jsxToString from 'jsx-to-string';


import {entities} from 'entities.js';
import Gallery from 'components/layout/media/Gallery';
import { Container, ContainerContent, ContainerWrapper, ContainerGroup } from 'components/layout/containers/Containers';
import { ArrayToHTMLList } from 'components/tools/arrays';
import { List, PhotoAndText, Quote, WebsiteLink } from 'components/layout/text/TextComponents';
import { P, QuoteByPerson } from 'styling/TextTypes';
import { ButtonLink } from 'components/layout/interacion/button/Button';

// "Klassieke Homeopathie volgens de Ziekte-classificatie RH"
//         ,"CEASE-therapie"
//         ,"Banerji protocollen"

// const _left = <>Als er een einde komt aan de oorzaak, houdt ook het gevolg op</>;
const _left = <>her-inner wonderkracht en licht</>;
const _right = <>heling begint met een ander zicht</>;

const values = { 
    titleSubject: `Klassieke<wbr> Homeopathie<wbr>`
    // ,quote: <div style={{width: '100%', paddingRight: '10vw'}}>{_left}<div style={{float: 'right'}}>{_right}</div></div>
    ,image: `${process.env.PUBLIC_URL}/images/layout/klassieke-homeopathie/butterfly.jpg`
}


let _a = <>Ik ben dankbaar om te mogen bijdragen aan de gezondheid van de mens en het gestelde vertrouwen in mij.</>;

function HeaderSection_ThisPage(props) {
    let _return = <></>;

	_return = <HeaderSection_Page 
    title={props.title} 
    titleBackground={values.titleSubject} 
    quote={values.quote || _a} 
    image={props.image || values.image}
    />

	return _return;
}


var companyStory = <>
<div>

    <br/>

    <PhotoAndText image={`${global.config.companyInfo.ownerImage}`} round={true} width={'50'} imageLeft={true}
    afterImage={<>
    <Quote>Ik miste de innerlijke hoofdzakelijke zorg, dat kon beter!<br/>
Door een aangrijpende gebeurtenis kwam ik uiteindelijk terecht bij de homeopathie.</Quote>
    </>}
    >
<P>
Omdat ik van jongs af aan geïnteresseerd ben in de betekenis van ziekte en gezondheid was het logisch dat ik in de zorg kwam te werken. 
<br/>
Na de toenmalige <b>mbo-opleiding Kind en Jeugdverzorging</b>, haalde ik mijn diploma in de zorg voor mensen met een verstandelijke en lichamelijke beperking, voorheen de <b>Z-opleiding</b> waar ik 10 jaar met liefde en dankbaarheid, met name kinderen, heb mogen verzorgen en begeleiden. 
<br/>
</P>
<P>
Ik begon vol goede moed aan de opleiding <b>hbo-verpleegkundige</b> die ik niet heb afgerond. Er begon iets te knagen, ik miste de innerlijke hoofdzakelijke zorg, dat kon beter en zo belandde ik bij de 2-jarige post <b>hbo-opleiding speltherapeute</b> die wel werd afgerond met een diploma.  
</P>


    </PhotoAndText> 
    <br/>

<P>
In de tussentijd werd ik moeder van 2 prachtige zonen. Naast liefde en gezonde voeding was een geneeswijze die niet belastend zou zijn een prioriteit om ze te laten opgroeien tot gezonde volwassenen. Door een aangrijpende gebeurtenis kwam ik uiteindelijk terecht bij de <b>Homeopathie</b> en ging ik mij verder verdiepen in het wel of niet vaccineren. 
Intussen ben ik ook oma geworden van 3 kleinkinderen. Het is nog steeds een wonder om te ervaren hoe positief iedereen reageert op homeopathie als deze ingezet wordt. 
</P>
<P>
In 2000 heb ik de 6-jarige opleiding <b>natuurgeneeskunde</b> en <b>klassieke homeopathie</b> aan de academie in Bloemendaal met goed resultaat afgerond. Facultatief volgde ik <b>voedingsleer</b>, <b>fytotherapie</b>, <b>iriscopie en massage</b>. In de laatste 2 praktiseer ik niet meer. 
    <br/>
    Ook tijdens deze opleiding en in de eerste jaren van mijn praktijk miste ik iets, te veel vragen die onbeantwoord bleven. 
    Deze hiaten werden opgevuld tijdens mijn verdieping binnen de <b>ziekteclassificatie</b>, Homeopathie zoals Hahnemann het bedoeld heeft in zijn 6<sup>de</sup> editie van de Organon. 
    Een fundamentele totale behandeling kon vanaf toen ingezet worden en waar ik tot op de dag van vandaag nog steeds met dankbaarheid en liefde achter sta. 
</P>

<P>
Naast mijn praktijkwerkzaamheden heb ik 10 jaar telefonische vragen en mails beantwoord voor de <b>NVKP</b> (Nederlandse Vereniging Kritisch Prikken). Hier deed ik veel ervaring op in het begeleiden van ouders in het soms moeilijke vraagstuk van vaccineren. 
Ik geef uitgebreid voorlichting en begeleiding bij het wel of niet vaccineren en 
behandel klachten die eventueel zijn ontstaan na vaccineren.	  
    <br/>
    Chronische ziekten namen steeds meer toe, zodoende ben ik mij verder gaan specialiseren in:
</P>


    <List 
    title={``} 
    bulletPoints={false}
    items={[
        <><a href={'/klassieke-homeopathie-inleiding'}>Klassieke Homeopathie volgens de Ziekte-classificatie RH</a><br/><br/></>
        ,<><a href={'/klassieke-homeopathie-cease'}>CEASE-therapie</a><br/><br/></>
        ,<><a href={'/klassieke-homeopathie-de-banerji-protocollen'}>Banerji protocollen</a><br/><br/></>
    ]}
    />

    {/* <br/> */}

    {/* <hr/> */}
    {/* <br/> */}

    {/* <h2>Verbroken Stilte</h2> */}
<P>
        <Quote>
        Hoe vaak heb ik horen zeggen "De stilte valt niet te doorbreken bij een kind met autisme."
        </Quote>
        {/* <Quote>
        Hoe vaak heb ik horen zeggen “Met een kind met autisme valt niets te beginnen”.
        </Quote> */}
    
Ik was 18 jaar jong toen ik het boek ‘Verbroken stilte’ van Barry Neil Kaufman in handen
kreeg wat mij intens raakte en niet meer losliet. Een persoonlijk waargebeurd verhaal over
een kind met autisme die bewijst dat de stilte van een kind met autisme te doorbreken valt.
In het begin van mijn praktijk kwam ik via een ouder van een kind met ASS
in aanraking met <b>C</b>ontact <b>G</b>ericht <b>S</b>pelen en <b>L</b>eren. Deze methode is ontwikkeld door Barry
Neil Kaufmann en laat dat de schrijver zijn van het boek, wat destijds zo een indruk op mij
had gemaakt. Dit kon geen toeval zijn en dus reden om mezelf te gaan trainen in de <b>CSL</b> en
de <b>ABA</b>- methode. Vanaf dat moment heb ik 10 jaar mijn homeopathiepraktijk
gecombineerd met het begeleiden van kinderen met ASS binnen de speelkamer en ter
ondersteuning thuis in het gezin.
<br/><br/>
De methode <b>‘ik leer anders’</b> maakte mij tot coach om beelddenkers, kinderen met dyslexie,
dyscalculie, korte concentratie en/of faalangst, een vertaalslag te laten maken binnen het
onderwijs waarin ze vastlopen.
</P>

<P>
Alle kennis en ervaring komt bij elkaar in het totaalpakket dat ik als behandelaar aanbied
waarin homeopathie de boventoon voert. De ervaring leert dat dit tot snellere resultaten
kan leiden.
De praktijk is gedurende de 23 jaar op verschillende plekken gevestigd geweest, namelijk
Bakkeveen, Haren, Groningen en Hoorn. Dit heeft een talrijk ver strekkende klantenkring
opgeleverd die mij telefonisch of via beeldbellen blijven consulteren.
De praktijk is nu terug op het drie provincie punt Groningen/Drenthe/Friesland te weten op de <b>Allardsoogsterweg 6 te Zevenhuizen</b>.
<br/><br/>
Ik werk samen met andere disciplines en eens in de 2 maanden vind er intercollegiaal
overleg plaats. Bovendien volg ik elk jaar minstens <b>zes nascholingsdagen</b> op medisch en
homeopathisch gebied.
</P>


<P>
    Het leren en ontwikkelen gaat door…………………….
</P>
    
    </div>
</>;
    
    // companyStory = jsxToString(companyStory, {detectFunctions: true});
const title = undefined;//`Wie is Anita Schuit`;

export default function About () {
	let _video = `${process.env.PUBLIC_URL}/videos/video-1.mp4`;
	let _image = `${process.env.PUBLIC_URL}/images/layout/klassieke-homeopathie/dragonfly.jpeg`;

	const _imageArray = importAllArray(
		require.context('../../../public/images/layout/home', true, /^(?!.*\/not-used\/).*\/.*.\.(JPG|png|jpe?g|svg)$/)
	);

    let _montagetechnieken = [
        'Mechanisch',
        'Pneumatisch'
    ];
    _montagetechnieken = ArrayToHTMLList(_montagetechnieken, 'list-item');

    let _disciplines = [
        'Reinigen (o.a. ultrasoon)',
        'Testen/valideren',
        'Geconditioneerde meetruimte'
    ];
    _disciplines = ArrayToHTMLList(_disciplines, 'list-item');

    return (
        <>
            <div className="page">
                {/* <HeaderSection video={'/videos/video-1.mp4'} showInfo={true} logo={true} /> */}
                {/* <HeaderSection video={_media} showInfo={true} logo={true} /> */}
                
                {/* <HeaderSectionHome title={'Even Voorstellen'} /> */}

                <HeaderSection_ThisPage title={undefined} />

                {/* <HeaderSection title={`Even Voorstellen`} mediaHeight={'350px'} image={_image} showInfo={false} logo={false} /> */}
                {/* <HeaderSection title={`${title}`} mediaHeight={'450px'} imageArray={_imageArray} showInfo={false} logo={false} /> */}

                <div className="page-content">
                    <CompanyStory title={title} showAppointmentButton={false}>
                        {companyStory}
                    </CompanyStory>
                </div>
            </div>
        </>
    );
};