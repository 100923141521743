import config from 'config';
import 'App.css';
// import '../../../../../App.css'
import { Button } from 'components/layout/interacion/button/Button';
import './CompanyStory.css';
import parse from 'html-react-parser';
import { Container, ContainerContent, ContainerGroup, ContainerWrapper } from '../containers/Containers';
import { ShowEmail, ShowLocation, ShowPhone } from '../pages/home/company_info/Info';
import { ArrayToHTMLList } from 'components/tools/arrays';
import ContactForm from '../interacion/forms/ContactForm';
import { P } from 'styling/TextTypes';
import { CardsContainer } from '../cards/Cards';
import { _cardsErkenningen } from 'components/pages/Home';


export default function ContactContainer(props) {
    // let _textTitle = parse(props.title);
    // let _story = props.story ? parse(props.story) : window.basicText;
    // let _titleSide = props.titleSide ? parse(props.titleSide) : '';

    function LList(props) {
        return (<li className='font-p'><span className='font-p'><b className='bold'>{props.name}</b> {props.value}</span></li>)
    }

    const _place = 'Anita Schuit Klassieke Homeopathie en Speltherapie';
    const googleMaps = `<div class="mapouter"><div class="gmap_canvas"><iframe class="gmap_iframe" width="100%" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" src="https://maps.google.com/maps?width=600&amp;height=300&amp;hl=en&amp;q=${_place}&amp;t=&amp;z=8&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"></iframe><a href="https://formatjson.org/word-counter">Word Counter</a></div><style>.mapouter{position:relative;text-align:right;width:100%;height:300px;}.gmap_canvas {overflow:hidden;background:none!important;width:100%;height:300px;}.gmap_iframe {height:300px!important;}</style></div>`;

    return (
        <div className='contact-container'>
            <div className='contact-container-content'>
                
                <ContainerGroup style={{justifyContent: 'space-between', flexDirection: 'row'}}>
                    <ContainerWrapper>
                        <Container style={{overflow: 'visible'}} background={false}>
                            <ContainerContent noPadding={true}>
                                <h1 className='company-info-title-big'>Contact</h1>
                            </ContainerContent>
                        </Container>
                    </ContainerWrapper>
                    {/* <ContainerWrapper>
                        <Container style={{overflow: 'visible'}} background={false}>
                            <ContainerContent noPadding={true} style={{textAlign: 'right'}}>
                                asdf
                            </ContainerContent>
                        </Container>
                    </ContainerWrapper> */}
                    {/* {props.titleSide ? (
                        
                    )
                    : ''} */}
                </ContainerGroup>

                    
                <hr className="textUnderLine"></hr>

                <div className='contact-container-story'>
                    

                    {/* <h3>Contact Form</h3> */}

                    <ContainerGroup style={{justifyContent: 'space-between', alignItems: 'stretch'}}>
                        <ContainerWrapper className={'container-wrapper-grow'}>
                            <Container style={{overflow: 'visible', alignItems: 'stretch'}} background={true}>
                                <ContainerContent>
                                    <h2>Contact Gegevens</h2>
                                    {/* <br/> */}

                                    <ul style={{lineHeight: 2, padding: '15px', listStyleType: 'none'}}>
                                        <li><ShowPhone phone={global.config.companyInfo.phoneNumberMain} /></li>
                                        <li><ShowEmail email={global.config.companyInfo.emailMain} /></li>
                                        <li><ShowLocation url='https://www.google.nl/maps/place/Allardsoogsterweg+6,+9354+VR+Zevenhuizen/@53.0964943,6.3138738,17z/data=!3m1!4b1!4m6!3m5!1s0x47c849d47e2bf02f:0x2610406a0bbf2d11!8m2!3d53.0964911!4d6.3164487!16s%2Fg%2F11ffvrqcvz?entry=ttu' location={global.config.companyInfo.address.whole + ', ' + global.config.companyInfo.country} /></li>
                                    </ul>

                                    {/* Route button */}
                                    <a target='blank' href={`https://www.google.nl/maps/dir//Allardsoogsterweg+6,+9354+VR+Zevenhuizen/@53.0964911,6.3164487,17z/data=!4m9!4m8!1m0!1m5!1m1!1s0x47c849d47e2bf02f:0x2610406a0bbf2d11!2m2!1d6.3164487!2d53.0964911!3e0?entry=ttu`} target="_blank">
                                        <div style={{float: "left"}} className='button'>
                                            Route beschrijving
                                        </div>
                                    </a>
                                    <br/>
                                    <br/>
                                    <br/>
                                    <div>
                                        {parse(googleMaps)}
                                    </div>
                                </ContainerContent>
                            </Container>
                        </ContainerWrapper>
                        <ContainerWrapper className={'container-wrapper-grow'}>
                            <Container style={{overflow: 'visible'}} background={true}>
                                <ContainerContent>
                                    <h2>Neem contact op</h2>
                                    <br/>
                                    <ContactForm />

                                </ContainerContent>
                            </Container>
                        </ContainerWrapper>
                        {/* {props.titleSide ? (
                            
                        )
                        : ''} */}
                    </ContainerGroup>

                    <ContainerGroup style={{justifyContent: 'space-between', alignItems: 'stretch'}}>
                        <ContainerWrapper className={'container-wrapper-grow'}>
                            <Container style={{overflow: 'visible', alignItems: 'stretch'}} background={true}>
                                <ContainerContent>
                                    <h2>Overige informatie</h2>        

                                    <ul className='font-p' style={{padding: '15px', listStyleType: 'none'}}>
                                        <LList name='NVKH' value={global.config.companyInfo.nvkh} />
                                        <LList name='RBCZ' value={global.config.companyInfo.rbcz} />
                                        <LList name='AGB' value={global.config.companyInfo.agb} />
                                        <LList name='btw-identificatienummer' value={global.config.companyInfo.btw} />
                                        <LList name='KVK' value={global.config.companyInfo.kvk} />
                                    </ul>
                                    

                                    <div>
                                        <a href='https://homeopathie.nl/' target='blank'>
                                        <img src='/images/company/homeopathie-logo.png' style={{width: "20%"}} className='smallpwidth100 logo'/>

                                        </a>
                                    </div>

                                    {/* <P>
                                        Nederlandse Vereniging Klassiek Homeopaten
                                    </P> */}
                                </ContainerContent>
                            </Container>
                        </ContainerWrapper>
                        {props.klachten == undefined || props.klachten != false ? <ContainerWrapper className={'container-wrapper-grow'}>
                            <Container style={{overflow: 'visible'}} background={true}>
                                <ContainerContent>
                                    <h3>Klachten?</h3>
                                    <br/>
                                    <P>
                                    Als u een klacht heeft, kunt u dit kenbaar maken via de website van de NVKH: <a target='blank' className='font-p' href='https://www.nvkh.nl/over-de-nvkh/'>https://www.nvkh.nl/over-de-nvkh/</a>.
                                    </P>
                                    <br/>

                                    {/* <P>
                                        Nederlandse Vereniging Klassiek Homeopaten
                                    </P> */}

                                </ContainerContent>
                            </Container>
                        </ContainerWrapper> : ''}
                    </ContainerGroup>


                </div>

            </div>            
          
        </div>
    )
}


