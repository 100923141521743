import React from 'react';
import HeaderSection, { HeaderSectionHome } from '../layout/header/HeaderSection';
import CompanyStory from 'components/layout/text/CompanyStory';
import Cards, { CardsContainer } from '../layout/cards/Cards';
import { importAll, importAllArray } from 'images';
import parse from 'html-react-parser';
import jsxToString from 'jsx-to-string';

import {entities} from 'entities.js';
import CardItem from 'components/layout/cards/CardItem';
import { Container, ContainerContent, ContainerWrapper, ContainerGroup } from 'components/layout/containers/Containers';
import ContactContainer from 'components/layout/text/ContactContainer';
import { P, QuoteByPerson } from 'styling/TextTypes';
import { AppointmentButton, ButtonLink } from 'components/layout/interacion/button/Button';
import { WebsiteLink } from 'components/layout/text/TextComponents';

// Recognition Cards
const _style = {paddingTop: '2%', animationName: "from-left, from-transparent", animationDuration: "0.75s"};
export const _cardsErkenningen = [];
    _cardsErkenningen.push(
        <CardItem
		key={1}
			title={'NVKH'}
			src={`/images/recognition/NVKH-logo-groot.png`}
			label={''}
			text={`Nederlandse Vereniging van Klassiek Homeopaten`}
            subText={`De NVKH is een vereniging VAN Klassiek Homeopaten, die haar doelen VOOR de klassiek homeopaat bereikt dankzij actieve inzet DOOR klassiek homeopaten. We zijn samen de NVKH en dragen samen de verantwoordelijkheid voor de inzet en het bereiken van de doelen.`}
			style={{..._style}}
			type='recognition wide'
            background={false}
            imageLink={'https://www.nvkh.nl/'}
		/>
    );
    _cardsErkenningen.push(
        <CardItem
		key={2}
			title={'RBCZ'}
			src={`/images/recognition/RBCZ-logo-groot.png`}
			label={''}
			text={`Register Beroepsbeoefenaren Complementaire Zorg`}
			subText={`De stichting RBCZ is een overkoepelende organisatie die beroepsbeoefenaren van de complementaire en alternatieve geneeswijzen (artsen en therapeuten op Hbo-niveau) certificeert en registreert.`}
			style={{..._style}}
			type='recognition'
            background={false}
            imageLink={'https://rbcz.nu/'}
		/>
    );
    _cardsErkenningen.push(
        <CardItem
		key={4}
			title={'Klassieke Homeopathie'}
			src={`/images/recognition/KH-logo.png`}
			label={''}
			text={`Stichting Hahnemann Homeopathie`}
			subText={`Op deze website, in onze praktijken en door middel van nascholingen, webinars en onze homeopathie opleiding informeren we geïnteresseerden over de mogelijkheden van homeopathie, behandelen patiënten, leiden studenten op en vormen en begeleiden homeopaten.`}
			style={{..._style}}
			type='recognition'
            background={false}
            imageLink={'https://www.klassiekehomeopathie.nl/'}
		/>
    );

    const _text_communication = <>
    <ContainerWrapper style={{margin: '2vh 2%'}}>
        <hr/>
        <br/>
        <h2 className="container-title">Overheid geeft homeopaten een communicatieverbod</h2>
        <br/>
        <P className='font-p italic'>
            Deze website geeft u informatie over het nut van homeopathie voor uw gezondheid en welzijn. Op straffe van hoge boetes verbiedt de minister van Volksgezondheid, Welzijn en Sport (VWS) om ter informatie homeopathische middelen in relatie tot ziekten te plaatsen, <b><br/>wel kunt u hieromtrent vrijblijvend contact met mij opnemen.</b>
        </P>
        <br/>
    </ContainerWrapper>
    </>;

var textTitle = 'Welkom';
// var titleSide = `<h2 >Alleen dat wat iemand werkelijk is heeft helende kracht</h2>
// <h3 className='shadow'>(Carl G. Jung)</h3>`;


// var headerStory = `<div className='shadow-dark header-story-content from-right'>
// <p className='text-accent'>
// Natuurlijk vrij en ongebonden los van wat ziekmakend bindt
// <br>Natuurlijk heelt oude wonden vervliegen met de wind
// <br>Geduldig ligt te wachten vredig, kalm en stil
// <br>Het ware zelf zonder klachten en natuurlijk de vrije wil
// <br>Dat ik u en het kind respectvol en aandachtig zie
// <br>Dat ik de passende middelen vind natuurlijk met klassieke homeopathie
// <br>Mijn doel, dat jong, oud, groot en klein duurzaam vrij en gezond mogen zijn!
// </P>
// </div>`;


function BoldText({ children }) {
    return (
      <span style={{ fontWeight: 'bold' }}>{children}</span>
    );
  }

var companyStory = (<div>
    <br/>
<P>
We zijn door ingrijpende veranderingen beschadigd en ver van onze natuurlijke zelf
verwijderd geraakt. De natuur heelt zichzelf, klassieke homeopathie kan u en uw kind
volgens de natuurlijke wetten op weg helpen om te helen.
Het is mijn passie en doel om u hierin van dienst te zijn, u bent van harte welkom in mijn
praktijk waar gezondheid centraal staat.
</P>

<P>
U vindt op deze site informatie over klassieke homeopathie en complementaire
behandelingen, de wijze waarop de behandelingen plaatsvinden en
aanvullende informatie voor diegene die zich verder wil verdiepen.
Ik behandel volgens de ziekteclassificatie, (de oorspronkelijke leer en de laatste inzichten, 6de
editie van het Organon van Samuel Hahnemann) en ben gespecialiseerd in het behandelen van AD(H)D, autisme en aanverwante
ontwikkelingsstoornissen en vaccinatieschade.
</P>

<P>
Genoten opleidingen: Z-verpleegkunde; Post hbo-speltherapie; Natuur en
kruidengeneeskunde; Klassieke homeopathie (Register Homeopaat); gecertificeerd in de
Banerji-protocollen en de CEASE-therapie.
</P>

<P>
Wilt u graag weten wat ik in uw specifieke situatie voor u kan betekenen? Neemt u dan
vrijblijvend contact met mij op voor nadere informatie.
</P>

<P>
Telefonische consulten / beeldbellen en aan huis is ook mogelijk.
<br/>
Telefonische spreekuur: <b>Maandag</b> t/m <b>Vrijdag</b> van <b>09:00</b> t/m <b>10:00</b>
</P>

<P>
Voor noodgevallen kunt u buiten het telefonisch spreekuur bellen naar <a href='tel:0636202765'>06-36202765</a> en eventueel inspreken waarop u meteen wordt teruggebeld.
<br/>
Tijdens de vakantie is er een waarnemende dienst en een bereikbare dienst voor noodgevallen.
</P>

<P className='bold'>
Consulten worden vergoed vanuit de aanvullende zorgverzekering.
<WebsiteLink href={'https://www.nvkh.nl/vergoedingen/'} break={false}></WebsiteLink>

<div style={{paddingTop: '10px'}}></div>

{/* <WebsiteLink href={'https://www.voedingsgeneeskunde.nl/editie/vg-24-1'} break={false}>Tarieven</WebsiteLink> */}

<ButtonLink link={'https://www.klassiekehomeopathie-groningen.nl/tarieven'}>Tarieven</ButtonLink>

</P>

</div>);

// companyStory = jsxToString(companyStory, {detectFunctions: true});

// console.log(companyStory);

if (!companyStory)
    companyStory = window.basicText;

export default function Home () {
	let _video = `${process.env.PUBLIC_URL}/videos/video-1.mp4`;
	let _image = `${process.env.PUBLIC_URL}/images/portfolio/layout/pages/home/20210526_121644.jpg`;
	// let _imageArray = `${process.env.PUBLIC_URL}/images/portfolio/layout/pages/home/20210526_121644.jpg`;

	const _imageArray = importAllArray(
		require.context('../../../public/images/layout/home', true, /^(?!.*\/not-used\/).*\/.*.\.(JPG|png|jpe?g|svg)$/)
	);

    
    

    return (
        <>
        <h1 data-nosnippet style={{position: 'absolute', color: 'rgba(0,0,0,0)', left: '-100%'}}>Homeopathie, Klassieke Homeopathie, KlassiekeHomeopathie, Friesland, Groningen</h1>
            <div className="page">
                {/* <HeaderSection video={'/videos/video-1.mp4'} showInfo={true} logo={true} /> */}
                {/* <HeaderSection video={_media} showInfo={true} logo={true} /> */}
                {/* <HeaderSection image={_image} showInfo={true} logo={true} /> */}
                
                <HeaderSectionHome />
                {/* <div className='shadow' style={{height: '10px', width:'100vw'}}></div> */}

                <div className="page-content">

                    {/* <QuoteByPerson text={'Alleen dat wat iemand werkelijk is heeft helende kracht'} who={'Carl G. Jung'}/> */}

                    <CompanyStory showAppointmentButtonBottom={false} title={undefined} scaleToPhone={false}>
                        {companyStory}
                    </CompanyStory>                    

                    {/* {_text_communication} */}

                    <hr/>
                    {/* Recognitions */}
			        <CardsContainer title={'Erkenningen'} cards={_cardsErkenningen} background={false} />
                    <hr/>

                    <ContactContainer  klachten={false} />
                    <br/>

                    <div className='fit-content margin-center'>
                    <AppointmentButton />
                    <br/>
                </div>


					{/* <Gallery path={process.env.PUBLIC_URL}/> */}
                    {/* <CardsHorizontal _key={1123} projects={entities.companies.ExampleCompany.GetProjects()} cards={undefined} /> */}
                </div>
            </div>
        </>
    );
};

    // _cards.push(
    //     <CardItem
	// 	key={5}
	// 		title={'NVKP'}
	// 		src={`/images/recognition/NVKP-logo.png`}
	// 		label={''}
	// 		text={`Nederlandse Vereniging Kritisch Prikken`}
	// 		// subText={``}
	// 		style={{..._style}}
	// 		type='recognition'
    //         background={false}
    //         imageLink={'https://www.nvkp.nl/'}
	// 	/>
    // );
    // _cards.push(
    //     <CardItem
	// 	key={3}
	// 		title={'De Banerji Protocols'}
	// 		src={`/images/recognition/KHBP-logo.png`}
	// 		label={''}
	// 		// text={`Banerji Protocols Stichting Hahnemann Homeopathie`}
	// 		// subText={``}
	// 		style={{..._style}}
	// 		type='recognition'
    //         background={false}
    //         imageLink={'http://www.banerjiprotocolsnederland.nl/'}
	// 	/>
    // );


// < className='header-story text-accent italic'>
    // <br>Natuurlijk vrij en ongebonden
    //         <br>los van wat ziekmakend bindt
    //         <br>Natuurlijk heelt oude wonden
    //         <br>vervliegen met de wind
    //         <br>
    //         <br>Geduldig ligt te wachten 
    //         <br>vredig, kalm en stil
    //         <br>Het ware zelf zonder klachten 
    //         <br>en natuurlijk de vrije wil
    //         <br>
    //         <br>Dat ik u en het kind 
    //         <br>respectvol en aandachtig zie
    //         <br>Dat ik de passende middelen vind 
    //         <br>natuurlijk met klassieke homeopathie
    //         <br>
    //         <br>Mijn doel, dat jong, oud, groot en klein 
    //         <br>duurzaam vrij en gezond mogen zijn!
    // </P>